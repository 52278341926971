import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';
import { message } from 'antd'; 

const ServerContext = createContext();

export const ServerProvider = ({ children }) => {
  const { token } = useAuth();
  const [roomServers, setRoomServers] = useState({});

  // Fetch server data for a specific room
  const fetchServerForRoom = async (roomId) => {
    try {
      const response = await axios.get(`https://api.scfg.io/private/module/rpa/server/${roomId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRoomServers(prevState => ({
        ...prevState,
        [roomId]: [response.data] 
      }));
    } catch (error) {
      console.error('Failed to fetch server:', error);
    }
  };

  // Fetch available server list for the department
  const fetchAvailableServers = async () => {
    try {
      const response = await axios.get('https://api.scfg.io/private/module/rpa/server/list', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data; // Return available server list
    } catch (error) {
      console.error('Failed to fetch available servers:', error);
      return [];
    }
  };

  // Add or update server for a specific room
  const addOrUpdateServer = async (roomId, serverInfo) => {
    try {
      const url = `https://api.scfg.io/private/module/rpa/server/${roomId}?property_id=${serverInfo.id}`;

      await axios.post(url, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setRoomServers(prevState => ({
        ...prevState,
        [roomId]: [serverInfo] // Assuming each room has only one server
      }));
      message.success("伺服器已成功增加或更新");
    } catch (error) {
      console.error('Failed to add or update server:', error);
      if (error.response && error.response.data) {
        message.error("無法增加或更新伺服器");
      } else {
        message.error("無法增加或更新伺服器");
      }
    }
  };

  // Remove server for a specific room using POST request
  const removeServer = async (roomId, serverInfo) => {
    try {
      const url = `https://api.scfg.io/private/module/rpa/server/${roomId}/remove?property_id=${serverInfo.id}`;

      await axios.post(url, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      setRoomServers(prevState => {
        const newState = { ...prevState };
        delete newState[roomId];
        return newState;
      });

      message.success("伺服器已成功移除");
    } catch (error) {
      console.error('Failed to remove server:', error);
      if (error.response && error.response.data) {
        message.error("無法移除伺服器");
      } else {
        message.error("無法移除伺服器");
      }
    }
  };

  // Get server data for a specific room from the local state
  const getServer = (roomId) => {
    return roomServers[roomId]?.[0]; // Assuming each room has only one server
  };

  return (
    <ServerContext.Provider value={{ roomServers, fetchServerForRoom, fetchAvailableServers, addOrUpdateServer, removeServer, getServer }}>
      {children}
    </ServerContext.Provider>
  );
};

// Hook
export const useServer = () => {
  return useContext(ServerContext);
};
