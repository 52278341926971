import React from 'react'
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as ExploreIcon } from './assets/explore_tab_icon.svg';
import { ReactComponent as ChatIcon } from './assets/chat_tab_icon.svg';
import { ReactComponent as ServiceIcon } from './assets/cms_service_icon.svg';
import { ReactComponent as ShopIcon } from './assets/cms_shop_icon.svg';
import { ReactComponent as TicketIcon } from './assets/cms_ticket_icon.svg';
import { ReactComponent as MemberIcon } from './assets/cms_member_icon.svg';
import { ReactComponent as ManagementIcon } from './assets/management_tab_icon.svg';
import { ReactComponent as MultiPostIcon } from './assets/multipost_tab_icon.svg';
import { ReactComponent as VoiceIcon } from './assets/voice_tab_icon.svg';
import Logo from '../logo/Logo';
import { FloatingPortal, useFloating, useHover, useInteractions, offset, FloatingArrow, arrow } from '@floating-ui/react';

const useTooltip = (label) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const arrowRef = React.useRef(null);
  
  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'right',
    middleware: [
      offset(10),
      arrow({ element: arrowRef })
    ],
  });

  const hover = useHover(context);
  const {getReferenceProps, getFloatingProps} = useInteractions([hover]);

  const TooltipContent = React.useMemo(() => {
    return isOpen ? (
      <FloatingPortal>
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            zIndex: 1000
          }}
          {...getFloatingProps()}
          className="bg-gray-800 text-white px-2 py-1 rounded text-sm"
        >
          {label}
          <FloatingArrow 
            ref={arrowRef} 
            context={context}
            className="fill-gray-800" 
          />
        </div>
      </FloatingPortal>
    ) : null;
  }, [isOpen, refs.setFloating, floatingStyles, getFloatingProps, context, label, arrowRef]);

  return {
    refs,
    getReferenceProps,
    TooltipContent
  };
};

const NavBar = () => {
  const location = useLocation();

  const render_e_commerce = process.env.REACT_APP_E_COMMERCE_MODULE === 'true' || false;
  const render_official_site = process.env.REACT_APP_E_OFFICIAL_SITE_MODULE === 'true' || false;
  const exploreTooltip = useTooltip('探索');
  const chatTooltip = useTooltip('聊天');
  const memberTooltip = useTooltip('會員');
  const shopTooltip = useTooltip('商店');
  const serviceTooltip = useTooltip('服務');
  const ticketTooltip = useTooltip('票券');
  const managementTooltip = useTooltip('後台管理');

  return (
    <div className="flex flex-col items-center justify-start min-h-screen min-w-24 w-24 pt-4 gap-4 bg-bgDark">
      <a href="/" className='w-[45px] h-[45px] flex justify-center items-center overflow-hidden' >
        <Logo type="NAVBAR" />
      </a>
      <a href="/explore" 
         ref={exploreTooltip.refs.setReference}
         {...exploreTooltip.getReferenceProps()}
         className={classNames({
           'border-primary': location.pathname.includes("/explore"),
           'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <ExploreIcon />
        {exploreTooltip.TooltipContent}
      </a>

      <a href="/chat" 
         ref={chatTooltip.refs.setReference}
         {...chatTooltip.getReferenceProps()}
         className={classNames({
           'border-primary': location.pathname.includes("/chat"),
           'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <ChatIcon />
        {chatTooltip.TooltipContent}
      </a>

      {render_e_commerce && (
        <>
          <a href="/member" 
             ref={memberTooltip.refs.setReference}
             {...memberTooltip.getReferenceProps()}
             className={classNames({
               'border-primary': location.pathname.includes("/member"),
               'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
          })}>
            <MemberIcon />
            {memberTooltip.TooltipContent}
          </a>
          
          <a href="/shop" 
             ref={shopTooltip.refs.setReference}
             {...shopTooltip.getReferenceProps()}
             className={classNames({
               'border-primary': location.pathname.includes("/shop"),
               'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
          })}>
            <ShopIcon />
            {shopTooltip.TooltipContent}
          </a>

          <a href="/service" 
             ref={serviceTooltip.refs.setReference}
             {...serviceTooltip.getReferenceProps()}
             className={classNames({
               'border-primary': location.pathname.includes("/service"),
               'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
          })}>
            <ServiceIcon />
            {serviceTooltip.TooltipContent}
          </a>

          <a href="/ticket" 
             ref={ticketTooltip.refs.setReference}
             {...ticketTooltip.getReferenceProps()}
             className={classNames({
               'border-primary': location.pathname.includes("/ticket"),
               'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
          })}>
            <TicketIcon />
            {ticketTooltip.TooltipContent}
          </a>
        </>
      )}

      {render_official_site && (
        <a href="/management" 
           ref={managementTooltip.refs.setReference}
           {...managementTooltip.getReferenceProps()}
           className={classNames({
             'border-primary': location.pathname.includes("/management"),
             'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
        })}>
          <ManagementIcon />
          {managementTooltip.TooltipContent}
        </a>
      )}

      {/* <a href="/multipost" className={classNames({
        'border-primary': location.pathname.includes("/multipost"),
        'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <MultiPostIcon />
      </a> */}

      {/* <a href="/voice" className={classNames({
        'border-primary': location.pathname.includes("/voice"),
        'flex w-10 h-10 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
      })}>
        <VoiceIcon />
      </a> */}

      <div
        className="text-xs text-bgMedium"
      >
        v-1108-00
      </div>

    </div>
  )
}

export default NavBar
