import React from 'react'
import classNames from 'classnames';

const ChatListItem = (props) => {
  // Main container classes
  const containerClass = classNames(
    'flex flex-col bg-white rounded-xl border cursor-pointer text-textDark w-80 overflow-hidden', {
    'border-primaryMedium drop-shadow': props.isActive,
    'hover:bg-bgLight': !props.isActive
  }
  );

  // Owner tag classes
  const ownerTagClass = classNames(
    'flex flex-row h-6 items-center pl-3 text-sm rounded-t-lg text-dark', {
    'bg-primaryMedium': props.isActive,
    'bg-borderLight': !props.isActive
  }
  );

  // Room content classes
  const roomContentClass = classNames(
    'flex px-2 flex-col items-start justify-center w-full h-full overflow-hidden whitespace-nowrap', {
    'text-primary': props.isActive
  }
  );

  // Online count container classes
  const onlineCountContainerClass = classNames(
    'flex flex-col items-center justify-center w-16 h-full border-l', {
    'text-primary': props.isActive
  }
  );

  return (
    <div className={containerClass}>
      {props.isOwner && (
        <div className={ownerTagClass}>
          房主
        </div>
      )}
      <div className="flex flex-row h-16">
        <div className={roomContentClass}>
          {props.roomName}
        </div>
        <div className={onlineCountContainerClass}>
          <div>
            {props.onlineCount}
          </div>
          <div className="text-xs">
            成員
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatListItem