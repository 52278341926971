import React, { useState, useEffect } from "react";
import { request } from "@/utils";
import axios from "axios";
import { Table, Input, Button, Tag, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import ToolBar from "./components/ToolBar";
import { initialData } from "./data/MemberData";
import { useAuth } from "@/contexts/AuthProvider";
const API_KEY = process.env.REACT_APP_API_KEY;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CMS_SERVER_URL = process.env.REACT_APP_CMS_SERVER_URL;
// define MemberDetail component
const MemberDetail = ({ member }) => {
  const items = [
    {
      key: "1",
      label: "會員資訊",
      children: (
        <div className="flex-1 pb-20">
          <div className="flex flex-col bg-white rounded-lg shadow gap-1">
            <div className="p-4">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <img
                    src={member.picture || "/images/user.png"}
                    alt="會員頭像"
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-lg font-bold">
                        {member.nickname || `${member.first_name || ''} ${member.last_name || ''}`.trim() || '未設定'}
                      </span>
                      <Tag color="green">Level {member.level}</Tag>
                    </div>
                  </div>
                </div>
              </div>

              {/* 基本資料區 */}
              <div className="mb-4">
                <h4 className="text-base font-bold mb-2">基本資料</h4>
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/phone.svg" alt="電話" className="w-5 h-5" />
                    <span>{member.phone || '未設定'}</span>
                    {member.phone_is_verified && <Tag color="green">已驗證</Tag>}
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/mail.svg" alt="信箱" className="w-5 h-5" />
                    <span>{member.email || '未設定'}</span>
                    {member.email_is_verified && <Tag color="green">已驗證</Tag>}
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/calendar.svg" alt="註冊時間" className="w-5 h-5" />
                    <span>{moment(member.created_at).format("YYYY-MM-DD")}</span>
                  </div>
                </div>
              </div>

              {/* 社群平台區 */}
              {member.sso_info && member.sso_info.length > 0 && (
                <div className="mb-4">
                  <h4 className="text-base font-bold mb-2">社交平台</h4>
                  <div className="space-y-2">
                    {member.sso_info.map((sso, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <img
                          src={`/images/${sso.provider}.svg`}
                          alt={sso.provider}
                          className="w-5 h-5"
                        />
                        <span className="flex-1">{sso.provider_uid}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col h-full relative">
      <div className="flex-1">
        <Tabs
          defaultActiveKey="1"
          items={items}
          className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4"
        />
      </div>
    </div>
  );
};

const Member = () => {
  // define columns
  const columns = [
    {
      title: "會員ID",
      dataIndex: "id",
      width: "150px", 
      fixed: "left",
      render: (id) => id.slice(0, 8)
    },
    {
      title: "會員名稱",
      dataIndex: "nickname",
      width: "180px",
      fixed: "left",
      render: (text, record) => (
        <div className="flex items-center">
          <img
            src={record.picture || "/images/user.png"}
            alt="會員頭像"
            className="w-6 h-6 object-cover rounded-full mr-2"
          />
          <span>{text || `${record.first_name || ''} ${record.last_name || ''}`.trim() || '未設定'}</span>
        </div>
      ),
    },
    {
      title: "電話",
      dataIndex: "phone",
      width: "120px",
      render: (phone) => phone || '未設定'
    },
    {
      title: "Email",
      dataIndex: "email", 
      width: "180px",
      render: (email) => email || '未設定'
    },
    {
      title: "會員等級",
      dataIndex: "level",
      width: "100px",
      render: (level) => (
        <Tag color={level > 0 ? "gold" : "blue"}>Level {level}</Tag>
      ),
    },
    {
      title: "註冊時間",
      dataIndex: "created_at",
      width: "130px",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "社群平台",
      dataIndex: "sso_info",
      width: "120px",
      render: (sso) => {
        if (!sso || sso.length === 0) return '未綁定';
        return sso[0].provider;
      }
    },
    {
      title: "驗證狀態",
      width: "200px",
      render: (_, record) => {
        const hasVerification = record.phone_is_verified || record.email_is_verified;
        
        if (!hasVerification) {
          return <Tag color="default">未設定</Tag>;
        }
        
        return (
          <div className="flex flex-wrap gap-1">
            {record.phone_is_verified && <Tag color="green">手機已驗證</Tag>}
            {record.email_is_verified && <Tag color="green">郵箱已驗證</Tag>}
          </div>
        );
      },
    },
    {
      title: "開放狀態",
      dataIndex: "enabled",
      width: "90px",
      align: "center",
      fixed: "right",
      render: (enabled) => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${
            enabled ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
          }`}
        >
          {enabled ? '開放' : '關閉'}
        </span>
      ),
    },
  ];
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [searchText, setSearchText] = useState("");
  const { token } = useAuth();
  // 添加 tableLoading 状态
  const [tableLoading, setTableLoading] = useState(false);
  // fetch member data
  useEffect(() => {
    fetchMembers();
  }, []);

  // fetch member data
  const fetchMembers = async () => {
    setTableLoading(true); // 开始加载时设置 loading
    try {
      const response = await request.post(`${SERVER_URL}/private/property/ecommerce/proxy`, 
        {
            "url": '/system/user/all',
            "method": "GET",
            "body": {},
            "query": {}
          }
    );

      // 直接使用API返回的數據結構,只添加key屬性
      const formattedData = response.users.map(user => ({
        ...user,
        key: user.id
      }));

      setMembers(formattedData);
    } catch (error) {
      console.error('獲取會員數據失敗:', error);
    } finally {
      setTableLoading(false); // 完成加载后关闭 loading
    }
  };

  // handle search
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // handle filtered members
  const filteredMembers = members.filter((member) => {
    return (
      searchText === "" ||
      member.name.toLowerCase().includes(searchText.toLowerCase())
    );
  });

  // set default selected member
  useEffect(() => {
    if (!loading && members.length > 0 && !selectedMember) {
      setSelectedMember(members[0]);
    }
  }, [loading, members, selectedMember]);

  // handle loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  // handle row click
  const onRowClick = (record) => {
    setSelectedMember(record);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
        <ToolBar pure={true} />
        <div className="flex flex-col w-full h-[calc(100vh-64px)]">
          <div className="flex-1 flex overflow-auto">
            <div className="flex w-full overflow-auto bg-gray-50">
              {/* left table area  */}
              <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                <div className="flex justify-between items-center">
                  <div className="text-lg font-bold">會員管理</div>
                </div>

                {/* Statistics information */}
                <div className="grid grid-cols-5 gap-4 mb-4">
                  <div className="col-span-2 bg-[#F3F3F3] rounded-lg shadow">
                    <div className="h-[36px] pt-2 pl-2 ">
                      <img
                        src="/images/folder.png"
                        alt="folder"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">總會員人數</div>
                        <div className="text-2xl font-bold">5000</div>
                      </div>
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">已消費會員人數</div>
                        <div className="text-2xl font-bold">4200</div>
                      </div>
                    </div>
                  </div>

                  <div className="col-span-3 p-1 rounded-lg ">
                    <div className="bg-[#FFCC9129] rounded-lg w-[36px] h-[36px] inline-flex items-center justify-center">
                      <img
                        src="/images/2user.png"
                        alt="folder"
                        className="w-[20px] h-[20px]"
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">近7天加入的會員</div>
                        <div className="text-2xl font-bold">100</div>
                      </div>
                      <div className="p-2 text-start rounded-lg">
                        <div className="text-sm">已消費會員人數</div>
                        <div className="text-2xl font-bold">4200</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* search area  */}
                <div className="flex space-x-4 mb-4 mt-4">
                  <Input
                    className="w-80"
                    placeholder="搜尋"
                    prefix={<SearchOutlined />}
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </div>

                {/* table  */}
                <div className="flex-1 overflow-auto">
                  <Table
                    loading={tableLoading}
                    style={{
                      height: "100%",
                    }}
                    columns={columns}
                    dataSource={filteredMembers}
                    pagination={{
                      pageSize: 10,
                      className: "sticky bottom-0 pb-2",
                    }}
                    onRow={(record) => ({
                      onClick: () => onRowClick(record),
                      className:
                        record.key === selectedMember?.key ? "bg-gray-100" : "",
                    })}
                    rowClassName={(record) =>
                      `cursor-pointer ${
                        record.key === selectedMember?.key ? "bg-gray-100" : ""
                      }`
                    }
                    scroll={{ x: "max-content" }}
                    className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0] h-full"
                    size="small"
                  />
                </div>
              </div>

              {/* right preview area  */}
              <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                <div>
                  {selectedMember ? (
                    <MemberDetail member={selectedMember} className="pl-6" />
                  ) : (
                    <div className="flex items-center justify-center h-[calc(100vh-64px)] text-gray-400">
                      請選擇會員以查看詳細資訊
                    </div>
                  )}
                </div>
                <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                  <Button
                    type="primary"
                    className="w-full h-10 bg-[#4CAF50] hover:bg-[#45a049]"
                    disabled={!selectedMember}
                  >
                    查看會員
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Member;
