import React, { useEffect, useState } from 'react';
import { Spin, Button, List, Empty, Tooltip } from 'antd';
import { LoadingOutlined, ReloadOutlined, CloseOutlined } from '@ant-design/icons';

export const Advanced = ({
  handleButtonClick,
  selectedButton,
  fetching,
  template,
  setSelectedTemplate,
  setSelectedTemplateIndex,
  setShowTemplateEditor,
  setShowRecord,
  handleOpenModal,
  deleteMemory,
  handleFetchTemplate
}) => {
  const [record, setRecord] = useState([]);
  const [fileContents, setFileContents] = useState([]);

  // Fetch and return file content from the URL
  const fetchTextContent = async (url) => {
    try {
      const response = await fetch(url);
      const textContent = await response.text();
      return textContent;
    } catch (error) {
      console.error('Error fetching file:', error);
      return '查無相關資料';
    }
  };

  const extractRecordsFromTemplate = (templates) => {
    return templates.reduce((acc, currTemplate) => {
      if (currTemplate.records && currTemplate.records.length > 0) {
        return [...acc, ...currTemplate.records];
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (template && template.length > 0) {
      const newRecord = extractRecordsFromTemplate(template);
      setRecord(newRecord);
    }
  }, [template]);

  useEffect(() => {
    const fetchAllFileContents = async () => {
      const contents = await Promise.all(
        record.map(item => fetchTextContent(item.blob.url))
      );
      setFileContents(contents);
    };

    if (record.length > 0) {
      fetchAllFileContents();
    }
  }, [record]);

  return (
    <div className="flex flex-row w-full h-full p-1">
      <div className="flex flex-col w-1/2 h-full items-start justify-start border-r border-r-borderLight p-3">
        {/* nav btn */}
        <div className="flex justify-between bg-gray-100 w-[276px] rounded mb-4">
          <button
            onClick={() => handleButtonClick("general")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "general" ? "bg-[#ADDEAC]" : "bg-gray-100"}`}
          >
            一般記憶
          </button>
          <button
            onClick={() => handleButtonClick("advanced")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "advanced" ? "bg-[#ADDEAC]" : "bg-gray-100"}`}
          >
            進階記憶
          </button>
          <button
            onClick={() => handleButtonClick("qa")}
            className={`px-2 py-1 flex-1 rounded ${selectedButton === "qa" ? "bg-[#ADDEAC]" : "bg-gray-100"}`}
          >
            問答記憶
          </button>
        </div>
        <div className="flex flex-row bg-bgDark h-8 items-center w-full gap-2 px-2">
          <div className="flex w-[600px] text-sm">模板名稱</div>
          <div className="flex min-w-4 bg-bgDark h-full ml-2" />
        </div>
        {fetching ? (
          <div className="flex justify-center items-center w-full h-72">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
          </div>
        ) : (
          <List
            dataSource={template}
            className="w-full"
            locale={{
              emptyText: <Empty description="No data" />
            }}
            renderItem={(item, index) => (
              <List.Item key={index} className="flex flex-row justify-between border-b border-b-borderLight h-11 items-center w-full gap-2 px-2">
                <div className="flex cursor-pointer hover:underline truncate">
                  {item.name}
                </div>
                <Tooltip title="編輯模板">
                  <Button
                    onClick={() => {
                      setSelectedTemplate(item);
                      setSelectedTemplateIndex(index);
                      setShowTemplateEditor(true);
                    }}
                    className="bg-transparent border-none hover:bg-transparent active:bg-transparent"
                  >
                    <img src="/images/edit-icon.png" alt="edit-icon" />
                  </Button>
                </Tooltip>
              </List.Item>
            )}
          />
        )}
        <div className="flex justify-end w-full mt-3">
          <Tooltip title="新增模板">
            <Button
              className="text-black-900 text-[16px] leading-[1.3] w-[58px] bg-[#ADDEAC] flex items-center justify-center"
              style={{ transition: 'background-color 0.3s, color 0.3s' }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = 'rgba(173, 222, 172, 0.8)';
                e.currentTarget.style.color = 'inherit';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = '#ADDEAC';
                e.currentTarget.style.color = 'inherit';
              }}
              onClick={() => {
                setSelectedTemplate(null);
                setSelectedTemplateIndex(null);
                setShowTemplateEditor(true);
              }}
            >
              新增
            </Button>
          </Tooltip>
        </div>
      </div>
      <div className="flex flex-col w-1/2 min-h-full h-fit items-center justify-start border-l border-l-borderLight p-4 gap-4 overflow-visible pb-8">
        <h1 className="text-lg font-bold text-textDark self-start">
          目前記憶
          <Tooltip title="重新載入">
            <ReloadOutlined
              className="hover:text-primary ml-2"
              style={{ width: "12px", height: "12px" }}
              onClick={handleFetchTemplate}
            />
          </Tooltip>
        </h1>
        <div className="flex flex-col w-full">
          {/* 表頭 */}
          <div className="flex flex-row justify-start bg-bgDark h-8 items-center w-full gap-1 px-2">
            <div className="flex justify-start text-sm w-1/3">記憶名稱</div>
            <div className="flex justify-start text-sm w-1/4">模板名稱</div>
            <div className="flex justify-start text-sm w-1/4">建立時間</div>
            <div className="flex w-1/6 bg-bgDark h-full ml-2" />
          </div>

          {/* 內容區塊 */}
          {fetching ? (
            <div className="flex justify-center items-center w-full h-72">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <List
              dataSource={record}
              locale={{
                emptyText: <Empty description="No data" />
              }}
              renderItem={(item, index) => (
                <List.Item key={index} className="flex flex-row border-b border-b-borderLight h-11 items-center w-full gap-0 px-2">
                  <div className="flex w-1/3 cursor-pointer hover:underline truncate" onClick={() => handleOpenModal(item.blob.content_type, item.blob.url, item.id)}>
                    {fileContents[index] && fileContents[index].split(":")[1].split("#")[0]}
                  </div>
                  <div className="flex w-1/4 px-1 truncate">
                    {fileContents[index] && fileContents[index].split(":")[0]}
                  </div>
                  <div className="flex w-1/4 px-1 min-w-20 text-xs">
                    {item.blob.created_at?.split(" ")[0]}
                  </div>
                  <div className="flex justify-end px-1 w-1/6">
                    <Tooltip title="刪除記憶">
                      <CloseOutlined
                        className="text-textDark w-1/8 px-1 hover:text-red-500 cursor-pointer ml-2"
                        onClick={() => deleteMemory(item.id)}
                      />
                    </Tooltip>
                  </div>
                </List.Item>
              )}
            />
          )}
          <div className="flex justify-end w-full mt-3">
            <Tooltip title="新增記憶">
              <Button
                className="text-black-900 text-[16px] leading-[1.3] w-[58px] bg-[#ADDEAC] flex items-center justify-center"
                style={{ transition: 'background-color 0.3s, color 0.3s' }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = 'rgba(173, 222, 172, 0.8)';
                  e.currentTarget.style.color = 'inherit';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = '#ADDEAC';
                  e.currentTarget.style.color = 'inherit';
                }}
                onClick={() => {
                  setShowRecord(true);
                }}
              >
                新增
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
