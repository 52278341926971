import React, { useEffect, useState } from "react";
import { useServer } from "@/contexts/ServerContext";
import { Select, Button, message } from "antd";
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const { Option } = Select;

const ServerSetting = ({ room, token }) => {
  const [selectedServer, setSelectedServer] = useState(null);
  const [isResetting, setIsResetting] = useState(false);
  const { roomServers, fetchServerForRoom, fetchAvailableServers, addOrUpdateServer } = useServer();
  const [availableServers, setAvailableServers] = useState([]);
  const roomId = room.id;
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchServerForRoom(roomId); // Fetch server data for this room
      const availableServersData = await fetchAvailableServers(); // Fetch available servers list
      setAvailableServers(availableServersData);
      setLoading(false);
    };
    fetchData();
  }, [roomId]);



  const handleFinish = async () => {
    if (!selectedServer) {
      message.error("請選擇伺服器");
      return;
    }

    const serverInfo = availableServers.find(
      (server) => server.name === selectedServer
    );

    if (!serverInfo) {
      message.error("伺服器不存在");
      return;
    }

    try {
      await addOrUpdateServer(roomId, serverInfo);
      setIsResetting(false);
      const profileUrl = `https://api.scfg.io/private/module/rpa/profile/${roomId}`;

      try {
        const response = await axios.get(profileUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          // 如果返回 200
          console.log("RPA profile 已存在");
        }
      } catch (error) {
        if (error.response && error.response.status !== 200) {
          await axios.post(profileUrl, {}, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
        } else {
          message.error("無法檢查或創建 RPA profile");
        }
      }
    } catch (error) {
      message.error("伺服器增加或更新失敗");
    }
  };


  const handleResetServer = () => {
    const serverInfo = roomServers[roomId] && roomServers[roomId][0];
    if (serverInfo) {
      setSelectedServer(null); // reset selected server
      setIsResetting(true); // reset
    } else {
      message.error("沒有可移除的伺服器");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }


  return (
    <>
      <div className="flex flex-col justify-start items-start w-full py-6 gap-4 max-h-[100vh] overflow-y-auto">
        <div className="font-semibold text-[17px] text-textDark font-bold w-full">
          伺服器設定
        </div>

        {isResetting || !(roomServers[roomId] && roomServers[roomId][0]) ? (
          // if resetting or no server data
          <div className="w-full flex flex-col items-start gap-4">
            <div className="w-full h-[109px] bg-[#F0F0F0] border border-[#E0E0E0] rounded-[4px] flex flex-col items-start gap-4 p-4">
              <div className="text-black text-[16px]">伺服器列表</div>
              <div className="mt-[2px] text-black-900 w-full">
                <Select
                  className="w-full"
                  placeholder={<span style={{ color: "#000000" }}>
                    {roomServers[roomId] && roomServers[roomId][0]
                      ? `已設定: ${roomServers[roomId][0].name}`
                      : "選擇伺服器"}
                  </span>}
                  onChange={(value) => setSelectedServer(value)}
                >
                  {availableServers.map((server) => (
                    <Option key={server.id} value={server.name}>
                      <div className="flex items-center">
                        {server.name}
                      </div>
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="w-full flex justify-end gap-4">
              <Button
                onClick={handleFinish}
                className="text-black-900 text-[16px] leading-[1.3]"
                style={{ backgroundColor: "#ADDEAC" }}
              >
                完成
              </Button>
            </div>
          </div>
        ) : (
          // if server data exists and not resetting
          <div className="w-full bg-[#F0F0F0] border border-[#E0E0E0] rounded-[4px] p-4 flex flex-col gap-2">
            <div className="flex justify-between text-[16px] font-normal mb-2">
              您選擇的伺服器
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-4">
                <div className="flex space-x-4">
                  <div className="flex flex-col pr-4 border-r border-gray-400">
                    <span className="font-bold">名稱:</span>
                    <span>{roomServers[roomId][0].name}</span>
                  </div>
                  <div className="flex flex-col pr-4 border-r border-gray-400">
                    <span className="font-bold">網址:</span>
                    <span>{roomServers[roomId][0].body.url}</span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-bold">IP:</span>
                    <span>{roomServers[roomId][0].body.ip}</span>
                  </div>
                </div>
              </div>
              <Button
                className="text-[#3E3D3D] bg-[#ADDEAC] text-[16px] leading-[1.3]"
                onClick={handleResetServer}
              >
                重新設定
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ServerSetting;
