import React, { useState, useEffect } from "react";
import { Table, Input, Button, Select, Tag, Tabs, Radio } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { Spin } from "antd";
import AddShopForm from "./components/AddShopForm";
import { LoadingOutlined } from "@ant-design/icons";
import OrderManagement from "./components/OrderManagement";
import moment from "moment";
import { useShop } from "@/contexts/ShopContext";
import ToolBar from "./components/ToolBar";
import request from "@/utils/request";

const Shop = () => {
  const [shops, setShops] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [activeTab, setActiveTab] = useState("2");
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;
  const [tableLoading, setTableLoading] = useState(true);
  // fetch member data
  useEffect(() => {
    fetchShops();
  }, []);

  // fetch shop data
  const fetchShops = async () => {
    setTableLoading(true);
    try {
      const response = await request.post(`${SERVER_URL}/private/property/ecommerce/proxy`, 
        {
            "url": '/private/user/organizations',
            "method": "GET",
            "body": {},
            "query": {}
          }
    );

      // 格式化數據，添加必要的字段
      const formattedData = response.map(shop => ({
        key: shop.id,
        id: shop.id,
        name: shop.name,
        status: "上架中", // 默认值
        provider: "審核通過", // 默认值
        shopId: `SP${String(shop.id).slice(0, 3)}`,
        phone: shop.phone || "",
        email: shop.email || "",
        city: shop.address || "",
        district: shop.region || "",
        order: "0", // 默认值
        remaining: "0", // 默认值
        memberDiscount: "0", // 默认值
        tags: [], // 默认空数组
        publishDate: shop.created_at,
        imageUrl: shop.pictures?.[0] || "/images/default-shop.jpg",
        isVerified: shop.is_verified,
        enabled: shop.enabled,
        serviceRegions: shop.service_regions || [],
        adminInfo: shop.admin || {}
      }));

      setShops(formattedData);
    } catch (error) {
      console.error('獲取商家數據失敗:', error);
    } finally {
      setTableLoading(false);
    }
  };

  // Define columns
  const columns = [
    {
      title: <span className="whitespace-nowrap">前台狀態</span>,
      dataIndex: "status",
      width: "10%",
      fixed: "left",
      render: (status) => (
        <span
          className={`badge rounded-full px-2 py-1 text-white whitespace-nowrap ${status === "上架中" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
        >
          {status}
        </span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">審核狀態</span>,
      dataIndex: "provider",
      width: "10%",
      fixed: "left",
      render: (provider) => (
        <span className="whitespace-nowrap">{provider}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">商家序號</span>,
      dataIndex: "shopId",
      width: "8%",
      fixed: "left",
      render: (shopId) => (
        <span className="whitespace-nowrap">{shopId}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">商家名稱</span>,
      dataIndex: "name",
      width: "15%",
      render: (text, record) => (
        <div className="flex items-center">
          <img
            src={record.imageUrl}
            alt="服務縮圖"
            className="w-6 h-6 object-cover rounded mr-2"
          />
          <span className="truncate whitespace-nowrap" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {text}
          </span>
        </div>
      ),
    },
    {
      title: <span className="whitespace-nowrap">註冊申請人</span>,
      dataIndex: "price",
      width: "8%",
      render: (price) => (
        <span className="whitespace-nowrap">NT${price}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">美容師數量</span>,
      dataIndex: "order",
      width: "8%",
      render: (order) => (
        <Button type="link" className="whitespace-nowrap">{order}</Button>
      ),
    },
    {
      title: <span className="whitespace-nowrap">服務數量</span>,
      dataIndex: "remaining",
      width: "7%",
      render: (remaining) => (
        <span className="whitespace-nowrap">{remaining === "0" ? "無限" : remaining}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">商品數</span>,
      dataIndex: "memberDiscount",
      width: "7%",
      render: (memberDiscount) => (
        <span className="whitespace-nowrap">{memberDiscount}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">累積營收</span>,
      dataIndex: "revenue",
      width: "10%",
      ellipsis: true,
      render: (revenue) => (
        <span className="whitespace-nowrap">
          {revenue ? `NT$ ${revenue}` : "未設定"}
        </span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">標籤</span>,
      dataIndex: "tags",
      width: "15%",
      render: (tags) => (
        <div className="flex gap-1 whitespace-nowrap" style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {tags?.length > 0 ? (
            tags.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))
          ) : (
            <Tag>未設定</Tag>
          )}
        </div>
      ),
    },
    {
      title: <span className="whitespace-nowrap">加入日期</span>,
      dataIndex: "publishDate",
      width: "10%",
      render: (date) => (
        <span className="whitespace-nowrap">{moment(date).format("YYYY-MM-DD")}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">商家電話</span>,
      dataIndex: "phone",
      width: "10%",
      ellipsis: true,
      render: (phone) => (
        <span className="whitespace-nowrap">{phone}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">商家Email</span>,
      dataIndex: "email",
      width: "10%",
      ellipsis: true,
      render: (email) => (
        <span className="whitespace-nowrap">{email}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">所在縣市</span>,
      dataIndex: "city",
      width: "10%",
      ellipsis: true,
      render: (city) => (
        <span className="whitespace-nowrap">{city}</span>
      ),
    },
    {
      title: <span className="whitespace-nowrap">所在區域</span>,
      dataIndex: "district",
      width: "10%",
      ellipsis: true,
      render: (district) => (
        <span className="whitespace-nowrap">{district || "未設定"}</span>
      ),
    },
  ];

  const [selectedService, setSelectedService] = useState(null);
  const [selectedShop, setSelectedShop] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const totalShops = shops.length;
  const availableShops = shops.filter(
    (shop) => shop.status === "上架中"
  ).length;
  const lowStockWarnings = shops.filter(
    (shop) => parseInt(shop.remaining) < 20 && shop.remaining !== "0"
  ).length;
  const onPromotion = shops.filter(
    (shop) => parseFloat(shop.memberDiscount) > 0
  ).length;
  const drafts = shops.filter((shop) => shop.status === "草稿夾").length;

  // Handle row click
  const onRowClick = (record) => {
    setSelectedShop(record);
  };

  // Handle add shop
  const handleAddShop = (newShop) => {
    const currentShops = JSON.parse(JSON.stringify(shops));
    const shopToAdd = {
      key: Date.now().toString(),
      status: "上架中",
      provider: "審核通過",
      shopId: `SP${String(currentShops.length + 1).padStart(3, "0")}`,
      name: newShop.selectedShopName || newShop.name || "未設定",
      price: newShop.price || "未設定",
      order: newShop.order || "0",
      remaining: newShop.remaining || "0",
      memberDiscount: newShop.memberDiscount || "0",
      description: newShop.description || "未設定",
      tags: newShop.tags?.length ? newShop.tags : ["未設定"],
      publishDate: newShop.publishDate || new Date().toISOString(),
      imageUrl: newShop.imageUrl || "/images/default-shop.jpg",
      phone: newShop.phone || "未設定",
      email: newShop.email || "未設定",
      city: newShop.city || "未設定",
      district: newShop.district || "未設定",
      revenue: newShop.revenue || "0",
      businessHours: newShop.businessHours || "未設定",
      rating: newShop.rating || "未設定",
      establishDate: newShop.establishDate || "未設定",
      ownerName: newShop.ownerName || "未設定",
      ownerPhone: newShop.ownerPhone || "未設定"
    };
    const updatedShops = [shopToAdd, ...currentShops];

    setShops(updatedShops);
    setShowForm(false);
  };

  // Handle search
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // Handle provider change
  const handleProviderChange = (value) => {
    setSelectedProvider(value);
  };

  // Handle status change
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  // Handle filtered shops
  const filteredShops = shops.filter((shop) => {
    return (
      (searchText === "" ||
        shop.name.toLowerCase().includes(searchText.toLowerCase())) &&
      (selectedProvider === "all" ||
        selectedProvider === null ||
        shop.provider === selectedProvider) &&
      (selectedStatus === "all" ||
        selectedStatus === null ||
        shop.status === selectedStatus)
    );
  });

  // // Handle provider options
  //   const providerOptions = [
  //   { value: 'all', label: '全部商家' },
  //   ...[...new Set(shops.map(shop => shop.provider))].map(provider => ({
  //     value: provider,
  //     label: provider
  //   }))
  // ];

  // Handle status options
  const statusOptions = [
    { value: "all", label: "狀態" },
    { value: "上架中", label: "上架中" },
    { value: "下架中", label: "下架中" },
  ];

  // Handle shop detail
  const ShopDetail = ({ shop }) => {
    const items = [
      {
        key: "1",
        label: "篩選商家",
        children: (
          <div className="p-4">
            <div className="space-y-6">
              <div>
                <div className="mb-2">篩選特定商家</div>
                <Input
                  placeholder="輸入商家名稱,商家序號,商家電話,商家Email..."
                  className="w-full"
                  disabled
                />
              </div>

              <div>
                <div className="mb-2">美容師數量</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低數量"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高數量"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">服務數量</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低數量"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高數量"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">商品數量</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低數量"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高數量"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">累積營收</div>
                <div className="flex gap-2">
                  <Input
                    placeholder="輸入最低金額"
                    className="flex-1"
                    disabled
                  />
                  <span className="flex items-center">~</span>
                  <Input
                    placeholder="輸入最高金額"
                    className="flex-1"
                    disabled
                  />
                </div>
              </div>

              <div>
                <div className="mb-2">商家標籤</div>
                <Select
                  className="w-full"
                  placeholder="選擇商家標籤"
                  mode="multiple"
                  options={[
                    { value: "beauty", label: "美容" },
                    { value: "spa", label: "SPA" },
                    { value: "massage", label: "按摩" },
                  ]}
                  disabled
                />
                <div className="flex gap-2 mt-2">
                  <Radio.Group defaultValue="any" className="w-full" disabled>
                    <div className="flex justify-between">
                      <Radio value="all">符合所有選項</Radio>
                      <Radio value="any">符合任一選項</Radio>
                    </div>
                  </Radio.Group>
                </div>
              </div>

              <div>
                <div className="mb-2">商家所在縣市</div>
                <Select
                  className="w-full"
                  placeholder="擇商家所在縣市"
                  mode="multiple"
                  options={[
                    { value: "taipei", label: "北市" },
                    { value: "newtaipei", label: "新北市" },
                  ]}
                  disabled
                />
                <div className="flex gap-2 mt-2">
                  <Radio.Group defaultValue="any" className="w-full" disabled>
                    <div className="flex justify-between">
                      <Radio value="all">符合所有選項</Radio>
                      <Radio value="any">符合任一選項</Radio>
                    </div>
                  </Radio.Group>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        key: "2",
        label: "商家資訊預覽",
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white rounded-lg shadow gap-1 p-4">
              {/* 商家名稱和標籤 */}
              <div className="flex flex-col items-center mb-4">
                <img
                  src={shop.imageUrl || "/images/default-service.jpg"}
                  alt={shop.name}
                  className="w-24 h-24 object-cover rounded-full mb-2"
                />
                <h3 className="text-2xl font-semibold">{shop.name}</h3>
                <div className="flex gap-2 mt-2">
                  {shop.tags?.map((tag, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                  <Button shape="circle" icon={<PlusOutlined />} />
                </div>
              </div>

              {/* 新增的商家資訊 */}
              <div className="mb-4">
                <h4 className="font-semibold">營業時間</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/clock.png" alt="Clock" className="w-6 h-6" />
                    <span>{shop.businessHours || "未設定"}</span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <h4 className="font-semibold">商家評分</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/star.png" alt="Star" className="w-6 h-6" />
                    <span>{shop.rating || "未設定"}</span>
                  </div>
                </div>
              </div>

              {/* 商家平台 */}
              <div className="mb-4">
                <h4 className="font-semibold">商家平台</h4>
                <div className="flex gap-4 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/facebook.png" alt="Facebook" className="w-6 h-6" />
                    <span>未綁定</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/instagram.png" alt="Instagram" className="w-6 h-6" />
                    <span>未綁定</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/thread.png" alt="thread" className="w-6 h-6" />
                    <span>未綁定</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/tiktok.png" alt="TikTok" className="w-6 h-6" />
                    <span>未綁定</span>
                  </div>
                </div>
              </div>

              {/* 基本資料 */}
              <div className="mb-4">
                <h4 className="font-semibold">基本資料</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/date.png" alt="Date" className="w-6 h-6" />
                    <span>{shop.establishDate || "未設定"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/phone.png" alt="Phone" className="w-6 h-6" />
                    <span>{shop.phone || "未設定"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/email.png" alt="Email" className="w-6 h-6" />
                    <span>{shop.email || "未設定"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/location.png" alt="Location" className="w-6 h-6" />
                    <span>{`${shop.city || ""}${shop.district || ""}` || "未設定"}</span>
                  </div>
                </div>
              </div>

              {/* 負責人 */}
              <div className="mb-4">
                <h4 className="font-semibold">負責人</h4>
                <div className="space-y-2 mt-2">
                  <div className="flex items-center gap-2">
                    <img src="/images/person.png" alt="User" className="w-6 h-6" />
                    <span>{shop.ownerName || "未設定"}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <img src="/images/phone.png" alt="Phone" className="w-6 h-6" />
                    <span>{shop.ownerPhone || "未設定"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ];

    return (
      <div className="flex flex-col h-full relative">
        <div className="flex-1 p-4">
          <Tabs
            items={items}
            className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto"
            tabBarStyle={{ justifyContent: 'flex-start' }}
            activeKey={activeTab}
            onChange={(key) => setActiveTab(key)}
          />
        </div>
      </div>
    );
  };

  // handle show form
  const handleShowForm = () => {
    setShowForm(true);
    setSelectedService(null);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  if (showOrderManagement) {
    return <OrderManagement />;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
        <ToolBar pure={true} />
        <div className="flex flex-col w-full h-[calc(100vh-64px)]">
          <div className="flex-1 flex overflow-auto">
            {showForm ? (
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-auto">
                  <AddShopForm
                    onSave={handleAddShop}
                    shops={shops} />
                </div>
              </div>
            ) : (
              <div className="flex w-full overflow-auto bg-gray-50">

                {/* left table area */}
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">

                  <div className="flex justify-between items-center">
                    <div className="text-lg font-bold">商家管理</div>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleShowForm}
                    >
                      新增商家
                    </Button>
                  </div>

                  {/* Statistics */}
                  <div className="grid grid-cols-5 gap-4 mb-4">
                    <div className="col-span-5 bg-[#F3F3F3] rounded-lg shadow">
                      <div className="h-[36px] pt-2 pl-2 ">
                        <img
                          src="/images/folder.png"
                          alt="folder"
                          className="w-[20px] h-[20px]"
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">總商家數</div>
                          <div className="text-2xl font-bold">
                            {totalShops}
                          </div>
                        </div>
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">營業中</div>
                          <div className="text-2xl font-bold">
                            {availableShops}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-4 mb-4">
                    <Input
                      className="w-80"
                      placeholder="搜尋"
                      prefix={<SearchOutlined />}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    {/* <Select
                      className="w-40"
                      placeholder="上架商家"
                      options={providerOptions}
                      onChange={handleProviderChange}
                      allowClear
                      value={selectedProvider}
                    /> */}
                    <Select
                      className="w-28"
                      placeholder="前台狀態"
                      options={statusOptions}
                      onChange={handleStatusChange}
                      allowClear
                      value={selectedStatus}
                    />
                  </div>

                  <div className="flex-1 overflow-auto">
                    <Table
                      style={{
                        height: "100%",
                      }}
                      columns={columns}
                      dataSource={filteredShops}
                      pagination={{
                        pageSize: 10,
                        className: "sticky bottom-0 pb-2"
                      }}
                      onRow={(record) => ({
                        onClick: () => onRowClick(record),
                        className:
                          record.key === selectedService?.key
                            ? "bg-gray-100"
                            : "",
                      })}
                      rowClassName={(record) =>
                        `cursor-pointer ${record.key === selectedService?.key
                          ? "bg-gray-100"
                          : ""
                        }`
                      }
                      scroll={{ x: "max-content" }}
                      className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0] h-full"
                      size="small"
                    />
                  </div>
                </div>


                {/* right preview area */}
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <div>
                    {selectedShop && (
                      <ShopDetail shop={selectedShop} className="pl-6" />
                    )}
                  </div>
                  <div className="fixed w-[440px]  bottom-0 bg-white border shadow-3xl p-4">
                    <Button
                      type="primary"
                      className="w-full h-10 bg-[#4CAF50] hover:bg-[#45a049]"
                    >
                      編輯商家
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Shop;
