import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Carousel, Modal, Slider, Button, Tabs } from "antd";
import getCroppedImg from "@/utils/cropImage";
import 'react-easy-crop/react-easy-crop.css';
import YouTube from 'react-youtube';


const Preview = ({ data, onUpdateImage }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [imageType, setImageType] = useState(""); // 'logo', 'banner', 'mobile'
    const [imageIndex, setImageIndex] = useState(undefined);

    // get unique categories from partners
    const uniqueCategories = Array.from(
        new Set(data.partners?.map(partner => partner.category) || [])
    ).filter(Boolean);

    const showEditModal = (image, type, index) => {
        setCurrentImage(image);
        setImageType(type);
        setImageIndex(index);
        setIsModalVisible(true);
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleSaveCrop = useCallback(async () => {
        if (currentImage && imageType === 'mobile') {
            // 如果是 mobile 類型，直接使用原圖
            onUpdateImage(currentImage, imageType, imageIndex);
            setIsModalVisible(false);
        } else if (currentImage && croppedAreaPixels) {
            // 其他類型使用裁切功能
            const croppedImage = await getCroppedImg(currentImage, croppedAreaPixels, rotation);
            onUpdateImage(croppedImage, imageType, imageIndex);
            setIsModalVisible(false);
        }
    }, [currentImage, croppedAreaPixels, rotation, onUpdateImage, imageType, imageIndex]);

    // extract video id from youtube url
    const getYoutubeVideoId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    };

    // 根據圖片類型設置不同的寬高比和編輯區域大小
    const getImageSettings = (type) => {
        switch(type) {
            case 'logo':
                return {
                    aspectRatio: 1,
                    cropHeight: 400,
                    modalWidth: 600
                };
            case 'banner':
                return {
                    aspectRatio: 16/9,
                    cropHeight: 400,
                    modalWidth: 800
                };
            case 'mobile':
                return {
                    aspectRatio: undefined,
                    cropHeight: 600,
                    modalWidth: 1000
                };
            default:
                return {
                    aspectRatio: 16/9,
                    cropHeight: 400,
                    modalWidth: 800
                };
        }
    };

    return (
        <>
            <div className="bg-[#F3F3F3] p-4" style={{ boxShadow: '0px -4px 8px 0px #00000012 inset' }}>
                <span className="text-lg font-semibold mb-4">前台手機版預覽</span>
            </div>
            <div className="bg-white shadow-lg sticky top-4">
                {/* header */}
                <div className="p-4 border-b">
                    <div className="flex justify-center items-center gap-4">
                        {data.logo && (
                            <img
                                src={data.logo}
                                alt="Logo"
                                className="w-16 h-16 object-contain cursor-pointer"
                                onClick={() => data.logo && showEditModal(data.logo, "logo")}
                            />
                        )}
                        <h2 className="text-xl font-bold">{data.companyName || "LOGO"}</h2>
                    </div>
                </div>

                {/* banner carousel */}
                <div className="w-full aspect-video p-2">
                    <Carousel autoplay>
                        {data.bannerImages && data.bannerImages.length > 0 ? (
                            data.bannerImages.map((img, index) => (
                                <div key={index} className="w-full aspect-video">
                                    <img
                                        src={img}
                                        alt={`Banner ${index + 1}`}
                                        className="w-full h-full object-cover rounded-lg cursor-pointer"
                                        onClick={() => showEditModal(img, "banner", index)}
                                    />
                                </div>
                            ))
                        ) : (
                            Array.from({ length: 3 }).map((_, index) => (
                                <div key={index} className="w-full aspect-video bg-gray-200 rounded-lg" />
                            ))
                        )}
                    </Carousel>
                </div>

                {/* mobile content images */}
                <div className="p-4">
                    {data.mobileContentImages && data.mobileContentImages.length > 0 ? (
                        data.mobileContentImages.map((img, index) => (
                            <img
                                key={index}
                                src={img}
                                alt={`Mobile Content ${index + 1}`}
                                className="w-full h-full object-contain rounded-lg cursor-pointer"
                                onClick={() => showEditModal(img, "mobile", index)}
                            />
                        ))
                    ) : (
                        <div className="w-full aspect-[4/3] bg-gray-200 rounded-lg mb-4" />
                    )}
                </div>

                {/* video preview */}
                {data.videoUrl && (
                    <div className="m-4">
                        <div className="relative pt-[56.25%] w-full">
                            {getYoutubeVideoId(data.videoUrl) ? (
                                <YouTube
                                    videoId={getYoutubeVideoId(data.videoUrl)}
                                    opts={{
                                        width: '100%',
                                        height: '100%',
                                        playerVars: {
                                            autoplay: 0,
                                        },
                                    }}
                                    className="absolute top-0 left-0 w-full h-full"
                                />
                            ) : (
                                <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100">
                                    無效的 YouTube 網址
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/* partners section */}
                <div className="m-2 py-4">
                    <h3 className="text-lg font-semibold mb-4 text-center">合作廠商</h3>
                    <Tabs
                        defaultActiveKey="0"
                        items={uniqueCategories.length > 0 ? uniqueCategories.map((category, index) => ({
                            key: index.toString(),
                            label: category,
                            children: (
                                <div className="flex flex-wrap gap-4 justify-center">
                                    {data.partners?.filter(partner => partner.category === category)
                                        .map((partner, pIndex) => (
                                            partner.url ? (
                                                <a
                                                    key={pIndex}
                                                    href={partner.url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="block rounded-lg overflow-hidden hover:opacity-80 transition-opacity h-[81px] w-[81px] flex items-center justify-center basis-[calc(33.333%-1rem)]"
                                                >
                                                    {partner.logo ? (
                                                        <img
                                                            src={partner.logo}
                                                            alt={partner.name}
                                                            className="max-w-full max-h-full object-contain"
                                                        />
                                                    ) : (
                                                        <div className="w-full h-full flex items-center justify-center text-gray-400">
                                                            {partner.name || '未設定名稱'}
                                                        </div>
                                                    )}
                                                </a>
                                            ) : (
                                                <div
                                                    key={pIndex}
                                                    className="block rounded-lg overflow-hidden h-[81px] w-[81px] flex items-center justify-center basis-[calc(33.333%-1rem)]"
                                                >
                                                    {partner.logo ? (
                                                        <img
                                                            src={partner.logo}
                                                            alt={partner.name}
                                                            className="max-w-full max-h-full object-contain"
                                                        />
                                                    ) : (
                                                        <div className="w-full h-full flex items-center justify-center text-gray-400">
                                                            {partner.name || '未設定名稱'}
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        ))}
                                </div>
                            )
                        })) : [{
                            key: '0',
                            label: '類別',
                            children: (
                                <div className="flex flex-wrap gap-4 justify-center mb-4">
                                    {Array.from({ length: 3 }).map((_, index) => (
                                        <div
                                            key={index}
                                            className="h-[81px] w-[81px] bg-gray-200 rounded-lg basis-[calc(33.333%-1rem)]"
                                        />
                                    ))}
                                </div>
                            )
                        }]}
                        className="partner-tabs"
                    />
                </div>
            </div>

            {/* crop modal */}
            <Modal
                visible={isModalVisible}
                title="編輯圖片"
                onCancel={() => setIsModalVisible(false)}
                onOk={handleSaveCrop}
                width={getImageSettings(imageType).modalWidth}
                footer={[
                    <Button key="cancel" onClick={() => setIsModalVisible(false)}>
                        取消
                    </Button>,
                    <Button key="save" type="primary" onClick={handleSaveCrop}>
                        保存
                    </Button>,
                ]}
            >
                {currentImage && imageType !== 'mobile' ? (
                    <>
                        <div 
                            className="relative w-full"
                            style={{ height: getImageSettings(imageType).cropHeight }}
                        >
                            <Cropper
                                image={currentImage}
                                crop={crop}
                                zoom={zoom}
                                rotation={rotation}
                                aspect={getImageSettings(imageType).aspectRatio}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onRotationChange={setRotation}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                        {/* zoom control */}
                        <div className="mt-4">
                            <label>縮放</label>
                            <Slider
                                min={1}
                                max={3}
                                step={0.1}
                                value={zoom}
                                onChange={(value) => setZoom(value)}
                            />
                        </div>
                        {/* rotation control */}
                        <div className="mt-4">
                            <label>旋轉</label>
                            <Slider
                                min={0}
                                max={360}
                                step={1}
                                value={rotation}
                                onChange={(value) => setRotation(value)}
                            />
                        </div>
                    </>
                ) : (
                    <div className="w-full flex justify-center">
                        <img 
                            src={currentImage} 
                            alt="預覽圖片" 
                            className="max-w-full max-h-[600px] object-contain"
                        />
                    </div>
                )}
            </Modal>
            <footer className="bg-[#EAE6D9] p-6 mx-2 my-6">
                <div className="text-center">
                    <div className="flex flex-col items-center gap-4">
                        <div className="flex justify-center items-center gap-2">
                            {data.logo && (
                                <img
                                    src={data.logo}
                                    alt="Logo"
                                    className="w-16 h-16 object-contain cursor-pointer"
                                    onClick={() => data.logo && showEditModal(data.logo, "logo")}
                                />
                            )}
                            <h2 className="text-[24px] font-bold">{data.companyName}</h2>
                        </div>
                        <p className="text-[16px]">{data.companyDescription}</p>
                    </div>
                    <div className="flex justify-center mt-4">
                        <div className="flex gap-2">
                            {/* YouTube */}
                            {data.socialLinks?.youtube && (
                                <a href={data.socialLinks.youtube} target="_blank" rel="noopener noreferrer">
                                    <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                        <img src="/images/yt-1.png" alt="yt.png" width={25} height={25} />
                                    </button>
                                </a>
                            )}

                            {/* TikTok */}
                            {data.socialLinks?.tiktok && (
                                <a href={data.socialLinks.tiktok} target="_blank" rel="noopener noreferrer">
                                    <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                        <img src="/images/tiktok-1.png" alt="tiktop.png" width={25} height={25} />
                                    </button>
                                </a>
                            )}

                            {/* Instagram */}
                            {data.socialLinks?.instagram && (
                                <a href={data.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                                    <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                        <img src="/images/Instagram-1.png" alt="instagram.png" width={25} height={25} />
                                    </button>
                                </a>
                            )}

                            {/* Facebook */}
                            {data.socialLinks?.facebook && (
                                <a href={data.socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                                    <button className="flex justify-center hover:opacity-75 active:opacity-50 transition-opacity duration-200 h-[40px] w-[40px] rounded-full items-center bg-white">
                                        <img src="/images/Facebook-1.png" alt="facebook.png" width={25} height={25} />
                                    </button>
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <hr className="my-[20px] border-t border-gray-300" />
                <p className="text-center text-sm">
                    ©2024 慰心科技. All rights reserved
                </p>
            </footer>
        </>
    );

};

export default Preview;
