import React from 'react'

const SettingMenu = (props) => {
  return (
    <div className="flex flex-col w-64 bg-bgMedium p-4">
      <button className='bg-[#ADDEAC] p-2 rounded-[5px] w-full flex' onClick={() => props.changeTab(5)}>
        總後台管理
      </button>
    </div>
  )
}

export default SettingMenu;