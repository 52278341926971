import React, { useState, useEffect } from 'react';
import { Modal, message, Button, Input } from 'antd';
import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const { TextArea } = Input;

const MemoryModal = ({ token, recordId, roomId, url, open, onCancel }) => {
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [originalContent, setOriginalContent] = useState('');
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const data = await response.text();

        setOriginalContent(data);
        setEditedContent(data);

        // handle break lines
        const lines = data.split('\n');
        const formattedContent = lines.map((line, index) => <p key={index}>{line}</p>);

        setContent(formattedContent);
      } catch (error) {
        message.error('記憶讀取失敗，請重整');
      }
    };

    fetchData();
  }, [url]);

  const handleDiscard = () => {
    setEditMode(false);
    setEditedContent(originalContent);
  };

  const handleUpdate = async () => {
    setLoading(true);

    try {
      await axios.post(SERVER_URL + '/private/chatrooms/vector_store/text/' + roomId, {
        text: editedContent,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error('記憶更新失敗');
      setLoading(false);
      return
    }

    try {
      await axios.delete(SERVER_URL + '/private/chatrooms/vector_store/' + roomId + '?record_id=' + recordId, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      message.success('記憶更新成功，稍後重整即可查看');
      onCancel();
    } catch (error) {
      message.error('記憶更新失敗');
    } finally {
      setLoading(false);
      setEditMode(false);
    }
  };

  return (
    <Modal
      onCancel={onCancel}
      open={open}
      width={1024}
      footer={
        <div className="flex justify-end">
          {
            editMode ?
              <>
                <Button className='mr-2' onClick={handleDiscard} disabled={loading}>取消</Button>
                <Button type="primary" onClick={handleUpdate} loading={loading}>更新</Button>
              </>
              :
              <Button onClick={() => setEditMode(true)}>編輯</Button>
          }
        </div>
      }
    >
      <div>
        <a href={url} target="_blank" rel="noreferrer">
          <div className="text-xs text-textLight underline font-normal cursor-pointer mb-4">檢視原始檔案</div>
        </a>
        {
          editMode ?
            <TextArea
              value={editedContent}
              onChange={(e) => setEditedContent(e.target.value)}
              rows={22}
              disabled={loading}
            />
            :
            <div className="h-[500px] overflow-scroll border rounded p-2">{content}</div>
        }
      </div>
    </Modal>
  );
};

export default MemoryModal;