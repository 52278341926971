// AppProviders.js
import { AuthProvider } from "./AuthProvider";
import { UserProvider } from "./UserProvider";
import { BoundProvider } from "./BoundContext";
import { IntegrationProvider } from "./IntegrationContext";
import { NotificationProvider } from "./NotificationContext";
import { ServerProvider } from "./ServerContext";

const AppProviders = ({ children }) => {
  return (
    <AuthProvider>
      <UserProvider>
        <BoundProvider>
          <IntegrationProvider>
            <NotificationProvider>
              <ServerProvider>
                {children}
              </ServerProvider>
            </NotificationProvider>
          </IntegrationProvider>
        </BoundProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default AppProviders;
