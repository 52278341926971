import React, { createContext, useContext, useState, useEffect } from 'react';
import { initialData } from '../app/cms/panco/data/ShopData';
const ShopContext = createContext();


export const ShopProvider = ({ children }) => {
  const [globalShops, setGlobalShops] = useState(() => {
    try {
      // read data from localStorage
      const savedShops = localStorage.getItem('shops');
      
      // use initialData only when localStorage is empty
      if (!savedShops) {
        // save initialData to localStorage
        localStorage.setItem('shops', JSON.stringify(initialData));
        return initialData;
      }

      // parse saved data
      const parsedShops = JSON.parse(savedShops);
      
      // validate parsed data to be an array and not empty
      if (Array.isArray(parsedShops) && parsedShops.length > 0) {
        return parsedShops;
      }
      
      // if data is invalid, use initialData
      localStorage.setItem('shops', JSON.stringify(initialData));
      return initialData;
      
    } catch (error) {
      console.error('Error loading shops from localStorage:', error);
      // if error occurs, use initialData
      localStorage.setItem('shops', JSON.stringify(initialData));
      return initialData;
    }
  });

  // when globalShops changes, update localStorage
  useEffect(() => {
    try {
      localStorage.setItem('shops', JSON.stringify(globalShops));
    } catch (error) {
      console.error('Error saving shops to localStorage:', error);
    }
  }, [globalShops]);

  const value = {
    globalShops,
    setGlobalShops,
  };

  return (
    <ShopContext.Provider value={value}>
      {children}
    </ShopContext.Provider>
  );
};

export const useShop = () => {
  const context = useContext(ShopContext);
  if (!context) {
    throw new Error('useShop must be used within a ShopProvider');
  }
  return context;
};
