import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Redirection = ({ target }) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(target);
  }, [target]); // eslint-disable-line

  return (
    <div>Redirecting...</div>
  );
}

export default Redirection;