
export const initialData = [
  {
    key: '1',
    status: '上架中',
    provider: '審核通過',
    shopId: 'SP001',
    name: '美顏工作室',
    price: '王小明',
    order: '3',
    remaining: '5',
    memberDiscount: '12',
    description: '150,000',
    tags: ['美容', '美體'],
    publishDate: '2024-01-15',
    imageUrl: '/images/shop1.png',
    phone: '02-2345-6789',
    email: 'beauty@example.com',
    city: '台北市',
    district: '大安區'
  },
  {
    key: '2',
    status: '下架中',
    provider: '待審核',
    shopId: 'SP002',
    name: '沙龍美學',
    price: '李美玲',
    order: '5',
    remaining: '8',
    memberDiscount: '15',
    description: '280,000',
    tags: ['美髮', '美甲'],
    publishDate: '2024-02-01',
    imageUrl: '/images/shop2.png',
    phone: '02-8765-4321',
    email: 'salon@example.com',
    city: '台北市',
    district: '信義區'
  },
  {
    key: '3',
    status: '上架中',
    provider: '審核通過',
    shopId: 'SP003',
    name: '東方養生館',
    price: '張大華',
    order: '4',
    remaining: '6',
    memberDiscount: '8',
    description: '320,000',
    tags: ['按摩', '養生'],
    publishDate: '2024-01-20',
    imageUrl: '/images/shop3.png',
    phone: '02-2222-3333',
    email: 'wellness@example.com',
    city: '新北市',
    district: '板橋區'
  },
  {
    key: '4',
    status: '上架中',
    provider: '審核通過',
    shopId: 'SP004',
    name: '時尚美學中心',
    price: '陳雅琪',
    order: '6',
    remaining: '10',
    memberDiscount: '20',
    description: '450,000',
    tags: ['美容', '美睫'],
    publishDate: '2024-02-10',
    imageUrl: '/images/shop4.png',
    phone: '02-3333-4444',
    email: 'fashion@example.com',
    city: '台北市',
    district: '中山區'
  },
  {
    key: '5',
    status: '上架中',
    provider: '審核通過',
    shopId: 'SP005',
    name: '尊爵 SPA',
    price: '林志明',
    order: '8',
    remaining: '12',
    memberDiscount: '25',
    description: '680,000',
    tags: ['SPA', '美體'],
    publishDate: '2024-01-30',
    imageUrl: '/images/shop5.png',
    phone: '02-5555-6666',
    email: 'spa@example.com',
    city: '台北市',
    district: '內湖區'
  }
];