// utils/cropImage.ts
export default function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.setAttribute('crossOrigin', 'anonymous'); // 為了避免跨域問題
            image.src = url;
        });

    const getRadianAngle = (degree) => {
        return (degree * Math.PI) / 180;
    };

    return new Promise(async (resolve, reject) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return reject('No canvas context');
        }

        const maxSize = Math.max(image.width, image.height);
        const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

        // 设置 canvas 的尺寸为 safeArea
        canvas.width = safeArea;
        canvas.height = safeArea;

        // 将原始图片居中并旋转
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);
        ctx.drawImage(image, (safeArea - image.width) / 2, (safeArea - image.height) / 2);

        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        // 设置 canvas 的尺寸为裁切的大小
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // 将裁切后的图像绘制到新的 canvas 上
        ctx.putImageData(
            data,
            Math.round(-safeArea / 2 + image.width / 2 - pixelCrop.x),
            Math.round(-safeArea / 2 + image.height / 2 - pixelCrop.y)
        );

        // 将 canvas 转换为 Data URL
        resolve(canvas.toDataURL('image/jpeg'));
    });
}
