import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import userAvatar from '../../assets/userAvatar.png';
import { useBound } from '@/contexts/BoundContext';

const BoundListItem = ({ client, activeId, setActiveId, newMessage = false }) => {
  const { setRoomId, setMessageId, orderAgodaMessage } = useBound();
  const hasFakeMessage =
    client.last_two_messages[0].response &&
    client.last_two_messages[0].response.message === '#.aFaKeMeSsAgE';
  const [guestName, setGuestName] = useState('');

  useEffect(() => {
    // filter data by message id
    const data = orderAgodaMessage?.filter(
      (message) => message.message_id === client.messageId
    );

    // set guest name
    if (
      data.length > 0 &&
      data[0].result &&
      Array.isArray(data[0].result.guest_details) &&
      data[0].result.guest_details.length > 0 &&
      data[0].result.guest_details[0].length > 1
    ) {
      setGuestName(data[0].result.guest_details[0][1]);
    } else {
      setGuestName('Unknown Guest');
    }
  }, [orderAgodaMessage, client.messageId, client]);

  return (
    <div
      className={classnames(
        "flex flex-row gap-2 w-full h-full cursor-pointer px-2 py-2 items-center rounded bg-bgLight",
        { "hover:bg-bgDark": activeId !== client.messageId },
        { "bg-primaryMedium hover:bg-primaryMedium": activeId === client.userId}
      )}
      onClick={() => {
        setActiveId(client.messageId? client.messageId : client.userId);
        setRoomId(client.roomId);
        setMessageId(client.messageId);
      }}
    >
      <img
        src={client.pictureUrl ? client.pictureUrl : userAvatar}
        alt={client.displayName || guestName}
        className="w-12 h-12 rounded-full"
      />
      <div className="flex flex-col w-full h-full">
        <div className="flex flex-row w-full h-full gap-1 justify-between items-center line-clamp-1 truncate">
          <div>{client.displayName || guestName}</div>
          <div className="text-xs text-textLight mr-2">
            {
              hasFakeMessage || !client.last_two_messages[0].response
                ? client.last_two_messages[0].created_at
                : client.last_two_messages[0].response.created_at
            }
          </div>
        </div>
        <div className="flex flex-row h-full w-full items-center gap-1 justify-between">
          <div className="text-xs text-textDark line-clamp-1 w-[188px]">
            {
              hasFakeMessage || !client.last_two_messages[0].response
                ? (client.last_two_messages[0].message || "...")
                : (client.last_two_messages[0].response.message || "...")
            }
          </div>
          {
            newMessage && (
              <div className="bg-red-600 rounded-full w-2 h-2 my-auto" />
            )
          }
        </div>
      </div>
    </div>
  );
};

export default BoundListItem;
