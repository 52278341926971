import React, { createContext, useContext, useState } from "react";

const BoundContext = createContext();

export const BoundProvider = ({ children }) => {
  const [roomId, setRoomId] = useState("");
  const [messageId, setMessageId] = useState("");
  const [lineMessage, setLineMessage] = useState([]);
  const [airbnbMessage, setAirbnbMessage] = useState([]);
  const [agodaMessage, setAgodaMessage] = useState([]);

  const [orderLineMessage, setOrderLineMessage] = useState([]);
  const [orderAirbnbMessage, setOrderAirbnbMessage] = useState([]);
  const [orderAgodaMessage, setOrderAgodaMessage] = useState([]);

  const [listLoading, setListLoading] = useState(true);

  return (
    <BoundContext.Provider
      value={{
        roomId,
        setRoomId,
        messageId,
        setMessageId,
        lineMessage,
        setLineMessage,
        airbnbMessage,
        setAirbnbMessage,
        agodaMessage,
        setAgodaMessage,
        orderLineMessage,
        setOrderLineMessage,
        orderAirbnbMessage,
        setOrderAirbnbMessage,
        orderAgodaMessage,
        setOrderAgodaMessage,
        listLoading,
        setListLoading
      }}
    >
      {children}
    </BoundContext.Provider>
  );
};

// hook
export const useBound = () => {
  return useContext(BoundContext);
};
