import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { request } from '@/utils';

const initialState = {
    lineMessage: [],
    airbnbMessage: [],
    agodaMessage: [],

    orderLineMessage: [],
    orderAirbnbMessage: [],
    orderAgodaMessage: [],
};

// Fetch Airbnb messages
export const fetchAirbnbMessage = createAsyncThunk(
  'bound/fetchAirbnbMessage',
  async ({ room_id }) => {
      const res = await request.get(
          `/private/chatrooms/vector_store/template/${room_id}/all`
      );
      return res.data;
  }
);

// Fetch Line messages
export const fetchLineMessage = createAsyncThunk(
  'bound/fetchLineMessage',
  async ({ room_id }) => {
      const res = await request.get(
          `/private/chatrooms/line_store/template/${room_id}/all`
      );
      return res.data;
  }
);

// Fetch Agoda messages
export const fetchAgodaMessage = createAsyncThunk(
  'bound/fetchAgodaMessage',
  async ({ room_id }) => {
      const res = await request.get(
          `/private/chatrooms/agoda_store/template/${room_id}/all`
      );
      return res.data;
  }
);

const boundService = createSlice({
  name: 'bound',
  initialState,
  reducers: {
      // Set messages for different platforms
      setMessages(state, action) {
          const { platform, messages } = action.payload;
          if (Array.isArray(messages)) {
              state[`${platform}Message`] = messages;
          } else if (typeof messages === 'object' && messages !== null) {
              const index = state[`${platform}Message`].findIndex(
                  (item) => item.id === messages.id
              );
              if (index !== -1) {
                  state[`${platform}Message`][index] = messages;
              } else {
                  state[`${platform}Message`].push(messages);
              }
          } else {
              console.error(
                  'Invalid payload format: expected an array or an object.'
              );
          }
      },

      // Set ordered messages for a specific platform
      setOrderMessage(state, action) {
          const { platform, messages } = action.payload;
          state[`order${platform.charAt(0).toUpperCase() + platform.slice(1)}Message`] = messages;
      },
  },
  extraReducers: (builder) => {
      builder
          // Airbnb messages
          .addCase(fetchAirbnbMessage.fulfilled, (state, action) => {
              state.airbnbMessage = action.payload;
          })
          .addCase(fetchAirbnbMessage.rejected, (state, action) => {
              console.error('Failed to fetch Airbnb messages:', action.error);
          })
          // Line messages
          .addCase(fetchLineMessage.fulfilled, (state, action) => {
              state.lineMessage = action.payload;
          })
          .addCase(fetchLineMessage.rejected, (state, action) => {
              console.error('Failed to fetch Line messages:', action.error);
          })
          // Agoda messages
          .addCase(fetchAgodaMessage.fulfilled, (state, action) => {
              state.agodaMessage = action.payload;
          })
          .addCase(fetchAgodaMessage.rejected, (state, action) => {
              console.error('Failed to fetch Agoda messages:', action.error);
          });
  }
});

export const { setMessages, setOrderMessage } = boundService.actions;
export default boundService.reducer;
