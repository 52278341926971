import React from "react";
import { Button, Input } from "antd";

const StepTwoComponent = ({
  thirdPartyIntegrations,
  integrationsInfos,
  email,
  password,
  setEmail,
  setPassword,
  handleCancel,
  handleFinish,
  isSubmitting, 
}) => {
  return (
    <div className="w-full flex flex-col items-start gap-3">
      <div className="w-full bg-[#F0F0F0] border border-[#E0E0E0] rounded-[4px] flex flex-col p-4">
        <div className="text-[16px] font-normal">串接平台</div>
        <div className="w-full flex justify-between items-center text-black text-[16px]">
          <span className="mb-1 mt-3 flex items-center">
            {integrationsInfos.find(
              (integration) =>
                integration.name === thirdPartyIntegrations
            )?.icon && (
              <img
                src={
                  integrationsInfos.find(
                    (integration) =>
                      integration.name === thirdPartyIntegrations
                  )?.icon
                }
                alt={`${thirdPartyIntegrations}-icon`}
                className="w-[24px] h-[24px] mr-2"
              />
            )}
            {thirdPartyIntegrations}
          </span>
        </div>
        <hr className="w-full border-[#E0E0E0] mt-3" />
        <span className="mt-3">電子郵件</span>
        <Input
          placeholder="電子郵件"
          className="w-full mt-1"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isSubmitting} 
        />
        <hr className="w-full border-[#E0E0E0] mt-2" />
        <span className="mt-2">密碼</span>
        <Input.Password
          placeholder="密碼"
          className="w-full mt-1"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isSubmitting} 
        />
      </div>
      <div className="w-full flex justify-end gap-4">
        <Button
          onClick={handleCancel}
          className="text-black-900 text-[16px] leading-[1.3]"
          disabled={isSubmitting} 
        >
          取消
        </Button>
        <Button
          onClick={handleFinish}
          className="text-black-900 text-[16px] leading-[1.3]"
          style={{ backgroundColor: isSubmitting ? "#B8B8B7" : "#ADDEAC" }} 
          disabled={isSubmitting} 
        >
          完成
        </Button>
      </div>
    </div>
  );
};

export default StepTwoComponent;
