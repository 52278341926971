import React, { useEffect, useState } from "react";
import { Button, Form, Tabs, message, Select, Spin, Divider } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import "tailwindcss/tailwind.css";
import ServicePricing from "./ServicePricing";
import DiscountManagement from "./DiscountManagement";
import ServicePublish from "./ServicePublish";
import Information from "./Information";
import moment from "moment";
import { useShop } from '@/contexts/ShopContext';
import { Link } from "react-router-dom";
import { HomeOutlined } from '@ant-design/icons';

const AddServiceForm = ({ onSave, services }) => {
  const [loading, setLoading] = useState(true);
  const [tabLoading, setTabLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [form] = Form.useForm();
  const { globalShops } = useShop();
  const [previewData, setPreviewData] = useState({
    name: '預設服務名稱',
    provider: '商家',
    price: 0,
    description: '',
    serviceNotice: '',
    reservation: '',
    bookingPeriod: '',
    imageUrl: '/images/default-service.jpg',
  });

  // Get form values
  const formValues = Form.useWatch([], form);

  // Update preview data
  useEffect(() => {
    if (formValues) {
      // check if there is a pricing table and filter out valid duration options
      const validDurations = formValues.pricingTable?.filter(item =>
        item.price && item.price.trim() !== ''
      ) || [];

      // if there is a valid duration option, use the price of the first option as the default
      const defaultPrice = validDurations.length > 0 ? validDurations[0].price : 0;

      setPreviewData({
        name: formValues.serviceTitle || '請輸入 服務名稱',
        provider: formValues.provider || '請選擇 商家',
        price: formValues.fixedPrice || defaultPrice || 0,
        description: formValues.serviceDescription || '請輸入 服務說明',
        serviceNotice: formValues.serviceNotice || '請輸入 注意事項說明',
        reservation: formValues.reservation || '請輸入 預約說明',
        bookingPeriod: formValues.bookingPeriod || '請輸入 預約時間',
        imageUrl: formValues.serviceImage || '/images/default-service.jpg',
        pricingTable: validDurations.map(item => ({
          ...item,
          selected: item === validDurations[0] // default select the first option
        })),
      });
    }
  }, [formValues]);

  // Set default form values
  useEffect(() => {
    form.setFieldsValue({
      provider: "", // change from serviceTarget to provider
      serviceTitle: "",
      serviceNumber: Date.now().toString(),
      serviceImage: '',
    });
  }, [form]);

  // Handle save
  const handleSave = () => {
    form.validateFields().then((values) => {
      setSaveLoading(true); // show loading when saving

      try {
        console.log('提交的表單資訊:', values);

        // handle pricing mode and price
        const pricingMode = values.pricingMode;
        let finalPrice = values.fixedPrice;
        let pricingTable = null;

        if (pricingMode === 'tiered' && values.pricingTable) {
          // filter out valid price options
          pricingTable = values.pricingTable.filter(item =>
            item.price && item.price.trim() !== ''
          );

          // use the first valid price as the display price
          if (pricingTable.length > 0) {
            finalPrice = pricingTable[0].price;
          }
        }

        const newService = {
          key: (services.length + 1).toString(),
          provider: values.provider,
          serviceId: values.serviceNumber,
          status: '上架中',
          name: values.serviceTitle,
          price: finalPrice || 0,
          order: '0',
          remaining: values.remaining || '5',
          description: values.serviceDescription,
          serviceNotice: values.serviceNotice,
          reservation: values.reservation,
          bookingPeriod: values.bookingPeriod,
          tags: Array.isArray(values.tags) ? values.tags : [],
          memberDiscount: values.memberDiscountValue ? `${values.memberDiscountValue}折` : '無折扣',
          earlyBirdDiscount: values.earlyBirdDiscountValue ? `${values.earlyBirdDiscountValue}折` : '無折扣',
          publishDate: moment().format('YYYY-MM-DD'),
          imageUrl: values.serviceImage,
          pricingMode: pricingMode,
          pricingTable: pricingTable
        };

        console.log('新增的服務資料:', newService);
        onSave(newService);
        message.success("服務已成功增加並發布");
        form.resetFields();

        // simulate API request delay
        setTimeout(() => {
          setSaveLoading(false); // close loading after completion
        }, 1000);
      } catch (error) {
        setSaveLoading(false);
        message.error("儲存失敗，請稍後再試");
        console.error('儲存錯誤:', error);
      }
    }).catch((errorInfo) => {
      message.error("請填寫所有必要信息");
      console.log('errorInfo', errorInfo);
    });
  };

  // handle tab change
  const handleTabChange = (activeKey) => {
    setTabLoading(true);
    setTimeout(() => {
      setTabLoading(false);
    }, 500);
  };

  // breadcrumb navigation
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-lg font-bold">服務管理</div>

      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/service'}
        >
          服務管理
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">新增服務</span>
      </div>
    </div>
  );

  const items = [
    {
      key: "1",
      label: "基本資訊",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <Information form={form} shops={globalShops} />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: "服務規格",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <ServicePricing form={form} />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: "優惠折扣",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <DiscountManagement form={form} />
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: "服務上架",
      children: (
        <div className="bg-white p-6 rounded-lg">
          {tabLoading ? (
            <div className="flex justify-center items-center h-[400px]">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
          ) : (
            <ServicePublish form={form} />
          )}
        </div>
      ),
    },
  ];

  // Handle service preview
  const ServicePreview = ({ data }) => {
    return (
      <>
        <div className="bg-[#F3F3F3] p-4" style={{ boxShadow: '0px -4px 8px 0px #00000012 inset' }}>
          <span className="text-lg font-semibold mb-4">前台服務預覽</span>
        </div>
        <div className="bg-white shadow-lg sticky top-4">
          {/* Top area */}
          <div className="p-4">
            <div className="relative mb-4">
              <img
                src={data.imageUrl}
                alt={data.name}
                className="w-full h-48 object-cover"
              />
            </div>
            <h3 className="text-lg font-bold mb-1">{data.name}</h3>
            <p className="text-lg font-bold mb-2">{data.provider}</p>
            {/* Service description area */}
            <div>
              <p className="text-sm text-gray-700">{data.description}</p>
            </div>
            <div className="text-[24px] mb-2 text-primary flex items-baseline justify-end pb-2">
              <span className="font-roboto text-2xl font-medium leading-[26px] tracking-[-0.9px] text-right">
                $ {Number(data.price).toLocaleString()}
              </span>
              <span className="text-xl text-black ml-1 align-baseline">起</span>
            </div>
          </div>

          <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />

          {/* show duration selection according to pricingMode */}
          {formValues?.pricingMode === 'tiered' && data.pricingTable?.length > 0 && (
            <div className="p-4">
              <h4 className="text-base font-bold mb-3">選擇規格</h4>
              <h6 className="text-sm text-gray-600 mb-2">時長</h6>
              <div className="flex flex-wrap gap-2">
                {data.pricingTable.map((item) => (
                  <Button
                    key={item.actualDuration}
                    className="min-w-[80px] mb-2"
                    type={item.selected ? 'primary' : 'default'}
                    onClick={() => {
                      setPreviewData(prev => ({
                        ...prev,
                        price: item.price || 0,
                        pricingTable: prev.pricingTable.map(row => ({
                          ...row,
                          selected: row.actualDuration === item.actualDuration
                        }))
                      }));
                    }}
                  >
                    {item.actualDuration}
                  </Button>
                ))}
              </div>
            </div>
          )}

          {/* Reservation time selection area */}
          <div className="p-4">
            <h4 className="text-base font-bold mb-3">選擇預約時段</h4>
            <div className="grid grid-cols-2 gap-4 mb-4">
              <div>
                <div className="text-sm mb-2">日期</div>
                <Select
                  className="w-full"
                  placeholder="請選擇日期"
                  disabled
                  value={moment().format('YYYY-MM-DD')}
                >
                  {[...Array(7)].map((_, index) => {
                    const date = moment().add(index, 'days');
                    return (
                      <Select.Option key={date.format('YYYY-MM-DD')} value={date.format('YYYY-MM-DD')}>
                        {date.format('MM/DD (ddd)')}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div>
                <div className="text-sm mb-2">時間</div>
                <Select
                  className="w-full"
                  placeholder="請選擇時間"
                  disabled
                  value="15:00"
                >
                  {['09:00', '10:00', '11:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'].map((time) => (
                    <Select.Option key={time} value={time}>
                      {time}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            {/* 9-grid time selection */}
            <div className="grid grid-cols-3 gap-2">
              {[
                "09:00",
                "10:00",
                "11:00",
                "14:00",
                "15:00",
                "16:00",
                "17:00",
                "18:00",
                "19:00",
              ].map((time) => (
                <div
                  key={time}
                  className={`text-center py-2 ${time === "15:00" ? "bg-primary text-white" : "bg-gray-200"
                    } min-w-[80px] mb-2 rounded`}
                >
                  {time}
                </div>
              ))}
            </div>
          </div>
          <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />


          {/* Notice area */}
          <div className="p-4 border-b">
            <h4 className="text-base font-bold mb-2">注意事項</h4>
            <p className="text-sm text-gray-700">{data.serviceNotice}</p>
          </div>


        </div>
      </>
    );
  };

  // add loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-auto">

      {/* left form area */}
      <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full">
        {/* breadcrumb navigation */}
        <BreadcrumbNav />
        <Form
          form={form}
          layout="vertical"
        >
          <div className="bg-white rounded-lg mb-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6 p-4">
              <h1 className="text-[20px] font-semibold">新增服務</h1>
              <div className="flex gap-3">
                <Button type="default" className="w-full sm:w-auto">儲存至草稿</Button>
                <Button
                  type="primary"
                  onClick={handleSave}
                  className="w-full sm:w-auto"
                  loading={saveLoading} // add loading property
                  disabled={saveLoading} // disable button when loading
                >
                  {saveLoading ? '儲存中...' : '儲存並發佈'}
                </Button>
              </div>
            </div>
            <Tabs
              defaultActiveKey="1"
              size="large"
              items={items}
              onChange={handleTabChange}
              className="service-tabs"
            />
          </div>
        </Form>
      </div>

      {/* right preview area */}
      <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
        <div>
          {previewData && (
            <ServicePreview data={previewData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddServiceForm;
