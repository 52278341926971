
import { CloseOutlined } from '@ant-design/icons';
import { Input, message } from 'antd';
import { useState } from 'react';
import axios from 'axios';
import { useAuth } from '@/contexts/AuthProvider';


const ChangePassword = ({ handlePopup }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useAuth();
  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const isPasswordValid = (password) => {
    // 至少10個字符
    if (password.length < 10) return false;
    // 至少一個大寫字母
    if (!/[A-Z]/.test(password)) return false;
    // 至少一個小寫字母
    if (!/[a-z]/.test(password)) return false;
    // 至少一個數字
    if (!/[0-9]/.test(password)) return false;
    // 特殊符號僅支援 . _ ! # - 五種
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d._!#-]{10,}$/.test(password)) return false;

    const validCharacters = /^[a-zA-Z0-9\._!#-]+$/;
    if (!validCharacters.test(password)) return false;

    return true;
  };

  const handleSetPassword = async () => {

    if (password !== confirmPassword) {
      message.open({
        type: 'error',
        content: '密碼不一致',
        duration: 2,
      })
      return
    };
    if (!isPasswordValid(password)) {
      message.open({
        type: 'error',
        content: '密碼格式錯誤',
        duration: 2,
      });
      return;
    }

    try {
      await axios.patch(`${SERVER_URL}/private/user/password`,
        { "password": password },
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        });
      message.success({
        content: '密碼重設成功',
        duration: 2,
      })
      setTimeout(() => {
        handlePopup();
      }, "2000")

    } catch (error) {
      message.error({
        content: '密碼重設失敗',
        duration: 2
      })
    }
  };


  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-40 z-30 '>
      <div className='w-[360px] h-[300px] bg-[#F2F2F2] flex flex-col justify-between  rounded-xl overflow-hidden'>
        {/* <button className='flex justify-end p-3' > */}
        <div className='flex justify-end p-3'>
          <CloseOutlined onClick={handlePopup} />
        </div>
        <h3 className='font-semibold text-[20px] flex justify-center text-black'>
          變更密碼
        </h3>
        {/* </button> */}
        <div className='px-7 text-[#5B5B5B]'>
          {/* <h3 className='font-semibold text-[20px] flex justify-center text-black'>變更密碼</h3> */}
          <div className='mb-5'>
            <p>新密碼</p>
            <Input.Password onChange={(e) => { setPassword(e.target.value) }} />
          </div>
          <div className='mb-5'>
            <p>確認密碼</p>
            <Input.Password onChange={(e) => { setConfirmPassword(e.target.value) }} />
            <p className='text-[10px] mt-[5px]'>
              密碼需包含至少一個大寫字母、一個小寫字母、一個數字，且長度需大於 10 個字元，特殊符號僅支援 . _ ! # - 五種。
            </p>
          </div>
        </div>
        <button className='h-[30px] bg-[#ADDEAC] font-[400px] text-[16px] text-[#3E3D3D]'
          onClick={handleSetPassword}>
          變更
        </button>
      </div>
    </div>
  )
}

export default ChangePassword;