export const initialData = [
  {
    key: '1',
    memberId: 'MB001',
    name: '王小明',
    gender: '男',
    phone: '0912-345-678',
    email: 'wang.xm@email.com',
    birthDate: '1990-03-15',
    registerDate: '2024-01-01',
    status: '正常',
    level: '一般會員',
    points: 2500,
    consumeCount: 12,
    lastLoginDate: '2024-03-28',
    address: '台北市信義區信義路五段100號',
    tags: ['新會員', '美容愛好者'],
    boundBeauticians: [
      { id: 'BT001', name: '張美容' },
      { id: 'BT003', name: '李美妝' }
    ],
    serviceOrders: [
      { id: 'SO001', date: '2024-03-25', service: '基礎護膚', amount: 2000 },
      { id: 'SO002', date: '2024-03-15', service: '深層清潔', amount: 1500 }
    ],
    productOrders: [
      { id: 'PO001', date: '2024-03-20', product: '保濕面霜', amount: 1200 },
      { id: 'PO002', date: '2024-03-10', product: '潔面乳', amount: 800 }
    ],
    tickets: [
      { id: 'TK001', type: '折價券', value: 200, expireDate: '2024-06-30' },
      { id: 'TK002', type: '護理券', value: 500, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 2,
    memberLevel: "一般會員",
    socialPlatform: "FB, IG",
    socialAccount: "@wang.xm",
    source: "網路搜尋",
    totalSpent: 25000,
    orderCount: 12,
  },
  {
    key: '2',
    memberId: 'MB002',
    name: '林美玲',
    gender: '女',
    phone: '0923-456-789',
    email: 'mei.lin@email.com',
    birthDate: '1988-07-22',
    registerDate: '2024-01-05',
    status: '正常',
    level: 'VIP會員',
    points: 8500,
    consumeCount: 25,
    lastLoginDate: '2024-03-27',
    address: '台北市大安區忠孝東路四段50號',
    tags: ['高消費', 'VIP'],
    boundBeauticians: [
      { id: 'BT002', name: '王美妍' },
      { id: 'BT004', name: '陳美容' },
      { id: 'BT005', name: '林美姿' }
    ],
    serviceOrders: [
      { id: 'SO003', date: '2024-03-28', service: 'VIP全身護理', amount: 5000 },
      { id: 'SO004', date: '2024-03-20', service: '美容SPA', amount: 3500 },
      { id: 'SO005', date: '2024-03-10', service: '臉部保養', amount: 2800 }
    ],
    productOrders: [
      { id: 'PO003', date: '2024-03-25', product: '精華液套組', amount: 4500 },
      { id: 'PO004', date: '2024-03-15', product: '抗老面膜', amount: 2000 },
      { id: 'PO005', date: '2024-03-05', product: '美白精華', amount: 3000 }
    ],
    tickets: [
      { id: 'TK003', type: 'VIP折價券', value: 1000, expireDate: '2024-07-31' },
      { id: 'TK004', type: 'SPA護理券', value: 2000, expireDate: '2024-08-31' }
    ],
    beauticiansCount: 3,
    memberLevel: "VIP會員",
    socialPlatform: "FB, IG, LINE",
    socialAccount: "@mei.lin_beauty",
    source: "實體店面",
    totalSpent: 120000,
    orderCount: 25,
  },
  {
    key: '3',
    memberId: 'MB003',
    name: '志豪',
    gender: '男',
    phone: '0934-567-890',
    email: 'zhang.zh@email.com',
    birthDate: '1995-11-30',
    registerDate: '2024-01-10',
    status: '停用',
    level: '一般會員',
    points: 1200,
    consumeCount: 5,
    lastLoginDate: '2024-02-15',
    address: '新北市板橋區中山路一段200號',
    tags: ['停用會員'],
    boundBeauticians: [
      { id: 'BT006', name: '黃美琪' }
    ],
    serviceOrders: [
      { id: 'SO006', date: '2024-02-15', service: '基礎護理', amount: 1500 },
      { id: 'SO007', date: '2024-02-01', service: '臉部清潔', amount: 1000 }
    ],
    productOrders: [
      { id: 'PO006', date: '2024-02-10', product: '洗面乳', amount: 500 }
    ],
    tickets: [
      { id: 'TK005', type: '折價券', value: 100, expireDate: '2024-05-31' }
    ],
    beauticiansCount: 1,
    memberLevel: "一般會員",
    socialPlatform: "FB",
    socialAccount: "@zhang.zh",
    source: "實體店面",
    totalSpent: 8000,
    orderCount: 5,
  },
  {
    key: '4',
    memberId: 'MB004',
    name: '陳雅琪',
    gender: '女',
    phone: '0945-678-901',
    email: 'chen.yq@email.com',
    birthDate: '1992-05-18',
    registerDate: '2024-01-15',
    status: '正常',
    level: '白金會員',
    points: 12000,
    consumeCount: 35,
    lastLoginDate: '2024-03-28',
    address: '台北市中山區南京東路三段80號',
    tags: ['白金會員', '美妝達人'],
    boundBeauticians: [
      { id: 'BT007', name: '楊美麗' },
      { id: 'BT008', name: '趙美芳' },
      { id: 'BT009', name: '吳美玲' }
    ],
    serviceOrders: [
      { id: 'SO008', date: '2024-03-28', service: '白金級護理', amount: 6000 },
      { id: 'SO009', date: '2024-03-20', service: '美容護理', amount: 4500 },
      { id: 'SO010', date: '2024-03-15', service: '身體護理', amount: 3500 },
      { id: 'SO011', date: '2024-03-10', service: '臉部護理', amount: 2500 }
    ],
    productOrders: [
      { id: 'PO007', date: '2024-03-25', product: '奢華保養組', amount: 8000 },
      { id: 'PO008', date: '2024-03-18', product: '美白精華液', amount: 3500 },
      { id: 'PO009', date: '2024-03-12', product: '抗老面霜', amount: 4500 }
    ],
    tickets: [
      { id: 'TK006', type: '白金會員券', value: 2000, expireDate: '2024-08-31' },
      { id: 'TK007', type: 'SPA券', value: 1500, expireDate: '2024-07-31' },
      { id: 'TK008', type: '生日優惠券', value: 1000, expireDate: '2024-09-30' }
    ],
    beauticiansCount: 3,
    memberLevel: "白金會員",
    socialPlatform: "FB, IG, LINE",
    socialAccount: "@chen.yq_beauty",
    source: "朋友推薦",
    totalSpent: 120000,
    orderCount: 35,
  },
  {
    key: '5',
    memberId: 'MB005',
    name: '李俊宏',
    gender: '男',
    phone: '0956-789-012',
    email: 'lee.jh@email.com',
    birthDate: '1987-09-25',
    registerDate: '2024-01-20',
    status: '正常',
    level: '一般會員',
    points: 3500,
    consumeCount: 15,
    lastLoginDate: '2024-03-26',
    address: '台北市松山區民生東路四段120號',
    tags: ['運動愛好者'],
    boundBeauticians: [
      { id: 'BT010', name: '周美伶' },
      { id: 'BT011', name: '謝美玲' }
    ],
    serviceOrders: [
      { id: 'SO012', date: '2024-03-26', service: '運動按摩', amount: 2000 },
      { id: 'SO013', date: '2024-03-15', service: '基礎護理', amount: 1500 },
      { id: 'SO014', date: '2024-03-05', service: '臉部護理', amount: 1800 }
    ],
    productOrders: [
      { id: 'PO010', date: '2024-03-20', product: '保濕乳液', amount: 1200 },
      { id: 'PO011', date: '2024-03-10', product: '運動後修護乳', amount: 1500 }
    ],
    tickets: [
      { id: 'TK009', type: '按摩券', value: 500, expireDate: '2024-06-30' },
      { id: 'TK010', type: '折價券', value: 300, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 2,
    memberLevel: "一般會員",
    socialPlatform: "FB, IG",
    socialAccount: "@lee.jh",
    source: "網路搜尋",
    totalSpent: 12000,
    orderCount: 15,
  },
  {
    key: '6',
    memberId: 'MB006',
    name: '吳佳穎',
    gender: '女',
    phone: '0967-890-123',
    email: 'wu.jy@email.com',
    birthDate: '1993-12-08',
    registerDate: '2024-01-25',
    status: '正常',
    level: 'VIP會員',
    points: 9500,
    consumeCount: 28,
    lastLoginDate: '2024-03-28',
    address: '台北市內湖區成功路三段150號',
    tags: ['VIP', '按摩愛好者'],
    boundBeauticians: [
      { id: 'BT012', name: '劉美珍' },
      { id: 'BT013', name: '蔡美琪' }
    ],
    serviceOrders: [
      { id: 'SO015', date: '2024-03-28', service: 'VIP按摩', amount: 3500 },
      { id: 'SO016', date: '2024-03-20', service: '精油按摩', amount: 2800 },
      { id: 'SO017', date: '2024-03-10', service: '臉部護理', amount: 2000 }
    ],
    productOrders: [
      { id: 'PO012', date: '2024-03-25', product: '精油組合', amount: 3000 },
      { id: 'PO013', date: '2024-03-15', product: '按摩精油', amount: 1800 }
    ],
    tickets: [
      { id: 'TK011', type: 'VIP按摩券', value: 1000, expireDate: '2024-08-31' },
      { id: 'TK012', type: '精油護理券', value: 800, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 2,
    memberLevel: "VIP會員",
    socialPlatform: "FB, IG",
    socialAccount: "@wu.jy",
    source: "實體店面",
    totalSpent: 12000,
    orderCount: 28,
  },
  {
    key: '7',
    memberId: 'MB007',
    name: '黃建志',
    gender: '男',
    phone: '0978-901-234',
    email: 'huang.jz@email.com',
    birthDate: '1991-04-12',
    registerDate: '2024-02-01',
    status: '停用',
    level: '一般會員',
    points: 800,
    consumeCount: 3,
    lastLoginDate: '2024-02-28',
    address: '新北市中和區中和路250號',
    tags: ['停用會員'],
    boundBeauticians: [
      { id: 'BT014', name: '許美玲' }
    ],
    serviceOrders: [
      { id: 'SO018', date: '2024-02-28', service: '基礎護理', amount: 1500 }
    ],
    productOrders: [
      { id: 'PO014', date: '2024-02-25', product: '保濕乳液', amount: 800 }
    ],
    tickets: [
      { id: 'TK013', type: '折價券', value: 200, expireDate: '2024-05-31' }
    ],
    beauticiansCount: 1,
    memberLevel: "一般會員",
    socialPlatform: "FB",
    socialAccount: "@huang.jz",
    source: "實體店面",
    totalSpent: 15000,
    orderCount: 3,
  },
  {
    key: '8',
    memberId: 'MB008',
    name: '許雅婷',
    gender: '女',
    phone: '0989-012-345',
    email: 'xu.yt@email.com',
    birthDate: '1994-08-20',
    registerDate: '2024-02-05',
    status: '正常',
    level: '白金會員',
    points: 15000,
    consumeCount: 42,
    lastLoginDate: '2024-03-27',
    address: '台北市大同區民權西路180號',
    tags: ['白金會員', '護膚達人'],
    boundBeauticians: [
      { id: 'BT015', name: '張美琳' },
      { id: 'BT016', name: '李美芳' },
      { id: 'BT017', name: '王美玲' }
    ],
    serviceOrders: [
      { id: 'SO019', date: '2024-03-27', service: '白金護膚', amount: 5500 },
      { id: 'SO020', date: '2024-03-20', service: '美容SPA', amount: 4500 },
      { id: 'SO021', date: '2024-03-15', service: '身體護理', amount: 3500 },
      { id: 'SO022', date: '2024-03-10', service: '深層護理', amount: 3000 }
    ],
    productOrders: [
      { id: 'PO015', date: '2024-03-25', product: '護膚套組', amount: 6000 },
      { id: 'PO016', date: '2024-03-18', product: '精華液', amount: 3500 },
      { id: 'PO017', date: '2024-03-12', product: '面膜組合', amount: 2500 }
    ],
    tickets: [
      { id: 'TK014', type: '白金護理券', value: 2000, expireDate: '2024-09-30' },
      { id: 'TK015', type: 'SPA券', value: 1500, expireDate: '2024-08-31' }
    ],
    beauticiansCount: 3,
    memberLevel: "白金會員",
    socialPlatform: "FB, IG, LINE",
    socialAccount: "@xu.yt",
    source: "實體店面",
    totalSpent: 120000,
    orderCount: 42,
  },
  {
    key: '9',
    memberId: 'MB009',
    name: '周明宏',
    gender: '男',
    phone: '0990-123-456',
    email: 'zhou.mh@email.com',
    birthDate: '1989-02-28',
    registerDate: '2024-02-10',
    status: '正常',
    level: '一般會員',
    points: 4200,
    consumeCount: 18,
    lastLoginDate: '2024-03-25',
    address: '新北市永和區永和路一段300號',
    tags: ['美容新手'],
    boundBeauticians: [
      { id: 'BT018', name: '陳美玲' },
      { id: 'BT019', name: '林美琪' }
    ],
    serviceOrders: [
      { id: 'SO023', date: '2024-03-25', service: '基礎護理', amount: 1800 },
      { id: 'SO024', date: '2024-03-15', service: '臉部清潔', amount: 1500 }
    ],
    productOrders: [
      { id: 'PO018', date: '2024-03-20', product: '潔顏組合', amount: 1200 },
      { id: 'PO019', date: '2024-03-10', product: '保濕面霜', amount: 1000 }
    ],
    tickets: [
      { id: 'TK016', type: '護理券', value: 500, expireDate: '2024-07-31' },
      { id: 'TK017', type: '折價券', value: 300, expireDate: '2024-06-30' }
    ],
    beauticiansCount: 2,
    memberLevel: "一般會員",
    socialPlatform: "FB, IG",
    socialAccount: "@zhou.mh",
    source: "網路搜尋",
    totalSpent: 12000,
    orderCount: 18,
  },
  {
    key: '10',
    memberId: 'MB010',
    name: '楊淑芬',
    gender: '女',
    phone: '0901-234-567',
    email: 'yang.sf@email.com',
    birthDate: '1996-06-15',
    registerDate: '2024-02-15',
    status: '正常',
    level: 'VIP會員',
    points: 7800,
    consumeCount: 23,
    lastLoginDate: '2024-03-28',
    address: '台北市文山區木柵路二段90號',
    tags: ['VIP', '美甲愛好者'],
    boundBeauticians: [
      { id: 'BT020', name: '黃美琳' },
      { id: 'BT021', name: '吳美玲' }
    ],
    serviceOrders: [
      { id: 'SO025', date: '2024-03-28', service: 'VIP美甲', amount: 3000 },
      { id: 'SO026', date: '2024-03-20', service: '手足護理', amount: 2500 },
      { id: 'SO027', date: '2024-03-10', service: '光療美甲', amount: 1800 }
    ],
    productOrders: [
      { id: 'PO020', date: '2024-03-25', product: '美甲工具組', amount: 2500 },
      { id: 'PO021', date: '2024-03-15', product: '指甲油套組', amount: 1500 }
    ],
    tickets: [
      { id: 'TK018', type: 'VIP美甲券', value: 1000, expireDate: '2024-08-31' },
      { id: 'TK019', type: '手足護理券', value: 800, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 2,
    memberLevel: "VIP會員",
    socialPlatform: "FB, IG",
    socialAccount: "@yang.sf",
    source: "實體店面",
    totalSpent: 12000,
    orderCount: 23,
  },
  {
    key: '11',
    memberId: 'MB011',
    name: '謝宗翰',
    gender: '男',
    phone: '0912-345-678',
    email: 'xie.zh@email.com',
    birthDate: '1986-10-05',
    registerDate: '2024-02-20',
    status: '正常',
    level: '一般會員',
    points: 2800,
    consumeCount: 10,
    lastLoginDate: '2024-03-26',
    address: '新北市三重區重新路四段120號',
    tags: ['按摩愛好者'],
    boundBeauticians: [
      { id: 'BT022', name: '楊美華' },
      { id: 'BT023', name: '周美玲' }
    ],
    serviceOrders: [
      { id: 'SO028', date: '2024-03-26', service: '全身按摩', amount: 2500 },
      { id: 'SO029', date: '2024-03-15', service: '肩頸按摩', amount: 1800 },
      { id: 'SO030', date: '2024-03-05', service: '足部按摩', amount: 1500 }
    ],
    productOrders: [
      { id: 'PO022', date: '2024-03-20', product: '按摩精油', amount: 1200 },
      { id: 'PO023', date: '2024-03-10', product: '舒緩凝膠', amount: 800 }
    ],
    tickets: [
      { id: 'TK020', type: '按摩券', value: 500, expireDate: '2024-07-31' },
      { id: 'TK021', type: '折價券', value: 300, expireDate: '2024-06-30' }
    ],
    beauticiansCount: 2,
    memberLevel: "一般會員",
    socialPlatform: "FB, IG",
    socialAccount: "@xie.zh",
    source: "實體店面",
    totalSpent: 12000,
    orderCount: 10,
  },
  {
    key: '12',
    memberId: 'MB012',
    name: '郭雅惠',
    gender: '女',
    phone: '0923-456-789',
    email: 'guo.yh@email.com',
    birthDate: '1993-01-25',
    registerDate: '2024-02-25',
    status: '正常',
    level: '白金會員',
    points: 13500,
    consumeCount: 38,
    lastLoginDate: '2024-03-28',
    address: '台北市士林區中正路250號',
    tags: ['白金會員', '美顏達人'],
    boundBeauticians: [
      { id: 'BT024', name: '林美華' },
      { id: 'BT025', name: '張美琪' },
      { id: 'BT026', name: '王美玲' }
    ],
    serviceOrders: [
      { id: 'SO031', date: '2024-03-28', service: '白金美髮護理', amount: 5000 },
      { id: 'SO032', date: '2024-03-20', service: '頭皮護理', amount: 3500 },
      { id: 'SO033', date: '2024-03-15', service: '染髮護理', amount: 4000 }
    ],
    productOrders: [
      { id: 'PO024', date: '2024-03-25', product: '髮品套組', amount: 4500 },
      { id: 'PO025', date: '2024-03-18', product: '護髮精油', amount: 2500 },
      { id: 'PO026', date: '2024-03-12', product: '頭皮精華', amount: 3000 }
    ],
    tickets: [
      { id: 'TK022', type: '白金美髮券', value: 2000, expireDate: '2024-08-31' },
      { id: 'TK023', type: '護髮券', value: 1500, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 3,
    memberLevel: "白金會員",
    socialPlatform: "FB, IG",
    socialAccount: "@guo.yh",
    source: "實體店面",
    totalSpent: 120000,
    orderCount: 38,
  },
  {
    key: '13',
    memberId: 'MB013',
    name: '沈俊傑',
    gender: '男',
    phone: '0934-567-890',
    email: 'shen.jj@email.com',
    birthDate: '1990-12-12',
    registerDate: '2024-03-01',
    status: '停用',
    level: '一般會員',
    points: 1500,
    consumeCount: 6,
    lastLoginDate: '2024-03-15',
    address: '新北市新莊區新莊路100號',
    tags: ['停用會員'],
    boundBeauticians: [
      { id: 'BT027', name: '陳美華' }
    ],
    serviceOrders: [
      { id: 'SO034', date: '2024-03-15', service: '基礎護理', amount: 1500 },
      { id: 'SO035', date: '2024-03-01', service: '臉部清潔', amount: 1000 }
    ],
    productOrders: [
      { id: 'PO027', date: '2024-03-10', product: '潔面乳', amount: 600 }
    ],
    tickets: [
      { id: 'TK024', type: '折價券', value: 200, expireDate: '2024-05-31' }
    ],
    beauticiansCount: 1,
    memberLevel: "一般會員",
    socialPlatform: "FB",
    socialAccount: "@shen.jj",
    source: "實體店面",
    totalSpent: 15000,
    orderCount: 6,
  },
  {
    key: '14',
    memberId: 'MB014',
    name: '林詩涵',
    gender: '女',
    phone: '0945-678-901',
    email: 'lin.sh@email.com',
    birthDate: '1995-03-30',
    registerDate: '2024-03-05',
    status: '正常',
    level: 'VIP會員',
    points: 6500,
    consumeCount: 20,
    lastLoginDate: '2024-03-27',
    address: '台北市北投區中央北路一段80號',
    tags: ['VIP', '美睫愛好者'],
    boundBeauticians: [
      { id: 'BT028', name: '李美華' },
      { id: 'BT029', name: '黃美玲' }
    ],
    serviceOrders: [
      { id: 'SO036', date: '2024-03-27', service: 'VIP美睫', amount: 3500 },
      { id: 'SO037', date: '2024-03-15', service: '睫毛護理', amount: 2500 },
      { id: 'SO038', date: '2024-03-05', service: '眼部護理', amount: 1800 }
    ],
    productOrders: [
      { id: 'PO028', date: '2024-03-20', product: '睫毛營養液', amount: 2000 },
      { id: 'PO029', date: '2024-03-10', product: '眼部精華', amount: 1500 }
    ],
    tickets: [
      { id: 'TK025', type: 'VIP美睫券', value: 1000, expireDate: '2024-08-31' },
      { id: 'TK026', type: '護理券', value: 800, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 2,
    memberLevel: "VIP會員",
    socialPlatform: "FB, IG",
    socialAccount: "@lin.sh",
    source: "實體店面",
    totalSpent: 120000,
    orderCount: 20,
  },
  {
    key: '15',
    memberId: 'MB015',
    name: '張家豪',
    gender: '男',
    phone: '0956-789-012',
    email: 'zhang.jh@email.com',
    birthDate: '1988-07-08',
    registerDate: '2024-03-10',
    status: '正常',
    level: '一般會員',
    points: 3200,
    consumeCount: 14,
    lastLoginDate: '2024-03-25',
    address: '新北市蘆洲區長安街150號',
    tags: ['護膚愛好者'],
    boundBeauticians: [
      { id: 'BT030', name: '吳美華' },
      { id: 'BT031', name: '趙美玲' }
    ],
    serviceOrders: [
      { id: 'SO039', date: '2024-03-25', service: '保濕護理', amount: 2000 },
      { id: 'SO040', date: '2024-03-15', service: '深層保養', amount: 2500 },
      { id: 'SO041', date: '2024-03-05', service: '臉部護理', amount: 1800 }
    ],
    productOrders: [
      { id: 'PO030', date: '2024-03-20', product: '保濕面霜', amount: 1500 },
      { id: 'PO031', date: '2024-03-10', product: '精華液', amount: 2000 }
    ],
    tickets: [
      { id: 'TK027', type: '護理券', value: 500, expireDate: '2024-07-31' },
      { id: 'TK028', type: '折價券', value: 300, expireDate: '2024-06-30' }
    ],
    beauticiansCount: 2,
    memberLevel: "一般會員",
    socialPlatform: "FB, IG",
    socialAccount: "@zhang.jh",
    source: "實體店面",
    totalSpent: 45000,
    orderCount: 14,
  },
  {
    key: '16',
    memberId: 'MB016',
    name: '劉美玲',
    gender: '女',
    phone: '0967-890-123',
    email: 'liu.ml@email.com',
    birthDate: '1992-09-18',
    registerDate: '2024-03-15',
    status: '正常',
    level: '白金會員',
    points: 11000,
    consumeCount: 32,
    lastLoginDate: '2024-03-28',
    address: '台北市萬華區西寧南路180號',
    tags: ['白金會員', '美容部落客'],
    boundBeauticians: [
      { id: 'BT032', name: '張美華' },
      { id: 'BT033', name: '李美玲' },
      { id: 'BT034', name: '王美琪' }
    ],
    serviceOrders: [
      { id: 'SO042', date: '2024-03-28', service: '白金美容護理', amount: 5500 },
      { id: 'SO043', date: '2024-03-20', service: '美容SPA', amount: 4000 },
      { id: 'SO044', date: '2024-03-15', service: '全身護理', amount: 3500 },
      { id: 'SO045', date: '2024-03-10', service: '臉部護理', amount: 2500 }
    ],
    productOrders: [
      { id: 'PO032', date: '2024-03-25', product: '奢華保養組', amount: 6000 },
      { id: 'PO033', date: '2024-03-18', product: '美白精華', amount: 3500 },
      { id: 'PO034', date: '2024-03-12', product: '抗老面霜', amount: 2800 }
    ],
    tickets: [
      { id: 'TK029', type: '白金護理券', value: 2000, expireDate: '2024-09-30' },
      { id: 'TK030', type: 'SPA券', value: 1500, expireDate: '2024-08-31' },
      { id: 'TK031', type: '生日優惠券', value: 1000, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 3,
    memberLevel: "白金會員",
    socialPlatform: "FB, IG, LINE",
    socialAccount: "@liu.ml_beauty",
    source: "實體店面",
    totalSpent: 155000,
    orderCount: 32,
  },
  {
    key: '17',
    memberId: 'MB017',
    name: '陳志明',
    gender: '男',
    phone: '0978-901-234',
    email: 'chen.zm@email.com',
    birthDate: '1987-05-22',
    registerDate: '2024-03-20',
    status: '正常',
    level: '一般會員',
    points: 2000,
    consumeCount: 8,
    lastLoginDate: '2024-03-26',
    address: '新北市淡水區中正路三段200號',
    tags: ['美容新手'],
    boundBeauticians: [
      { id: 'BT035', name: '陳美華' },
      { id: 'BT036', name: '林美玲' }
    ],
    serviceOrders: [
      { id: 'SO046', date: '2024-03-26', service: '基礎護理', amount: 1800 },
      { id: 'SO047', date: '2024-03-15', service: '臉部清潔', amount: 1500 }
    ],
    productOrders: [
      { id: 'PO035', date: '2024-03-20', product: '保濕乳液', amount: 1200 },
      { id: 'PO036', date: '2024-03-10', product: '潔面乳', amount: 800 }
    ],
    tickets: [
      { id: 'TK032', type: '護理券', value: 500, expireDate: '2024-06-30' },
      { id: 'TK033', type: '折價券', value: 300, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 2,
    memberLevel: "一般會員",
    socialPlatform: "FB, IG",
    socialAccount: "@chen.zm",
    source: "網路搜尋",
    totalSpent: 18000,
    orderCount: 8,
  },
  {
    key: '18',
    memberId: 'MB018',
    name: '王雅琳',
    gender: '女',
    phone: '0989-012-345',
    email: 'wang.yl@email.com',
    birthDate: '1994-11-15',
    registerDate: '2024-03-25',
    status: '正常',
    level: 'VIP會員',
    points: 5800,
    consumeCount: 16,
    lastLoginDate: '2024-03-28',
    address: '台北市信義區松仁路50號',
    tags: ['VIP', '按摩愛好者'],
    boundBeauticians: [
      { id: 'BT037', name: '黃美華' },
      { id: 'BT038', name: '趙美玲' }
    ],
    serviceOrders: [
      { id: 'SO048', date: '2024-03-28', service: 'VIP按摩', amount: 3000 },
      { id: 'SO049', date: '2024-03-20', service: '精油按摩', amount: 2500 },
      { id: 'SO050', date: '2024-03-10', service: '全身按摩', amount: 2000 }
    ],
    productOrders: [
      { id: 'PO037', date: '2024-03-25', product: '精油組合', amount: 2500 },
      { id: 'PO038', date: '2024-03-15', product: '按摩油', amount: 1500 }
    ],
    tickets: [
      { id: 'TK034', type: 'VIP按摩券', value: 1000, expireDate: '2024-08-31' },
      { id: 'TK035', type: '精油護理券', value: 800, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 2,
    memberLevel: "VIP會員",
    socialPlatform: "FB, IG",
    socialAccount: "@wang.yl",
    source: "實體店面",
    totalSpent: 65000,
    orderCount: 16,
  },
  {
    key: '19',
    memberId: 'MB019',
    name: '李建宏',
    gender: '男',
    phone: '0990-123-456',
    email: 'li.jh@email.com',
    birthDate: '1991-08-28',
    registerDate: '2024-04-01',
    status: '正常',
    level: '一般會員',
    points: 1800,
    consumeCount: 7,
    lastLoginDate: '2024-03-27',
    address: '新北市三峽區民生街120號',
    tags: ['護膚愛好者'],
    boundBeauticians: [
      { id: 'BT039', name: '吳美華' }
    ],
    serviceOrders: [
      { id: 'SO051', date: '2024-03-27', service: '基礎護理', amount: 1800 },
      { id: 'SO052', date: '2024-03-15', service: '臉部保養', amount: 1500 }
    ],
    productOrders: [
      { id: 'PO039', date: '2024-03-20', product: '保濕精華', amount: 1200 },
      { id: 'PO040', date: '2024-03-10', product: '面膜組合', amount: 900 }
    ],
    tickets: [
      { id: 'TK036', type: '護理券', value: 500, expireDate: '2024-06-30' },
      { id: 'TK037', type: '折價券', value: 300, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 1,
    memberLevel: "一般會員",
    socialPlatform: "FB",
    socialAccount: "@li.jh",
    source: "網路搜尋",
    totalSpent: 16000,
    orderCount: 7,
  },
  {
    key: '20',
    memberId: 'MB020',
    name: '吳雅芳',
    gender: '女',
    phone: '0901-234-567',
    email: 'wu.yf@email.com',
    birthDate: '1989-04-10',
    registerDate: '2024-04-05',
    status: '正常',
    level: '白金會員',
    points: 10500,
    consumeCount: 30,
    lastLoginDate: '2024-03-28',
    address: '台北市內湖區成功路五段90號',
    tags: ['白金會員', '美妝達人'],
    boundBeauticians: [
      { id: 'BT040', name: '張美華' },
      { id: 'BT041', name: '李美玲' },
      { id: 'BT042', name: '王美琪' }
    ],
    serviceOrders: [
      { id: 'SO053', date: '2024-03-28', service: '白金護理', amount: 5000 },
      { id: 'SO054', date: '2024-03-20', service: '美容SPA', amount: 4000 },
      { id: 'SO055', date: '2024-03-15', service: '身體護理', amount: 3500 },
      { id: 'SO056', date: '2024-03-10', service: '臉部護理', amount: 2500 }
    ],
    productOrders: [
      { id: 'PO041', date: '2024-03-25', product: '奢華保養組', amount: 5500 },
      { id: 'PO042', date: '2024-03-18', product: '美白精華', amount: 3000 },
      { id: 'PO043', date: '2024-03-12', product: '抗老面霜', amount: 2500 }
    ],
    tickets: [
      { id: 'TK038', type: '白金護理券', value: 2000, expireDate: '2024-09-30' },
      { id: 'TK039', type: 'SPA券', value: 1500, expireDate: '2024-08-31' },
      { id: 'TK040', type: '生日優惠券', value: 1000, expireDate: '2024-07-31' }
    ],
    beauticiansCount: 3,
    memberLevel: "白金會員",
    socialPlatform: "FB, IG, LINE",
    socialAccount: "@wu.yf_beauty",
    source: "實體店面",
    totalSpent: 175000,
    orderCount: 30,
  }
]; 