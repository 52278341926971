import React, { useState } from 'react'
import { Input, message } from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';
import { updateSample } from './hooks/voice.api';

const { TextArea } = Input;

const VoiceInputArea = ({ roomId, token, sampleId, title, content, keyword, refresh }) => {
  const [editMode, setEditMode] = useState(false)
  const [editedContent, setEditedContent] = useState(content)
  const [loading, setLoading] = useState(false)

  const handleUpdate = async () => {
    setLoading(true)
    await updateSample(roomId, token, sampleId, keyword, editedContent)
    refresh()
    setLoading(false)
    setEditMode(false)
    message.success('已更新文本');
  }

  if (editMode) {
    return (
      <div>
        <div className="flex flex-row items-center justify-between font-bold pb-1">
          {title}
          <CheckOutlined onClick={handleUpdate} className="hover:text-primary" />
        </div>
        <TextArea
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          rows={11}
          disabled={loading}
        />
      </div>
    )
  }

  return (
    <div>
      <div className="flex flex-row items-center justify-between font-bold pb-1">
        {title}
        <EditOutlined onClick={() => setEditMode(true)} className="hover:text-primary" />
      </div>
      <div className="overflow-scroll border rounded p-2 h-64">{content}</div>
    </div>
  )
}

export default VoiceInputArea