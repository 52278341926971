import React, { useState, useEffect } from 'react';
import { Input, message } from 'antd';
import axios from 'axios';

import { useUser } from '@/contexts/UserProvider';
import { useAuth } from '@/contexts/AuthProvider';

const DepMgnt = ({ selectedDepartmentId }) => {
  const { userId, token } = useAuth();
  const [departmentInfo, setDepartmentInfo] = useState();
  const [departmentName, setDepartmentName] = useState();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const fetchDeData = async () => {
    if (!token) return;
    try {
      const response = await axios.get(`${SERVER_URL}/private/company/departments/${selectedDepartmentId}`, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.data;
      setDepartmentInfo(data);
      setDepartmentName(data.name);
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };
  useEffect(() => {
    fetchDeData();
  }, [userId]);

  const handleChangeName = async () => {
    if (!departmentName) {
      message.error('部門名稱不能為空');
      return;
    }
    try {
      await axios.patch(`${SERVER_URL}/private/company/departments/name/${selectedDepartmentId}?department_name=${departmentName}`, {
        department_name: departmentName
      }, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      window.location.reload();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };
  const handleReturn = () => {
    window.location.reload();
  }

  const handleDepartmentNameChange = (e) => {
    setDepartmentName(e.target.value);
  };


  return (
    <div className="flex flex-col w-full bg-white">
      <h3 className='flex p-6 font-semibold text-[17px]'>編輯部門</h3>
      <div className='flex flex-col p-3'>
        <div className='flex p-1 items-center'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            部門編號</p>
          <p>{departmentInfo && departmentInfo.id}</p>
        </div>
        <div className='flex p-1'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            部門名稱</p>
          <Input className='border-gray border-[1px] rounded-[3px] w-[270px]'
            value={departmentName}
            onChange={handleDepartmentNameChange} />
        </div>
      </div>
      <div className='flex p-5'>
        <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center mr-[10px]'
          onClick={handleChangeName}>
          確認</button>
        <button className='text-[14px] bg-[#DEACAC] p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center'
          onClick={handleReturn}>
          返回</button>
      </div>
    </div>
  )
}

export default DepMgnt;