import React, { useState } from 'react'
import { Input, message } from "antd";
import axios from 'axios';

import { useAuth } from '@/contexts/AuthProvider';

const CreateDep = () => {
  const { token } = useAuth();
  const [departmentName, setDepartmentName] = useState(null);

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handleAddDep = async () => {
    if (!departmentName) {
      message.error('部門名稱不能為空');
      return;
    }
    try {
      await axios.post(`${SERVER_URL}/private/company/departments/?department_name=${departmentName}`, null, {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      window.location.reload();
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };
  const handleNameChange = (e) => {
    setDepartmentName(e.target.value)
  };

  const handleReturn = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col w-full bg-white">
      <h3 className='flex p-6 font-semibold text-[17px]'>新增部門</h3>
      <div className='flex flex-col p-3'>
        <div className='flex p-1 items-center'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            部門編號</p>
          <p>自動產生</p>
        </div>
        <div className='flex p-1'>
          <p className='w-[270px] h-[32px] p-1 bg-bgDark mr-[7px] text-[17px] border-b-[1px] border-[#D9D9D9]'>
            部門名稱</p>
          <Input className='border-gray border-[1px] rounded-[5px] w-[270px]'
            value={departmentName}
            onChange={handleNameChange} />
        </div>
      </div>
      <div className='flex p-5 w-1/2'>
        <button className='text-[14px] bg-primaryMedium p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center mr-[10px]'
          onClick={handleAddDep}>
          確認</button>
        <button className='text-[14px] bg-[#DEACAC] p-2 rounded-[3px] w-[58px] h-[32px] flex items-center justify-center'
          onClick={handleReturn}>
          返回</button>
      </div>
    </div>
  )
}

export default CreateDep