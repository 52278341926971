import React from 'react'
import classnames from 'classnames'

import { PhoneOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const OFFSET_HOUR = 8;

const VoiceListItem = ({ item, activeId, setActiveId }) => {

  const formatAndTimeZone = (timestamp) => {
    let timeObj = new Date(timestamp);

    timeObj.setHours(timeObj.getHours() + OFFSET_HOUR); // TODO: why no *2?

    const month = ('0' + (timeObj.getMonth() + 1)).slice(-2);
    const day = ('0' + timeObj.getDate()).slice(-2);
    const hour = ('0' + timeObj.getHours()).slice(-2);
    const minute = ('0' + timeObj.getMinutes()).slice(-2);

    return `${month}/${day} ${hour}:${minute}`;
  };

  if (item.state === "Done" || item.state === "Unknown") {
    return (
      <Tooltip title={item.customer_question.length > 12 && item.customer_question}>
        <div
          className={
            classnames(
              "flex flex-row gap-2 w-full h-full cursor-pointer px-2 py-2 items-center rounded bg-bgLight",
              { "hover:bg-bgDark": activeId !== item.id },
              { "bg-primaryMedium hover:bg-primaryMedium": activeId === item.id })}
          onClick={() => setActiveId(item.id)}
        >
          <div className="flex flex-col w-full h-full gap-1 justify-start line-clamp-1 truncate">
            {item.customer_question}
            <div className="text-xs text-textDark line-clamp-1">
              <PhoneOutlined className="mr-1" />
              {item.sample_duration.toFixed(0)} 秒
            </div>
          </div>
          <div className="flex flex-col h-full items-end gap-1 justify-start w-[100px]">
            <div className="text-xs text-textLight">
              {formatAndTimeZone(item.created_at)}
            </div>
            {
              item.state === "Done" && <div className="text-xs text-white bg-primary font-bold p-1 rounded">分析完成</div>
            }
            {
              item.state === "Unknown" && <div className="text-xs text-white bg-textLight font-bold p-1 rounded">人工檢視</div>
            }
          </div>
        </div>
      </Tooltip>
    )
  }

  return (
    <div
      className={
        classnames(
          "flex flex-row gap-2 w-full h-full cursor-pointer px-2 py-2 items-center rounded bg-bgLight",
          { "hover:bg-bgDark": activeId !== item.id },
          { "bg-primaryMedium hover:bg-primaryMedium": activeId === item.id })}
      onClick={() => setActiveId(item.id)}
    >
      <div className="flex flex-col w-full h-full gap-1 justify-start line-clamp-1 truncate">
        {item.sample_filename}
        <div className="text-xs text-textDark line-clamp-1">
          <PhoneOutlined className="mr-1" />
        </div>
      </div>
      <div className="flex flex-col h-full items-end gap-1 justify-start w-[100px]">
        <div className="text-xs text-textLight">
          {formatAndTimeZone(item.created_at)}
        </div>
        <div className="text-xs text-white bg-dark font-bold p-1 rounded">分析中</div>
      </div>
    </div>
  )
}

export default VoiceListItem