import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Avatar, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';

import { useUser } from '@/contexts/UserProvider';
import { useAuth } from '@/contexts/AuthProvider';

import UserMenu from './components/UserMenu';
import UserSetting from './components/UserSetting';

const UserAvatar = ({ isSystemPage }) => {
  const { user, isLoading, error } = useUser();
  const [popMenu, setPopMenu] = useState(false);
  const [popPersonalSetting, setPopPersonalSetting] = useState(false);
  const [userPhoto, setUserPhoto] = useState(null);
  const { token } = useAuth();

  const handleMenuPop = () => {
    setPopMenu(!popMenu);
  };
  const handlePersonalSettingPop = () => {
    setPopPersonalSetting(!popPersonalSetting);
  };

  useEffect(() => {
    setUserPhoto(null);
    if (user && user.photo_url) {
      setUserPhoto(user.photo_url);
    } else {
      setUserPhoto(null);
    }
  }, [user]);

  if (isLoading) {
    return <div className='flex items-center justify-center w-full'>
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 48,
            }}
            spin
          />
        }
      />
    </div>
  }
  if (error) {
    return <div>Error: {error}</div>;
  }


  return (
    <>
      <button className='flex justify-center items-center w-[40px] h-[40px] border-none rounded-[50%] overflow-hidden relative'
        onClick={handleMenuPop}>
        <Avatar size={40} icon={<UserOutlined />} src={userPhoto ? userPhoto : undefined}
        />
      </button>
      {popMenu && (
        <div className='fixed top-[60px] right-[10px] z-20 '>
          <UserMenu
            isSystemPage={isSystemPage}
            handlePersonalSettingPop={handlePersonalSettingPop}
          />
        </div>
      )}
      {popPersonalSetting &&
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-20'>
          <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30'>
            <UserSetting handlePersonalSettingPop={handlePersonalSettingPop} />
          </div>
        </div>
      }
    </>
  );
};
export default UserAvatar;