import React, { useState } from 'react';
import { Button } from 'antd';
import { createProfile } from './hooks/voice.api';
import TSTags from './TSTags';

const VoiceInit = ({ roomId, token, refresh }) => {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCreateProfile = async () => {
    setLoading(true);
    await createProfile(roomId, token, tags);
    refresh();
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-start w-full h-22 p-4 gap-2 border rounded border-borderLight">
      <div className="font-bold">常用詞彙<span className="text-xs text-textLight font-normal ml-2">幫助 AI 分析文本，建立後即不可編輯</span></div>
      <TSTags tags={tags} setTags={setTags} maxLength={20} addNewWord="新增詞彙" />
      <Button type="primary" onClick={handleCreateProfile} disabled={tags.length === 0} loading={loading} className="mt-2">建立</Button>
    </div>
  );
};

export default VoiceInit;