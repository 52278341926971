export const initialData = [
  {
    key: "1",
    provider: "美顏工作室",
    serviceId: "59217",
    status: "上架中",
    name: "抗衰護理療程 [75分鐘]",
    price: 1800,
    order: "0",
    remaining: "14",
    description: "描述",
    reservation: "預約方式",
    tags: ["緊緻", "美白"],
    publishDate: "2022-01-01",
    memberDiscount: "10%",
    imageUrls: [
      "/images/service1.png",
      "/images/service1.png",
      "/images/service1.png",
      "/images/service1.png",
      "/images/service1.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "60分鐘",
        backendDuration: "90分鐘",
        price: "1800",
        selected: true,
        discount: false,
        discountPrice: "1800",
        earlyBird: false,
        earlyBirdPrice: "1800",
      },
      {
        key: "2",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "2200",
        selected: false,
        discount: false,
        discountPrice: "2200",
        earlyBird: false,
        earlyBirdPrice: "2200",
      },
    ],
  },
  {
    key: "2",
    provider: "沙龍美學",
    serviceId: "59218",
    status: "下架中",
    name: "深層潔淨面部護理 [60分鐘]",
    price: 1200,
    order: "0",
    remaining: "20",
    description: "深層清潔，去除黑頭",
    reservation: "線上預約",
    tags: ["面部護理", "清潔"],
    publishDate: "2022-02-15",
    memberDiscount: "12%",
    imageUrls: [
      "/images/service2.png",
      "/images/service2.png",
      "/images/service2.png",
      "/images/service2.png",
      "/images/service2.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "60分鐘",
        backendDuration: "90分鐘",
        price: "1200",
        selected: true,
        discount: false,
        discountPrice: "1200",
        earlyBird: false,
        earlyBirdPrice: "1200",
      },
      {
        key: "2",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "1800",
        selected: false,
        discount: false,
        discountPrice: "1800",
        earlyBird: false,
        earlyBirdPrice: "1800",
      },
    ],
  },
  {
    key: "3",
    provider: "東方養生館",
    serviceId: "59219",
    status: "上架中",
    name: "全身按摩 [90分鐘]",
    price: 2200,
    order: "0",
    remaining: "25",
    description: "舒緩壓力，放鬆身心",
    reservation: "電話預約",
    tags: ["按摩", "舒壓"],
    publishDate: "2022-03-10",
    memberDiscount: "16%",
    imageUrls: [
      "/images/service3.png",
      "/images/service3.png",
      "/images/service3.png",
      "/images/service3.png",
      "/images/service3.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "2200",
        selected: true,
        discount: false,
        discountPrice: "2200",
        earlyBird: false,
        earlyBirdPrice: "2200",
      },
      {
        key: "2",
        actualDuration: "120分鐘",
        backendDuration: "150分鐘",
        price: "2800",
        selected: false,
        discount: false,
        discountPrice: "2800",
        earlyBird: false,
        earlyBirdPrice: "2800",
      },
    ],
  },
  {
    key: "4",
    provider: "時尚美學中心",
    serviceId: "59220",
    status: "上架中",
    name: "精緻美甲 [120分鐘]",
    price: 1500,
    order: "0",
    remaining: "15",
    description: "專業美甲服務",
    reservation: "線上預約",
    tags: ["美甲", "藝術"],
    publishDate: "2022-04-05",
    memberDiscount: "18%",
    imageUrls: [
      "/images/service4.png",
      "/images/service4.png",
      "/images/service4.png",
      "/images/service4.png",
      "/images/service4.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "120分鐘",
        backendDuration: "150分鐘",
        price: "1500",
        selected: true,
        discount: false,
        discountPrice: "1500",
        earlyBird: false,
        earlyBirdPrice: "1500",
      },
      {
        key: "2",
        actualDuration: "150分鐘",
        backendDuration: "180分鐘",
        price: "1800",
        selected: false,
        discount: false,
        discountPrice: "1800",
        earlyBird: false,
        earlyBirdPrice: "1800",
      },
    ],
  },
  {
    key: "5",
    provider: "尊爵 SPA",
    serviceId: "59221",
    status: "下架中",
    name: "髮型設計 [90鐘]",
    price: 2000,
    order: "0",
    remaining: "20",
    description: "專業髮型",
    reservation: "電話預",
    tags: ["髮型", "造型"],
    publishDate: "2022-05-20",
    memberDiscount: "14%",
    imageUrls: [
      "/images/service5.png",
      "/images/service5.png",
      "/images/service5.png",
      "/images/service5.png",
      "/images/service5.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "2000",
        selected: true,
        discount: false,
        discountPrice: "2000",
        earlyBird: false,
        earlyBirdPrice: "2000",
      },
      {
        key: "2",
        actualDuration: "120分鐘",
        backendDuration: "150分鐘",
        price: "2500",
        selected: false,
        discount: false,
        discountPrice: "2500",
        earlyBird: false,
        earlyBirdPrice: "2500",
      },
    ],
  },
  {
    key: "6",
    provider: "悠活養生會館",
    serviceId: "59222",
    status: "上架中",
    name: "芳療精油按摩 [60分鐘]",
    price: 1600,
    order: "0",
    remaining: "18",
    description: "天然精油芳療",
    reservation: "線上預約",
    tags: ["芳療", "紓壓"],
    publishDate: "2022-06-15",
    memberDiscount: "15%",
    imageUrls: [
      "/images/service6.png",
      "/images/service6.png",
      "/images/service6.png",
      "/images/service6.png",
      "/images/service6.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "60分鐘",
        backendDuration: "90分鐘",
        price: "1600",
        selected: true,
        discount: false,
        discountPrice: "1600",
        earlyBird: false,
        earlyBirdPrice: "1600",
      },
      {
        key: "2",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "2100",
        selected: false,
        discount: false,
        discountPrice: "2100",
        earlyBird: false,
        earlyBirdPrice: "2100",
      },
    ],
  },
  {
    key: "7",
    provider: "晶鑽美學",
    serviceId: "59223",
    status: "上架中",
    name: "微晶換膚 [45分鐘]",
    price: 2400,
    order: "0",
    remaining: "12",
    description: "專業換膚護理",
    reservation: "電話預約",
    tags: ["換膚", "保養"],
    publishDate: "2022-07-01",
    memberDiscount: "20%",
    imageUrls: [
      "/images/service7.png",
      "/images/service7.png",
      "/images/service7.png",
      "/images/service7.png",
      "/images/service7.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "45分鐘",
        backendDuration: "60分鐘",
        price: "2400",
        selected: true,
        discount: false,
        discountPrice: "2400",
        earlyBird: false,
        earlyBirdPrice: "2400",
      },
      {
        key: "2",
        actualDuration: "60分鐘",
        backendDuration: "90分鐘",
        price: "3200",
        selected: false,
        discount: false,
        discountPrice: "3200",
        earlyBird: false,
        earlyBirdPrice: "3200",
      },
    ],
  },
  {
    key: "8",
    provider: "禪風美容院",
    serviceId: "59224",
    status: "下架中",
    name: "中式經絡按摩 [90分鐘]",
    price: 2600,
    order: "0",
    remaining: "22",
    description: "傳統經絡調理",
    reservation: "線上預約",
    tags: ["經絡", "養生"],
    publishDate: "2022-08-10",
    memberDiscount: "12%",
    imageUrls: [
      "/images/service8.png",
      "/images/service8.png",
      "/images/service8.png",
      "/images/service8.png",
      "/images/service8.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "2600",
        selected: true,
        discount: false,
        discountPrice: "2600",
        earlyBird: false,
        earlyBirdPrice: "2600",
      },
      {
        key: "2",
        actualDuration: "120分鐘",
        backendDuration: "150分鐘",
        price: "3400",
        selected: false,
        discount: false,
        discountPrice: "3400",
        earlyBird: false,
        earlyBirdPrice: "3400",
      },
    ],
  },
  {
    key: "9",
    provider: "光采美學",
    serviceId: "59225",
    status: "上架中",
    name: "光療美甲 [90分鐘]",
    price: 1400,
    order: "0",
    remaining: "16",
    description: "持久光療護理",
    reservation: "線上預約",
    tags: ["美甲", "光療"],
    publishDate: "2022-09-05",
    memberDiscount: "15%",
    imageUrls: [
      "/images/service9.png",
      "/images/service9.png",
      "/images/service9.png",
      "/images/service9.png",
      "/images/service9.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "1400",
        selected: true,
        discount: false,
        discountPrice: "1400",
        earlyBird: false,
        earlyBirdPrice: "1400",
      },
      {
        key: "2",
        actualDuration: "120分鐘",
        backendDuration: "150分鐘",
        price: "1900",
        selected: false,
        discount: false,
        discountPrice: "1900",
        earlyBird: false,
        earlyBirdPrice: "1900",
      },
    ],
  },
  {
    key: "10",
    provider: "純淨美容中心",
    serviceId: "59226",
    status: "上架中",
    name: "深層保濕護理 [60分鐘]",
    price: 1700,
    order: "0",
    remaining: "19",
    description: "深層補水保養",
    reservation: "電話預約",
    tags: ["保濕", "護理"],
    publishDate: "2022-10-15",
    memberDiscount: "18%",
    imageUrls: [
      "/images/service10.png",
      "/images/service10.png",
      "/images/service10.png",
      "/images/service10.png",
      "/images/service10.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "60分鐘",
        backendDuration: "90分鐘",
        price: "1700",
        selected: true,
        discount: false,
        discountPrice: "1700",
        earlyBird: false,
        earlyBirdPrice: "1700",
      },
      {
        key: "2",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "2300",
        selected: false,
        discount: false,
        discountPrice: "2300",
        earlyBird: false,
        earlyBirdPrice: "2300",
      },
    ],
  },{
    key: "11",
    provider: "純淨美容中心",
    serviceId: "59226",
    status: "上架中",
    name: "深層保濕護理 [60分鐘]",
    price: 1700,
    order: "0",
    remaining: "19",
    description: "深層補水保養",
    reservation: "電話預約",
    tags: ["保濕", "護理"],
    publishDate: "2022-10-15",
    memberDiscount: "18%",
    imageUrls: [
      "/images/service11.png",
      "/images/service11.png",
      "/images/service11.png",
      "/images/service11.png",
      "/images/service11.png"
    ],
    pricingMode: "tiered",
    pricingTable: [
      {
        key: "1",
        actualDuration: "60分鐘",
        backendDuration: "90分鐘",
        price: "1700",
        selected: true,
        discount: false,
        discountPrice: "1700",
        earlyBird: false,
        earlyBirdPrice: "1700",
      },
      {
        key: "2",
        actualDuration: "90分鐘",
        backendDuration: "120分鐘",
        price: "2300",
        selected: false,
        discount: false,
        discountPrice: "2300",
        earlyBird: false,
        earlyBirdPrice: "2300",
      },
    ],
  },
];

   