import React, { useState } from 'react'
import classNames from 'classnames';
import { Button, Tooltip, message } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'

import { useAuth } from '@/contexts/AuthProvider'

import { ApiOutlined, ReloadOutlined } from '@ant-design/icons'
import { ReactComponent as LeaveIcon } from '../assets/leave.svg'
import { ReactComponent as SettingsIcon } from '../assets/settings.svg'
import { ReactComponent as LayersIcon } from '../assets/layers.svg'
import { ReactComponent as TerminalIcon } from '../assets/terminal.svg'
import { ReactComponent as LineIcon } from '../assets/line.svg'
import { ReactComponent as ExploreIcon } from '@/components/navBar/assets/explore_tab_icon.svg';
import { ReactComponent as ChatIcon } from '@/components/navBar/assets/chat_tab_icon.svg';
import { ReactComponent as MultiPostIcon } from '@/components/navBar/assets/multipost_tab_icon.svg';
import { ReactComponent as VoiceIcon } from '@/components/navBar/assets/voice_tab_icon.svg';

import UserAvatar from '../../settings/UserAvatar';
import ChatSettings from './chatSettings/ChatSettings';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const ToolBar = ({ pure = false, room, tab, setTab, refresh, binding, }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [openModal, setOpenModal] = useState(false)
  const { userId, token } = useAuth()

  const closeModal = () => {
    setOpenModal(false)
  }

  const leaveRoom = async () => {
    try {
      await axios.post(`${SERVER_URL}/private/chatrooms/leave/${room.id}`, {
        room_id: room.id
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      navigate('/chat')
      window.location.reload()
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  }

  if (pure) {
    return (
      <div className="flex flex-row w-full justify-end items-center min-h-14 border-b-borderLight px-6 border-b">
        <UserAvatar />
      </div>
    )
  }

  return (
    <>
      <ChatSettings open={openModal} closeModal={closeModal} room={room} />
      <div className="flex flex-row w-full justify-between items-center min-h-14 border-b-borderLight px-6 border-b">
        <div className="flex flex-row gap-4 items-center justify-start">
          <div className="text-textDark font-bold text-lg mr-6 max-w-96 truncate">
            {room.name}
            <ReloadOutlined
              className="hover:text-primary ml-2"
              style={{ width: "12px", height: "12px" }}
              onClick={refresh}
            />
          </div>
          {/* <div className="flex flex-row gap-1 items-center">
            <a href={"/chat/" + room.id} className={classNames({
              'border-primary': location.pathname.includes("/chat"),
              'flex w-6 h-6 items-center justify-center cursor-pointer border-[1px] border-borderDark hover:border-primaryMedium rounded-full': true,
            })}>
              <ChatIcon style={{ width: "15px", height: "15px" }} />
            </a>
            聊天模式
          </div> */}
        </div>
        <div className="flex flex-row gap-4 items-center justify-end">
          <Tooltip title="內部聊天室" onClick={() => setTab("chatroom")}>
            <Button className="border-0 bg-white rounded-full shadow-none min-w-9 min-h-9" icon={<ChatIcon style={{ width: "17px", height: "15px" }} />} />
          </Tooltip>
          {/* 關閉 */}
          {/* <Tooltip title={binding ? "第三方紀錄" : "尚未啟用"} onClick={() => binding && setTab("boundRoom")} > */}
          <Tooltip title="第三方紀錄" onClick={() => setTab("boundRoom")} >
            <Button className="border-0 bg-white rounded-full shadow-none min-w-9 min-h-9" icon={<ApiOutlined />} />
          </Tooltip>
          <Tooltip title="語音模組" onClick={() => setTab("asr")} >
            <Button className="border-0 bg-white rounded-full shadow-none min-w-9 min-h-9" icon={<VoiceIcon style={{ width: "18px", height: "18px" }} />} />
          </Tooltip>
          <Tooltip title="記憶模式" onClick={() => setTab("memory")} >
            <Button className="border-0 bg-white rounded-full shadow-none min-w-9 min-h-9" icon={<TerminalIcon style={{ width: "36px", height: "36px" }} />} />
          </Tooltip>
          {
            room.num_of_members > 1 &&
            <Tooltip title="退出聊天室">
              <Button className="border-0 bg-white rounded-full shadow-none min-w-9 min-h-9" icon={<LeaveIcon style={{ width: "36px", height: "36px" }} onClick={leaveRoom} />} />
            </Tooltip>
          }
          {
            userId === room.creator_id &&
            <Tooltip title="聊天室設定">
              <Button className="border-0 bg-white rounded-full shadow-none min-w-9 min-h-9" icon={<SettingsIcon style={{ width: "36px", height: "36px" }} />} onClick={() => setOpenModal(true)} />
            </Tooltip>
          }
          <UserAvatar />
        </div>
      </div>
    </>
  )
}

export default ToolBar