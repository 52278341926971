import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Spin } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';

import { useUser } from '@/contexts/UserProvider';
import { useAuth } from '@/contexts/AuthProvider';

import { ReactComponent as LogoutIcon } from '../assets/logout_icon.svg';
import { ReactComponent as Setting } from '../assets/setting_icon.svg';
import { ReactComponent as SysMgnt } from '../assets/sysMgnt_icon.svg';
import { ReactComponent as BackToChat } from '../assets/backToChat_icon.svg';

const UserMenu = ({ isSystemPage, handlePersonalSettingPop }) => {
    const { user, isLoading, error } = useUser();
    const { token, logout } = useAuth();
    const [photoUrl, setPhotoUrl] = useState(null);
    const navigate = useNavigate();


    const handleLogout = () => {
        logout()
        navigate('/login')
    };
    const redirectSysSetting = () => {
        navigate('/settings'); // redirect Settings page
    };
    const redirectExplore = () => {
        navigate('/explore'); // redirect Settings page
    };

    useEffect(() => {
        if (user && user.photo_url) {
            setPhotoUrl(user.photo_url)
        }
    }, [user]);

    if (isLoading) {
        return <div className='flex items-center justify-center w-full'>
            <Spin
                indicator={
                    <LoadingOutlined
                        style={{
                            fontSize: 48,
                        }}
                        spin
                    />
                }
            />
        </div>
    }
    if (error) {
        return <div>Error: {error}</div>;
    };

    return (
        <div className='bg-bgLight flex flex-col justify-around items-center w-[320px] h-[300px] border-gray border-[1px] rounded-[7px]'>
            <div className='flex w-[100%] h-[60%] justify-around items-center p-[10px] border-b-[1px] border-gray'>
                <Avatar size={56} icon={<UserOutlined />} src={photoUrl ? photoUrl : undefined} />
                <div className='flex flex-col'>
                    <p className='font-semibold'>{user?.nickname || 'error'}</p>
                    <p>{user?.email || 'error'}</p>
                </div>
            </div>
            <button className='flex w-[100%] h-[20%] items-center p-[20px] border-b-[1px] border-gray hover:bg-bgMedium'
                onClick={handlePersonalSettingPop}>
                <Setting className='mr-2' />
                <p>帳號設定</p>
            </button>
            {isSystemPage ?
                <button className='flex w-[100%] h-[20%] items-center p-[20px] border-b-[1px] border-gray hover:bg-bgMedium'
                    onClick={redirectExplore}>
                    <BackToChat className='mr-2' />
                    <p>返回聊天室</p>
                </button>
                :
                <button className='flex w-[100%] h-[20%] items-center p-[20px] border-b-[1px] border-gray hover:bg-bgMedium'
                    onClick={redirectSysSetting}>
                    <SysMgnt className='mr-2' />
                    <p>系統管理</p>
                </button>
            }
            <button className='flex w-[100%] h-[20%] items-center p-[20px] hover:bg-bgMedium'
                onClick={handleLogout}>
                <LogoutIcon className='mr-2' />
                <p>登出</p>
            </button>
        </div>
    )
}

export default UserMenu

