import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ReactComponent as AddDep } from '../assets/addDep_icon.svg';
import { ReactComponent as AddAcc } from '../assets/addAcc_icon.svg';

const AddNewAcc = ({ onClose, changeTab }) => {

  const handleAddDepClick = () => {
    changeTab(6);
    onClose();
  };


  return (
    <div className='w-full h-full flex flex-col justify-start rounded-[9px] bg-[#F7F7F7] z-20'>
      <div className='flex justify-end w-full h-1/4 items-start'>
        <Button icon={<CloseOutlined />} type="text" shape="circle" onClick={onClose} />
      </div>
      <div className='flex w-full justify-around'>
        <button className='group w-[160px] h-[160px] flex flex-col items-center justify-between rounded-[7px] overflow-hidden border-gray border-[1px]'>
          <div className='w-full h-full flex justify-center items-center group-hover:bg-gray-100'>
            <AddDep onClick={handleAddDepClick} />
          </div>
          <p className='bg-[#ADDEAC] group-hover:bg-[#67BE5F] w-full py-1'>新增部門</p>
        </button>
        <button className='group w-[160px] h-[160px] flex flex-col items-center justify-between rounded-[7px] overflow-hidden border-gray border-[1px]'>
          <div className='w-full h-full flex justify-center items-center group-hover:bg-gray-100'>
            <AddAcc onClick={() => changeTab(0)} />
            {/* <AddAcc className='hover:text-gray-700' /> */}
          </div>
          <p className='bg-[#ADDEAC] group-hover:bg-[#67BE5F] w-full py-1'>新增帳號</p>
        </button>
      </div>
    </div>
  )
}

export default AddNewAcc