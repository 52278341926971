import React, { useEffect, useState } from "react";
import { message, Button, Input, Select } from "antd";
import StepOneComponent from "./components/StepOneComponent";
import StepTwoComponent from "./components/StepTwoComponent";
import { useIntegration } from "@/contexts/IntegrationContext";
import { useServer } from "@/contexts/ServerContext";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Tooltip, Switch } from "antd";

const RuleSetting = ({ room }) => {
  const [thirdPartyIntegrations, setThirdPartyIntegrations] = useState("");
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isResetBtnDisable, setIsResetBtnDisable] = useState(false);
  const [step, setStep] = useState(1); // Step state, changes between 1 and 2 for different views
  const [email, setEmail] = useState(""); // Stores email input value
  const [password, setPassword] = useState(""); // Stores password input value
  const [editingIndex, setEditingIndex] = useState(null); // Tracks which integration is being edited
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks whether a submission is in progress
  const [loading, setLoading] = useState(false)
  const [isAIEnabled, setIsAIEnabled] = useState(false);
  const [updateInterval, setUpdateInterval] = useState("5");
  const [isEditingInterval, setIsEditingInterval] = useState(false);

  const { roomIntegrations, addIntegration, updateIntegration, getIntegration, fetchIntegrations } = useIntegration();
  const integrations = roomIntegrations[room.id] || []; // Retrieves integrations related to the specific room
  const { roomServers } = useServer();
  const servers = roomServers[room.id] || []; // Retrieves servers related to the specific room

  // List of available integration platforms with their icons
  const integrationsInfos = [
    // { name: "Facebook", icon: "/images/facebook-icon.png" },
    // { name: "Threads", icon: "/images/threads-icon.png" },
    // { name: "TikTok", icon: "/images/tiktok.png" },
    // { name: "Booking", icon: "/images/booking-icon.png" },
    { name: "Agoda", icon: "/images/agoda-icon.png" },
    { name: "AirBnB", icon: "/images/airbnb.png" },
    { name: "Google", icon: "/images/google-icon.png" },
    // { name: "Hotel.com", icon: "/images/hotel-icon.png" },
    // { name: "Expedia", icon: "/images/expedia-icon.png" },
    // { name: "Trip.com", icon: "/images/trip-icon.png" },
    // { name: "trivago", icon: "/images/trivago-icon.png" },
  ];

  const handleOpenIntegration = () => {
    setIsButtonVisible(false); // Hides the button to open third-party integration options
    setIsResetBtnDisable(true); // Disables the reset button when a new integration is being added
  };

  const handleNextStep = () => {
    if (!thirdPartyIntegrations) {
      message.error("請選擇一個平台");
      return;
    }

    // Check if the selected platform already exists in the integrations list
    const isPlatformExist = integrations.some(
      (integration) => integration.platform === thirdPartyIntegrations
    );

    if (isPlatformExist) {
      message.error("該平台已經串接，請選擇其他平台");
      return;
    }

    // Resets email and password fields and moves to the next step (step 2)
    setEmail("");
    setPassword("");
    setStep(2);
  };

  const handleCancel = () => {
    // Resets state to go back to the first step and show the initial button
    setStep(1);
    setIsButtonVisible(true);
    setIsResetBtnDisable(false);
    setThirdPartyIntegrations("");
    setEmail("");
    setPassword("");
  };

  const handleFinish = async () => {
    if (!email || !password) {
      message.error("請輸入電子郵件或密碼");
      return;
    }



    setIsSubmitting(true); // Indicates that a form submission is in progress

    // Finds the selected integration's icon based on the platform name
    const selectedIntegration = integrationsInfos.find(
      (integration) => integration.name === thirdPartyIntegrations
    );

    // Calls addIntegration function to add the new third-party integration
    const success = await addIntegration(room.id, {
      platform: thirdPartyIntegrations,
      icon: selectedIntegration ? selectedIntegration.icon : null,
      email: email,
      password: password
    });

    if (success) {
      // Resets the form and updates UI after successful integration
      setThirdPartyIntegrations("");
      setEmail("");
      setPassword("");
      message.success("第三方平台串接成功！");
      setIsResetBtnDisable(false);
      setIsButtonVisible(true);
      setStep(1);
    } else {
      message.error("第三方平台串接失敗，請稍後再試。");
    }

    setIsSubmitting(false); // Ends the submission state
  };

  const handleReset = (index) => {
    // Fetches the integration information based on the index and allows editing
    const integration = getIntegration(room.id, index);
    setEditingIndex(index);
    setEmail(integration.email || "");
    setPassword(integration.password || "");
  };

  const handleSave = (index) => {
    console.log("handleSave的結果:", { email, password, index }); // 打印 handleSave 的結果

    // Updates the integration with the new email and password values
    updateIntegration(room.id, index, { ...integrations[index], email, password });
    setEditingIndex(null); // Exits editing mode
  };

  const handleCancelEdit = () => {
    // Cancels the editing mode and resets the form
    setEditingIndex(null);
  };

  const handleIntervalChange = (e) => {
    const value = e.target.value;
    // 只允許輸入1-1000的數字
    if (/^\d*$/.test(value) && value !== "") {
      const numValue = parseInt(value);
      if (numValue >= 1 && numValue <= 1000) {
        setUpdateInterval(value);
      }
    }
  };

  const handleSaveInterval = () => {
    setIsEditingInterval(false);
    // 這裡可以添加保存到後端的邏輯
    message.success("更新間隔已保存");
  };

  // Fetch integrations for the specific room when the room ID changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchIntegrations(room.id);
      setLoading(false);
    };
    fetchData();
  }, [room.id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  return (
    <>
      {servers.length > 0 ? ( // Check if any server is available for the room
        <div className="flex flex-col justify-start items-start w-full py-6 gap-4 max-h-[100vh] overflow-y-auto">
          <div className="flex justify-between items-center font-semibold text-[17px] text-textDark font-bold w-[85%]">
            <span className="flex items-center">串接功能</span>
            <div className="flex flex-row items-center">
              <Tooltip title={isAIEnabled ? "關閉 AI 回覆" : "啟動 AI 回覆"}>
                <Switch
                  className="ml-4"
                  checked={isAIEnabled}
                  onChange={(checked) => setIsAIEnabled(checked)}
                />
              </Tooltip>
              <span className="font-inter text-[17px] font-semibold leading-[22.1px] text-[#3E3D3D] ml-1">自動更新</span>
            </div>
            <div className="flex items-center">
              <div 
                className="relative"
                onClick={() => !isEditingInterval && setIsEditingInterval(true)}
              >
                <Tooltip title="分鐘">
                  <Input
                    placeholder="更新"
                    className="w-16"
                    value={updateInterval}
                    onChange={handleIntervalChange}
                    readOnly={!isEditingInterval}
                    style={{
                      backgroundColor: isEditingInterval ? 'white' : '#E0E0E0',
                      cursor: isEditingInterval ? 'text' : 'pointer'
                    }}
                  />
                </Tooltip>
              </div>
              <Tooltip title="保存">
                <div 
                  className='cursor-pointer flex items-center hover:bg-gray-100 p-1 rounded active:bg-gray-200 ml-1'
                  onClick={handleSaveInterval}
                >
                  <img src='/images/reload-icon.png' alt='reload-icon' />
                </div>
              </Tooltip>
              <span className="font-inter text-[17px] font-semibold leading-[22.1px] text-[#3E3D3D]">更新間隔</span>
            </div>
          </div>

          {/* Display existing integrations */}
          {integrations.map((integration, index) => (
            <React.Fragment key={integration.platform || index}>
              <div className="w-full bg-[#F0F0F0] border border-[#E0E0E0] rounded-[4px] p-4 flex flex-col gap-2">
                <div className="flex justify-between text-[16px] font-normal">
                  串接平台
                </div>

                <div className="flex justify-between items-center">
                  <div className="flex items-center space-x-4">
                    {/* Display integration details */}
                    {editingIndex === index ? '' : (
                      <>
                        <div className="flex items-center space-x-4">
                          <img src={integration.icon} alt={integration.platform} className="w-[24px] h-[24px]" />
                          <span>{integration.platform}</span>
                          {integration.platform === "Agoda" && (
                            <img src="/images/google-sign-icon.png" alt="Google Icon" className="h-[30px]" />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {editingIndex != null ? '' : (
                    <Button
                      className="text-[#3E3D3D] text-[16px] leading-[1.3]"
                      style={
                        isResetBtnDisable || isSubmitting
                          ? { backgroundColor: "#B8B8B7" }
                          : { backgroundColor: "#ADDEAC" }
                      }
                      onClick={() => handleReset(index)} // Trigger reset for this integration
                      disabled={isResetBtnDisable || isSubmitting}
                    >
                      重新設定
                    </Button>
                  )}
                </div>
                <hr className="w-full border-[#E0E0E0] mt-2" />
                <div className="w-full items-center text-black text-[16px]">
                  {/* Conditional rendering for editing or display mode */}
                  {editingIndex === index ? (
                    <>
                      <div className="block mb-2">電子郵件</div>
                      <Input
                        placeholder="電子郵件"
                        className="w-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isSubmitting}
                      />
                      <div className="block mb-2 mt-2">密碼</div>
                      <Input.Password
                        placeholder="密碼"
                        className="w-full"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={isSubmitting}
                      />
                    </>
                  ) : (
                    <>
                      <div className="space-x-3">
                        <span>電子郵件:</span><span>{integration.data.email}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* Display buttons for editing */}
              {editingIndex === index && (
                <div className="flex justify-end gap-4 w-full">
                  <Button
                    onClick={handleCancelEdit}
                    className="text-black-900 text-[16px] leading-[1.3]"
                    disabled={isSubmitting}
                  >
                    取消
                  </Button>
                  <Button
                    onClick={() => handleSave(index)}
                    className="text-black-900 text-[16px] leading-[1.3]"
                    style={{ backgroundColor: "#ADDEAC" }}
                    disabled={isSubmitting}
                  >
                    完成
                  </Button>
                </div>
              )}
            </React.Fragment>
          ))}

          <div className="w-full flex justify-end">
            {/* Conditional rendering based on current state */}
            {isButtonVisible && editingIndex === null && (
              <button
                className="font-[400] text-[16px] bg-primaryMedium p-2 rounded-[3px] h-[31px] flex items-center justify-center"
                onClick={handleOpenIntegration}
                disabled={isSubmitting}
              >
                {integrations.length > 0
                  ? "串接其他第三方平台" // If there are existing integrations
                  : "開啟第三方串接功能"}
              </button>
            )}

            {!isButtonVisible && editingIndex === null && (
              <>
                {/* Step-based conditional rendering */}
                {step === 1 && (
                  <StepOneComponent
                    integrationsInfos={integrationsInfos}
                    thirdPartyIntegrations={thirdPartyIntegrations}
                    setThirdPartyIntegrations={setThirdPartyIntegrations}
                    handleCancel={handleCancel}
                    handleNextStep={handleNextStep}
                    isSubmitting={isSubmitting}
                  />
                )}
                {step === 2 && (
                  <StepTwoComponent
                    thirdPartyIntegrations={thirdPartyIntegrations}
                    integrationsInfos={integrationsInfos}
                    email={email}
                    password={password}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    handleCancel={handleCancel}
                    handleFinish={handleFinish}
                    isSubmitting={isSubmitting}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-start items-start w-full py-6 gap-4 max-h-[100vh] overflow-y-auto">
          <div className="font-semibold text-[17px] text-textDark font-bold w-full">
            串接功能
          </div>
          <h1>請先設定伺服器</h1>
        </div>
      )}
    </>
  );
};

export default RuleSetting;
