import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './AuthProvider';

const IntegrationContext = createContext();

export const IntegrationProvider = ({ children }) => {
  const { token } = useAuth();
  const [roomIntegrations, setRoomIntegrations] = useState({});

  // fetch function to sync with backend
  const fetchIntegrations = async (roomId) => {
    try {
      const urls = [
        `https://api.scfg.io/private/module/rpa/airbnb/account_info/${roomId}`,
        `https://api.scfg.io/private/module/rpa/agoda/account_info/${roomId}`,
        `https://api.scfg.io/private/module/rpa/google/account_info/${roomId}`
      ];

      const responses = await Promise.all(urls.map(url => 
        axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          }
        })
      ));

      const integrations = responses.map((response, index) => {
        if (response.status === 200) {
          return {
            platform: ['AirBnB', 'Agoda', 'Google'][index], // 添加平台名称
            icon: [`/images/airbnb.png`, `/images/agoda-icon.png`, `/images/google-icon.png`][index], // 添加对应图标
            data: response.data.body // 原始数据
          };
        }
        return null; // 如果请求失败，返回 null
      }).filter(Boolean); // 过滤掉 null 值

      setRoomIntegrations(prevState => ({
        ...prevState,
        [roomId]: integrations // 将所有整合数据合并
      }));
    } catch (error) {
      console.error('Failed to fetch Integrations:', error);
    }
  };

  // add
  const addIntegration = async (roomId, integration) => {
    try {
      const { platform, email, password } = integration;

      // 使用變數構建 API URL
      const baseUrl = `https://api.scfg.io/private/module/rpa/${platform.toLowerCase()}/account_info/${roomId}`;
      const url = `${baseUrl}?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`;

      const response = await axios.post(url, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        setRoomIntegrations(prevState => ({
          ...prevState,
          [roomId]: [...(prevState[roomId] || []), { ...integration, icon: `/images/${platform.toLowerCase()}.png` }] // 添加图标
        }));
        return true; 
      }
    } catch (error) {
      console.error('Failed to add or update Integration:', error);
      return false; 
    }
  };

  // update
  const updateIntegration = async (roomId, index, updatedIntegration) => {
    setRoomIntegrations(prevState => ({
      ...prevState,
      [roomId]: prevState[roomId].map((integration, i) => 
        i === index ? updatedIntegration : integration
      )
    }));
  };

  // rm
  const removeIntegration = (roomId, index) => {
    setRoomIntegrations(prevState => ({
      ...prevState,
      [roomId]: prevState[roomId].filter((_, i) => i !== index)
    }));
  };

  // get
  const getIntegration = (roomId, index) => {
    return roomIntegrations[roomId]?.[index];
  };

  useEffect(() => {
    const roomIds = Object.keys(roomIntegrations);
    roomIds.forEach(roomId => {
      fetchIntegrations(roomId);
    });
  }, []); 

  return (
    <IntegrationContext.Provider value={{ roomIntegrations, addIntegration, updateIntegration, removeIntegration, getIntegration, fetchIntegrations }}>
      {children}
    </IntegrationContext.Provider>
  );
};

// hook
export const useIntegration = () => {
  return useContext(IntegrationContext);
};
