import React, { useState } from 'react'
import { Modal, Tabs } from 'antd';
import Tutorial1 from '../../assets/tutorial_1.jpg'
import Tutorial2 from '../../assets/tutorial_2.jpg'
import Tutorial3 from '../../assets/tutorial_3.jpg'
import Tutorial4 from '../../assets/tutorial_4.jpg'

const tabs = [
  { label: '步驟 1', key: '1' },
  { label: '步驟 2', key: '2' },
  { label: '步驟 3', key: '3' },
  { label: '步驟 4', key: '4' },
]

const tutorialContents = [
  {
    title: "申請 Line@ 官方帳號",
    content: [
      "登入「Line@ 官方帳號 (或 Line Developer)」後台",
      "選擇「設定」",
      "點擊「Messaging API」",
      "點擊「啟用 Messaging API」",
    ],
    caution: ["初次啟用須按照「LINE官方帳號」後台指示建立服務提供者",],
    image: Tutorial1,
  },
  {
    title: "複製 Channel ID 與 Channel Secret",
    content: [
      "啟用「Messaging API」後",
      "在「Basic settings」標籤下，找到「Channel ID」及「Channel Secret」",
      "將兩串文字先複製儲存下來",
    ],
    caution: [],
    image: Tutorial2,
  },
  {
    title: "複製 Channel Access Token",
    content: [
      "於「Line Developers」點擊網站右上方「Console」可進入管理後台",
      "選擇欲啟用的LINE官方帳號的 Channel，並點選「Messaging API」標籤",
      "點選 Issue 生成「Channel Access Token」並將文字複製儲存下來",
    ],
    caution: ["若您在 Line Developer 更新 (Reissue) Access Token，請記得將新的 Access Token 更新至本系統中",],
    image: Tutorial3,
  },
  {
    title: "於 Line 後台填入本站生成之 Webhook 網址",
    content: [
      "將保存的三段文字填至本系統中相應的欄位，並點選「串接」",
      "複製生成的 Webhook 網址",
      "於「Line Developer」中的「Messaging API」標籤下，將複製的網址填入 Webhook 網址欄位",
      "按下「儲存」並「驗證」",
      "某些情況下，需手動將「Use webhook」啟用",
    ],
    caution: [
      "聊天室與 Line@ 為一對一關係。若此 Line@ 帳號之 Webhook 網址被其他聊天室之網址取代，本聊天室串接功能將失效",
      "可在 Line@ 後台將 Line 的自動回覆訊息關閉，避免與本系統的回覆訊息重複",
    ],
    image: Tutorial4,
  }
]

const TutorialContent = ({ tutorial }) => {
  const { title, content, caution, image } = tutorial

  return (
    <div className="flex flex-col p-4 gap-4">
      <h2 className="text-2xl font-bold text-textDark mb-2">{title}</h2>
      <div className="flex flex-row items-center justify-between gap-8">
        <div className="flex flex-col">
          {content.map((item, index) => (
            <p key={index} className="mb-2 text-textDark text-md">
              {item}
            </p>
          ))}
          {caution.length > 0 && (
            <div className="flex flex-col items-start text-textDark text-xs my-4">
              <span className="font-bold">注意事項:</span>
              <ul>
                {caution.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <img src={image} alt={title} className="w-2/3" />
      </div>
    </div>
  )
}

const TutorialModal = ({ open, close }) => {
  const [tab, setTab] = useState('1')

  return (
    <Modal
      open={open}
      width={1200}
      footer={null}
      onCancel={close}
      title="說明"
    >
      <Tabs
        defaultActiveKey={tab}
        onChange={setTab}
      >
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}>
            <TutorialContent tutorial={tutorialContents[parseInt(tab.key) - 1]} />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Modal>
  )
}

export default TutorialModal