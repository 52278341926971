// src/constants/constants.js

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const MQTT_URL = "wss://api-broker.scfg.io";
export const MQTT_PORT = process.env.REACT_APP_MQTT_PORT;

export const tabList = [
  {
    key: 0,
    label: "Airbnb",
  },
  {
    key: 1,
    label: "Line",
  },
  {
    key: 2,
    label: "Agoda",
  },
];
