import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

const ConfirmWindow = ({ title, context, handlePopupClose, confirmAction }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30 z-30">
      <div className='w-[400px] h-[280px] bg-[#F2F2F2] flex flex-col p-1 justify-between items-center rounded-[9px]'>
        <div className='flex w-full justify-end p-1'>
          <CloseOutlined onClick={handlePopupClose} />
        </div>
        <div className='flex flex-col justify-start items-center h-full w-[300px]'>
          <h6 className='font-semibold text-[20px] py-3'>{title}</h6>
          <p className='text-wrap	text-[17px] py-7'>{context}</p>
          <div className='flex w-3/5 justify-around py-6 '>
            <button className=' bg-[#ADDEAC] w-[58px] h-[31px] rounded-[4px]'
              onClick={confirmAction}>確定</button>
            <button className=' bg-[#DEACAC] w-[58px] h-[31px] rounded-[4px]'
              onClick={handlePopupClose}>取消</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmWindow