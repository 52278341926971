import React from 'react';
import { ReactComponent as LightTheme } from '../assets/lightTheme.svg';

const Theme = () => {

  return (
    <div>
      <h3 className='text-[17px] font-semibold p-[12px]'>主題</h3>
      <div className='flex'>
        <LightTheme />
      </div>
    </div>
  )
}

export default Theme