import React, { createContext, useState, useContext } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [roomNotifications, setRoomNotifications] = useState({});

  // set
  const setConfirmedDataForRoom = (roomId, inputLabel, confirmedData) => {
    setRoomNotifications(prevState => ({
      ...prevState,
      [roomId]: {
        ...(prevState[roomId] || {}),
        [inputLabel]: confirmedData,
      },
    }));
  };

  // get
  const getConfirmedDataForRoom = (roomId, inputLabel) => {
    return roomNotifications[roomId]?.[inputLabel] || null;
  };

  // rm
  const removeConfirmedDataForRoom = (roomId, index) => {
    setConfirmedDataForRoom(prevState => ({
      ...prevState,
      [roomId]: prevState[roomId].filter((_, i) => i !== index)
    }));
  };

  return (
    <NotificationContext.Provider value={{ setConfirmedDataForRoom, getConfirmedDataForRoom, removeConfirmedDataForRoom }}>
      {children}
    </NotificationContext.Provider>
  );
};

// hook
export const useNotification = () => {
  return useContext(NotificationContext);
};
