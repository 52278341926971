import React from "react";
import NotificationSetting from "./components/NotificationSetting";
import { useIntegration } from "@/contexts/IntegrationContext";

const RuleSetting = ({ room }) => {
  const { roomIntegrations} = useIntegration(); 
  const integrations = roomIntegrations[room.id] || [];

  const Divider = ({ className = "" }) => {
    return <hr className={`w-full border-[#E0E0E0] mt-2 ${className}`} />;
  };

  return (
    <>
      {
        integrations.length?
        <div className="flex flex-col justify-start items-start w-full py-6 gap-4 max-h-[100vh] overflow-y-auto">
      <div className="font-semibold text-[17px] text-textDark font-bold w-full">
        自動化排程
      </div>
      <div className="w-full bg-[#F0F0F0] border border-[#E0E0E0] rounded-[4px] p-4 flex flex-col gap-4">
        <NotificationSetting
        inputLabel="預訂後"
        limitText="*限制0~10小時"
        unit="小時"
        maxInput={10}
      />
        <Divider/>
        <NotificationSetting
        inputLabel="入住前"
        limitText="*限制最高14天"
        unit="天"
        maxInput={14}
      />
        <Divider/>
        <NotificationSetting
        inputLabel="入住前天"

      />
        <Divider/>
        <NotificationSetting
        inputLabel="入住當天"

      />
        <Divider/>
        <NotificationSetting
        inputLabel="退房隔天"

      />
      </div>
    </div>
    :
    <div className="flex flex-col justify-start items-start w-full py-6 gap-4 max-h-[100vh] overflow-y-auto">
      <div className="font-semibold text-[17px] text-textDark font-bold w-full">
        自動化排程
      </div>
      <h1>請先設定第三方設定</h1>
      </div>
      }
    </>
  );
};

export default RuleSetting;
