// AddServiceForm.jsx

import React, { useEffect, useState } from "react";
import { Button, Form, Tabs, message, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import "tailwindcss/tailwind.css";
import ShopInfo from "./ShopInfo";
import moment from "moment";
import { Link } from "react-router-dom";
import { HomeOutlined } from '@ant-design/icons';

const AddTagButton = () => (
  <button className="w-6 h-6 flex items-center justify-center">
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 12.5C24 15.6826 22.7357 18.7348 20.4853 20.9853C18.2348 23.2357 15.1826 24.5 12 24.5C8.8174 24.5 5.76515 23.2357 3.51472 20.9853C1.26428 18.7348 0 15.6826 0 12.5C0 9.3174 1.26428 6.26515 3.51472 4.01472C5.76515 1.76428 8.8174 0.5 12 0.5C15.1826 0.5 18.2348 1.76428 20.4853 4.01472C22.7357 6.26515 24 9.3174 24 12.5Z" fill="#9B9B9B"/>
      <rect x="4.36328" y="11.4102" width="15.2727" height="2.18182" rx="1.09091" fill="#FAFAFA"/>
      <rect x="11" y="19.7734" width="15.2727" height="2.18182" rx="1.09091" transform="rotate(-90 11 19.7734)" fill="#FAFAFA"/>
    </svg>
  </button>
);

const FacebookIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 10.5C2.00014 8.96045 2.44451 7.45364 3.27979 6.16038C4.11506 4.86712 5.30576 3.84234 6.70901 3.20901C8.11225 2.57568 9.66844 2.3607 11.1908 2.58987C12.7132 2.81904 14.1372 3.48262 15.2918 4.50099C16.4464 5.51936 17.2826 6.84926 17.7002 8.33111C18.1177 9.81296 18.0988 11.3838 17.6457 12.8552C17.1926 14.3265 16.3246 15.6359 15.1458 16.6262C13.967 17.6165 12.5275 18.2456 11 18.438V12.5H13C13.2652 12.5 13.5196 12.3946 13.7071 12.2071C13.8946 12.0196 14 11.7652 14 11.5C14 11.2348 13.8946 10.9804 13.7071 10.7929C13.5196 10.6054 13.2652 10.5 13 10.5H11V8.5C11 8.23478 11.1054 7.98043 11.2929 7.79289C11.4804 7.60536 11.7348 7.5 12 7.5H12.5C12.7652 7.5 13.0196 7.39464 13.2071 7.20711C13.3946 7.01957 13.5 6.76522 13.5 6.5C13.5 6.23478 13.3946 5.98043 13.2071 5.79289C13.0196 5.60536 12.7652 5.5 12.5 5.5H12C11.2044 5.5 10.4413 5.81607 9.87868 6.37868C9.31607 6.94129 9 7.70435 9 8.5V10.5H7C6.73478 10.5 6.48043 10.6054 6.29289 10.7929C6.10536 10.9804 6 11.2348 6 11.5C6 11.7652 6.10536 12.0196 6.29289 12.2071C6.48043 12.3946 6.73478 12.5 7 12.5H9V18.438C7.06661 18.1944 5.28861 17.2535 3.99975 15.792C2.7109 14.3304 1.99982 12.4487 2 10.5ZM10 20.5C15.523 20.5 20 16.023 20 10.5C20 4.977 15.523 0.5 10 0.5C4.477 0.5 0 4.977 0 10.5C0 16.023 4.477 20.5 10 20.5Z" fill="#3E3D3D"/>
  </svg>
);

const InstagramIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.8 0.5H14.2C17.4 0.5 20 3.1 20 6.3V14.7C20 16.2383 19.3889 17.7135 18.3012 18.8012C17.2135 19.8889 15.7383 20.5 14.2 20.5H5.8C2.6 20.5 0 17.9 0 14.7V6.3C0 4.76174 0.61107 3.28649 1.69878 2.19878C2.78649 1.11107 4.26174 0.5 5.8 0.5ZM5.6 2.5C4.64522 2.5 3.72955 2.87928 3.05442 3.55442C2.37928 4.22955 2 5.14522 2 6.1V14.9C2 16.89 3.61 18.5 5.6 18.5H14.4C15.3548 18.5 16.2705 18.1207 16.9456 17.4456C17.6207 16.7705 18 15.8548 18 14.9V6.1C18 4.11 16.39 2.5 14.4 2.5H5.6ZM15.25 4C15.5815 4 15.8995 4.1317 16.1339 4.36612C16.3683 4.60054 16.5 4.91848 16.5 5.25C16.5 5.58152 16.3683 5.89946 16.1339 6.13388C15.8995 6.3683 15.5815 6.5 15.25 6.5C14.9185 6.5 14.6005 6.3683 14.3661 6.13388C14.1317 5.89946 14 5.58152 14 5.25C14 4.91848 14.1317 4.60054 14.3661 4.36612C14.6005 4.1317 14.9185 4 15.25 4ZM10 5.5C11.3261 5.5 12.5979 6.02678 13.5355 6.96447C14.4732 7.90215 15 9.17392 15 10.5C15 11.8261 14.4732 13.0979 13.5355 14.0355C12.5979 14.9732 11.3261 15.5 10 15.5C8.67392 15.5 7.40215 14.9732 6.46447 14.0355C5.52678 13.0979 5 11.8261 5 10.5C5 9.17392 5.52678 7.90215 6.46447 6.96447C7.40215 6.02678 8.67392 5.5 10 5.5ZM10 7.5C9.20435 7.5 8.44129 7.81607 7.87868 8.37868C7.31607 8.94129 7 9.70435 7 10.5C7 11.2956 7.31607 12.0587 7.87868 12.6213C8.44129 13.1839 9.20435 13.5 10 13.5C10.7956 13.5 11.5587 13.1839 12.1213 12.6213C12.6839 12.0587 13 11.2956 13 10.5C13 9.70435 12.6839 8.94129 12.1213 8.37868C11.5587 7.81607 10.7956 7.5 10 7.5Z" fill="#3E3D3D"/>
  </svg>
);

const ThreadIcon = () => (
  <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.25 7.00454C13.673 1.13754 8.25 1.50454 8.25 1.50454C8.25 1.50454 0.75 1.00454 0.75 10.4995C0.75 19.9945 8.25 19.4955 8.25 19.4955C8.25 19.4955 12.708 19.7915 14.75 15.5775C15.417 13.7195 15.25 10.0045 8.75 10.0045C8.75 10.0045 5.75 10.0045 5.75 12.5045C5.75 13.4805 6.75 14.5045 8.25 14.5045C9.75 14.5045 11.421 13.4775 11.75 11.5045C12.75 5.50454 7.25 5.00454 5.75 7.50454" stroke="#3E3D3D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);

const TiktokIcon = () => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6002 3.32C11.9166 2.53962 11.5399 1.53743 11.5402 0.5H8.45016V12.9C8.42632 13.571 8.14303 14.2066 7.65992 14.6729C7.17682 15.1393 6.5316 15.3999 5.86016 15.4C4.44016 15.4 3.26016 14.24 3.26016 12.8C3.26016 11.08 4.92016 9.79 6.63016 10.32V7.16C3.18016 6.7 0.160156 9.38 0.160156 12.8C0.160156 16.13 2.92016 18.5 5.85016 18.5C8.99016 18.5 11.5402 15.95 11.5402 12.8V6.51C12.7932 7.40985 14.2975 7.89265 15.8402 7.89V4.8C15.8402 4.8 13.9602 4.89 12.6002 3.32Z" fill="#3E3D3D"/>
  </svg>
);

const AddShopForm = ({ onSave, services = [] }) => {
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [previewData, setPreviewData] = useState({
    name: '商家平台',
    provider: '商家',
    description: '',
    imageUrl: '/images/default-service.jpg',
    phone: '',
    email: '',
    ownerName: '',
    ownerPhone: '',
    establishDate: '',
    address: '',
    tags: [],
  });

  // Get form values
  const formValues = Form.useWatch([], form);

  // Update preview data
  useEffect(() => {
    if (formValues) {
      setPreviewData({
        name: formValues.shopName || '商家平台',
        provider: formValues.shopName || '商家',
        description: formValues.serviceDescription || '',
        imageUrl: formValues.serviceImage || '/images/default-service.jpg',
        phone: formValues.shopPhone || '',
        email: formValues.shopEmail || '',
        ownerName: formValues.ownerName || '',
        ownerPhone: formValues.ownerPhone || '',
        establishDate: formValues.establishDate || '',
        address: `${formValues.city || ''}${formValues.district || ''}${formValues.address || ''}`,
        tags: formValues.tags || [],
      });
    }
  }, [formValues]);

  // Set default form values
  useEffect(() => {
    form.setFieldsValue({
      serviceTarget: "",
      serviceTitle: "",
      serviceNumber: Date.now().toString(),
      serviceImage: '',
    });
  }, [form]);

  // Handle save
  const handleSave = () => {
    form.validateFields().then((values) => {
      console.log('提交的表單資訊:', values);
      const imageUrl = values.serviceImage || '/images/default-service.jpg';

      const newService = {
        key: services ? (services.length + 1).toString() : '1',
        provider: values.provider || '審核通過',
        status: '上架中',
        shopId: values.shopId || Date.now().toString(),
        name: values.shopName,
        description: values.serviceDescription,
        publishDate: moment().format('YYYY-MM-DD'),
        imageUrl,
        phone: values.shopPhone,
        email: values.shopEmail,
        ownerName: values.ownerName,
        ownerPhone: values.ownerPhone,
        establishDate: values.establishDate,
        city: values.city,
        district: values.district,
        tags: values.tags || [],
        order: '0',
        remaining: '0',
        memberDiscount: '0',
        description: '0',
      };

      onSave(newService);
      message.success("商家已成功增加，等待審核中");

      form.resetFields();
      setPreviewData({
        name: '預設商家名稱',
        provider: '待審核',
        description: '',
        imageUrl: '/images/default-service.jpg',
        phone: '',
        email: '',
        ownerName: '',
        ownerPhone: '',
        establishDate: '',
        address: '',
        tags: [],
      });
    }).catch((errorInfo) => {
      message.error("請填寫所有必要信息");
      console.log('errorInfo', errorInfo);
    });
  };

  // Define tabs
  const items = [
    {
      key: "1",
      label: "基本資訊",
      children: (
        <div className="bg-white p-6 rounded-lg">
          <ShopInfo form={form} />
        </div>
      ),
    }
  ];

  // Define service preview
  const ServicePreview = ({ data }) => (
    <>
      <div className="bg-[#F3F3F3] p-4" style={{ boxShadow: '0px -4px 8px 0px #00000012 inset' }}>
        <span className="text-lg font-semibold mb-4">商家資訊預覽</span>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg sticky top-4">
        <div className="relative mb-4">
          <img
            src={data.imageUrl || "/images/default-service.jpg"}
            alt={data.name}
            className="w-full h-48 object-cover rounded-lg"
          />
        </div>
        <div className="px-6">
          <h3 className="text-xl font-semibold mb-2">{data.name}</h3>
          
          {/* 標籤區塊 */}
          <div className="flex flex-wrap gap-2 mb-4 items-center">
            {data.tags.map((tag, index) => (
              <span key={index} className="px-3 py-1 bg-[#E8F3E8] text-[#4E734E] rounded-full text-sm">
                {tag}
              </span>
            ))}
            <AddTagButton />
          </div>

          {/* 社群平台連結 */}
          <div className="space-y-2 mb-4">
            <div className="flex items-center gap-2">
              <span className="w-6 h-6 flex items-center justify-center">
                <FacebookIcon />
              </span>
              <span>OOO</span>
              <span className="ml-auto text-black text-[14px] border bg-[#ADDEAC] px-2 rounded">前往</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="w-6 h-6 flex items-center justify-center">
                <InstagramIcon />
              </span>
              <span>OOO</span>
              <span className="ml-auto text-[14px] text-black border bg-gray-200 px-2 rounded">未綁定</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="w-6 h-6 flex items-center justify-center">
                <ThreadIcon />
              </span>
              <span>OOO</span>
              <span className="ml-auto text-[14px] text-black border bg-gray-200 px-2 rounded">未綁定</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="w-6 h-6 flex items-center justify-center">
                <TiktokIcon />
              </span>
              <span>OOO</span>
              <span className="ml-auto text-[14px] text-black border bg-gray-200 px-2 rounded">未綁定</span>
            </div>
          </div>

          {/* 基本資料 */}
          <div className="space-y-3 mb-4">
            <h4 className="font-semibold">基本資料</h4>
            <div className="flex items-center gap-2">
              <img src="/images/date.png" alt="plant" className="w-6 h-6" />
              <span>{data.establishDate || '199912月12日'}</span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/images/phone.png" alt="phone" className="w-6 h-6" />
              <span>{data.phone || '02-xxxx-xxxx'}</span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/images/email.png" alt="email" className="w-6 h-6" />
              <span>{data.email || 'OOOOO@gmail.com'}</span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/images/location.png" alt="location" className="w-6 h-6" />
              <span>{data.address || '台北市中山區中山北路50號9樓'}</span>
            </div>
          </div>

          {/* 負責人 */}
          <div className="space-y-3 mb-4">
            <h4 className="font-semibold">負責人</h4>
            <div className="flex items-center gap-2">
              <img src="/images/person.png" alt="user" className="w-6 h-6" />
              <span>{data.ownerName || 'OOO'}</span>
            </div>
            <div className="flex items-center gap-2">
              <img src="/images/phone.png" alt="phone" className="w-6 h-6" />
              <span>{data.ownerPhone || '09x-xxx-xxx'}</span>
            </div>
          </div>

          {/* 商家介紹 */}
          <div className="space-y-2">
            <h4 className="font-semibold">商家介紹</h4>
            <p className="text-sm text-gray-600 bg-[#D5EFD5] p-3 rounded">
              {data.description || '我們相信美容保養不該只是接受療程的一個地方，顛覆傳統，打造一個充滿優雅、時尚與潮流的環境。【加入Sparkle】會員儲值、消費即享暖心回饋禮，把握機會寵愛自己！'}
            </p>
          </div>
        </div>
      </div>
    </>
  );

  // add loading effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  // 新增面包屑導航組件
  const BreadcrumbNav = () => (
    <div className="flex flex-col space-y-2 mb-4 p-4">
      <div className="text-[24px] font-bold">
        商家管理
      </div>
      <div className="flex items-center text-sm">
        <Link to="/" className="text-green-600">
          <HomeOutlined className="mr-1" />
        </Link>
        <span className="mx-2 text-gray-500">/</span>
        <span
          className="text-green-600 cursor-pointer"
          onClick={() => window.location.href = '/shop'}
        >
          商家管理
        </span>
        <span className="mx-2 text-gray-500">/</span>
        <span className="text-gray-500">新增商家</span>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-auto">
      {/* 左側表單區域 */}
      <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full">
        {/* 面包屑導航 */}
        <BreadcrumbNav />
        <Form
          form={form}
          layout="vertical"
        >
          <div className="bg-white rounded-lg mb-6">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 p-4">
              <h1 className="text-[20px] font-semibold">新增商家</h1>
              <div className="flex gap-3">
                <Button type="default" className="w-full sm:w-auto">儲存至草稿</Button>
                <Button type="primary" onClick={handleSave} className="w-full sm:w-auto">
                  儲存並發佈
                </Button>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg">
              <div className="text-[20px] font-semibold mb-4">基本資訊</div>
              <ShopInfo form={form} />
            </div>
          </div>
        </Form>
      </div>

      {/* 右側預覽區域 */}
      <div className="w-[440px] flex-shrink-0">
        <div>
          {previewData && (
            <ServicePreview data={previewData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddShopForm;
