import React, { useState, useEffect } from "react";
import { Table, Input, Button, Select, Tag, Tabs } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import ToolBar from "./components/ToolBar";
import { initialData } from "./data/TicketData";

const TicketDetail = ({ ticket }) => {
    const items = [
        {
            key: "1",
            label: "票券資訊",
            children: (
                <div className="flex-1 pb-20">
                    <div className="flex flex-col bg-white rounded-lg shadow gap-1">
                        {/* 票券圖片區域 */}
                        <div className="p-4">
                            <div className="relative mb-4">
                                {/* 只在推薦值為1-5時顯示熱門標籤 */}
                                {ticket.recommendation && ticket.recommendation >= 1 && ticket.recommendation <= 5 && (
                                    <div className="absolute top-2 left-2">
                                        <Tag color="red">熱門</Tag>
                                    </div>
                                )}
                                <img
                                    src={"/images/service2.png" || ticket.imageUrl}
                                    alt={ticket.name}
                                    className="w-full h-48 object-cover rounded-lg"
                                />
                                <div className="absolute bottom-2 right-2">
                                    <div className="flex gap-1">
                                        <div className="w-2 h-2 rounded-full bg-white"></div>
                                        <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
                                        <div className="w-2 h-2 rounded-full bg-white opacity-50"></div>
                                    </div>
                                </div>
                            </div>

                            {/* 票券標題和基本信息 */}
                            <h3 className="text-lg font-bold mb-1">{ticket.ticketName}</h3>
                            <p className="text-sm text-gray-600 mb-2">{ticket.name}</p>
                            <div className="text-sm text-gray-700 mb-4">
                                Enjoy a seamless golf cart rental experience in Taichung with our affordable hourly rates. Whether you need it for a leisurely ride or a day out on the golf course, our well-maintained carts are available for rent.
                            </div>

                            {/* 兌換金額和使用門檻 */}
                            <div className="space-y-4">
                                <div>
                                    <h4 className="text-sm text-gray-500">兌換金額</h4>
                                    <div className="text-xl font-bold">NT${ticket.price}</div>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-500">使用門檻</h4>
                                    <div>消費需滿20,000</div>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-500">使用地點</h4>
                                    <div>{ticket.name}</div>
                                </div>
                                <div>
                                    <h4 className="text-sm text-gray-500">使用期限</h4>
                                    <div>{ticket.validPeriod}</div>
                                </div>
                            </div>
                        </div>

                        {/* 使用須知 */}
                        <div className="p-4 border-t">
                            <h4 className="text-base font-bold mb-3">注意事項</h4>
                            <ul className="text-sm text-gray-700 space-y-2">
                                <li>- 在接受療程前，請告知皮膚敏感情況或過敏史。</li>
                                <li>- 為確保療程效果，建議避免在療程當天進行其他臉部護理。</li>
                                <li>- 療程後，請避免暴露在陽光下，並注意使用防曬商品保護皮膚。</li>
                                <li>- 如有任何不適或疑問，請立即告知治療師。</li>
                            </ul>
                        </div>

                        {/* 領券連結 */}
                        <div className="p-4 border-t">
                            <h4 className="text-base font-bold mb-3">領券連結</h4>
                            <div className="flex items-center gap-2">
                                <Input.TextArea
                                    rows={2}
                                    value="https://example.com/ticket/123"
                                    readOnly
                                />
                                <Button type="primary">複製</Button>
                            </div>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="flex flex-col h-full relative">
            <div className="flex-1">
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4"
                />
            </div>
        </div>
    );
};

const Ticket = () => {
    // 定義列
    const columns = [
        {
            title: "推薦",
            dataIndex: "recommendation",
            width: 60,
            fixed: "left",
            sorter: (a, b) => {
                // Handle null values
                if (a.recommendation === null && b.recommendation === null) return 0;
                if (a.recommendation === null) return 1;
                if (b.recommendation === null) return -1;
                return a.recommendation - b.recommendation;
            },
            render: (value, record) => (
                <Input
                    type="text"
                    style={{
                        width: '60px',
                        '-moz-appearance': 'textfield'
                    }}
                    value={value || ''}
                    onChange={(e) => {
                        const inputValue = e.target.value;

                        // if input is empty, update to null
                        if (inputValue === '') {
                            const updatedTickets = tickets.map(ticket => {
                                if (ticket.key === record.key) {
                                    return { ...ticket, recommendation: null };
                                }
                                return ticket;
                            });
                            setTickets(updatedTickets);
                            return;
                        }

                        let newValue = parseInt(inputValue);

                        // check if it's a valid number and between 1 and 5
                        if (isNaN(newValue) || newValue < 1 || newValue > 5) return;

                        // check if the new number is already used
                        const isNumberUsed = tickets.some(
                            ticket => ticket.key !== record.key && ticket.recommendation === newValue
                        );

                        if (isNumberUsed) {
                            // if the number is already used, don't update
                            return;
                        }

                        const updatedTickets = tickets.map(ticket => {
                            if (ticket.key === record.key) {
                                return { ...ticket, recommendation: newValue };
                            }
                            return ticket;
                        });
                        setTickets(updatedTickets);
                    }}
                    className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                />
            ),
        },
        {
            title: "上架商家",
            dataIndex: "name",
            width: 150,
            fixed: "left",
            render: (text, record) => (
                <div className="flex items-center">
                    <img
                        // 有圖片後 左右兩個對調
                        src={"/images/service2.png" || record.imageUrl}
                        alt="商家圖片"
                        className="w-6 h-6 object-cover rounded mr-2"
                    />
                    <span>{text}</span>
                </div>
            ),
        },
        {
            title: "前台狀態",
            dataIndex: "type",
            width: 90,
            fixed: "left",
            render: (status) => (
                <span
                    className={`badge rounded-full px-2 py-1 text-white ${status === "上架中" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
                        }`}
                >
                    {status}
                </span>
            ),
        },
        {
            title: "審核狀態",
            dataIndex: "value",
            width: 100,
        },
        {
            title: "票券序號",
            dataIndex: "ticketId",
            width: 100,
        },
        {
            title: "名稱",
            dataIndex: "ticketName",
            width: 180,
        },
        {
            title: "價格",
            dataIndex: "price",
            width: 100,
        },
        {
            title: "庫存量",
            dataIndex: "inventory",
            width: 80,
        },
        {
            title: "已核銷",
            dataIndex: "usedCount",
            width: 80,
        },
        {
            title: "折抵兌換",
            dataIndex: "exchangeInfo",
            width: 150,
        },
        {
            title: "優惠條件",
            dataIndex: "conditions",
            width: 150,
        },
        {
            title: "使用期限",
            dataIndex: "validPeriod",
            width: 120,
        },
        {
            title: "標籤",
            dataIndex: "tags",
            width: 180,
            render: (tags) => (
                <div className="flex flex-wrap gap-1">
                    {tags.map((tag) => (
                        <Tag key={tag}>{tag}</Tag>
                    ))}
                </div>
            ),
        },
        {
            title: "上架日期",
            dataIndex: "publishDate",
            width: 120,
        },
    ];

    const [loading, setLoading] = useState(true);
    const [tickets, setTickets] = useState(initialData);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [searchText, setSearchText] = useState("");

    // 處理搜索
    const handleSearch = (value) => {
        setSearchText(value);
    };

    // 處理篩選後的票券
    const filteredTickets = tickets.filter((ticket) => {
        return (
            searchText === "" ||
            ticket.name.toLowerCase().includes(searchText.toLowerCase())
        );
    });

    // 處理行點擊事件
    const onRowClick = (record) => {
        setSelectedTicket(record);
    };

    // 在組件加載時設置默認的票券
    useEffect(() => {
        if (!loading && tickets.length > 0 && !selectedTicket) {
            setSelectedTicket(tickets[0]);
        }
    }, [loading, tickets, selectedTicket]);

    // 載入效果
    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 500);
        return () => clearTimeout(timer);
    }, []);

    // 添加初始化推薦值的 useEffect
    useEffect(() => {
        if (tickets.length > 0) {
            const updatedTickets = tickets.map((ticket, index) => ({
                ...ticket,
                recommendation: index < 5 ? index + 1 : null
            }));
            setTickets(updatedTickets);
        }
    }, []); // 僅在組件首次加載時執行

    if (loading) {
        return (
            <div className="flex justify-center items-center w-full h-screen">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
                <ToolBar pure={true} />
                <div className="flex flex-col w-full h-[calc(100vh-64px)]">
                    <div className="flex-1 flex overflow-auto">
                        <div className="flex w-full overflow-auto bg-gray-50">
                            {/* 左側表格區域 */}
                            <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">
                                <div className="flex justify-between items-center">
                                    <div className="text-lg font-bold">票券管理</div>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                    >
                                        新增票券
                                    </Button>
                                </div>

                                {/* Statistics */}
                                <div className="grid grid-cols-5 gap-4 mb-4">
                                    <div className="col-span-5 bg-[#F3F3F3] rounded-lg shadow">
                                        <div className="h-[36px] pt-2 pl-2 ">
                                            <img
                                                src="/images/folder.png"
                                                alt="folder"
                                                className="w-[20px] h-[20px]"
                                            />
                                        </div>
                                        <div className="grid grid-cols-2 gap-2">
                                            <div className="p-2 text-start rounded-lg">
                                                <div className="text-sm">總商家數</div>
                                                <div className="text-2xl font-bold">
                                                    350
                                                </div>
                                            </div>
                                            <div className="p-2 text-start rounded-lg">
                                                <div className="text-sm">營業中</div>
                                                <div className="text-2xl font-bold">
                                                    316
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* 功能按鈕區域 */}
                                <div className="flex justify-end space-x-4 mb-4 mt-4">
                                    <Button
                                        type="primary"
                                    >
                                        匯出票券資料
                                    </Button>
                                </div>

                                {/* 表格 */}
                                <div className="flex-1 overflow-auto">
                                    <Table
                                        style={{
                                            height: "100%",
                                        }}
                                        columns={columns}
                                        dataSource={filteredTickets}
                                        pagination={{
                                            pageSize: 10,
                                            className: "sticky bottom-0 pb-2"
                                        }}
                                        onRow={(record) => ({
                                            onClick: () => onRowClick(record),
                                            className: record.key === selectedTicket?.key
                                                ? "bg-gray-100"
                                                : "",
                                        })}
                                        rowClassName={(record) =>
                                            `cursor-pointer ${record.key === selectedTicket?.key
                                                ? "bg-gray-100"
                                                : ""
                                            }`
                                        }
                                        scroll={{ x: "max-content" }}
                                        className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0] h-full"
                                        size="small"
                                    />
                                </div>
                            </div>

                            {/* 右側預覽區域 */}
                            <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                                <div>
                                    {selectedTicket ? (
                                        <TicketDetail ticket={selectedTicket} />
                                    ) : (
                                        <div className="flex items-center justify-center h-[calc(100vh-64px)] text-gray-400">
                                            請選擇票券以查看詳細資訊
                                        </div>
                                    )}
                                </div>
                                <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                                    <Button
                                        type="primary"
                                        className="w-full h-10 bg-[#4CAF50] hover:bg-[#45a049]"
                                        disabled={!selectedTicket}
                                    >
                                        編輯票券
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ticket; 