import { request } from "@/utils";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// retry
const retryRequest = async (requestFn, retries = 5, delayMs = 2000) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await requestFn();
    } catch (error) {
      console.error(`Attempt ${attempt} failed:`, error);
      if (attempt < retries) {
        await delay(delayMs);
      } else {
        throw error;
      }
    }
  }
};

// refresh agoda clients
export const refreshAgoda = (fetching, fetchAgodaClients) => {
  if (!fetching) {
    fetchAgodaClients(true);
  }
};

// check agoda profile
const getprofile = async (roomId, setLoading) => {
  try {
    await request.get(`/private/module/rpa/profile/${roomId}`);
    console.warn("Profile fetched successfully");
    return true;
  } catch (error) {
    console.warn("Error fetching profile");
    setLoading(false);
    return false;
  }
};

// fetch agoda history
export const fetchAgodaHistory = async (
  setOrderAgodaMessage,
  setAgodaMessage,
  roomId,
  listLoading,
  setListLoading
) => {
  const getHistory = (limit = 60) => request.get(
    `/private/module/rpa/agoda/history/${roomId}`, {
    params: {
      limit: limit
    }
  });
  try {
    setListLoading(true);
    const historyResponse = await getHistory();
    const allMessages = historyResponse.flatMap(item => {
      const getMessages = item.get_messages;
      const messageId = item.message_id;
      const lastSubmitTimestamp = item.last_submit_timestamp;
      
      if (getMessages && typeof getMessages === 'object') {
        return [{
          ...getMessages,
          message_id: messageId,
          last_submit_timestamp: lastSubmitTimestamp
        }];
      }
      return [];
    });
    const allOrders = historyResponse.flatMap(item => {
      const getOrders = item.get_order_info;
      const messageId = item.message_id;
      const lastSubmitTimestamp = item.last_submit_timestamp;
      if (getOrders && typeof getOrders === 'object') {
        return [{
          ...getOrders,
          message_id: messageId,
          last_submit_timestamp: lastSubmitTimestamp
        }];
      }
      return [];
    });
    try {
      // set message to state
      setAgodaMessage(prevMessages => {
        const prevAgodaMessages = Array.isArray(prevMessages) ? prevMessages : [];
        const newMessages = allMessages.filter(
          newMsg => !prevAgodaMessages.some(existingMsg => existingMsg.message_id === newMsg.message_id)
        );
        if (newMessages.length === 0) return prevAgodaMessages;
        const updatedMessages = [...prevAgodaMessages, ...newMessages];
        return updatedMessages;
      });
    } catch (error) {
      console.log('8787', error);
    }
    // set order to state
    try {
      setOrderAgodaMessage(prevOrders => {
        const prevOrderMessages = Array.isArray(prevOrders) ? prevOrders : [];
        const updatedOrders = [...prevOrderMessages, ...allOrders];
        return updatedOrders;
      });
    } catch (error) {
      console.log('設定訂單時發生錯誤:', error);
    }
  } catch (error) {

  }finally {
    setListLoading(false);
  }
};

// fetch agoda clients
export const fetchAgodaClients = async (
  serverUrl,
  room,
  token,
  setClientsAgoda,
  setLoading,
  setFetching,
  requestQueue,
  setRequestQueue,
  setMessageId,
  roomId,
  setAgodaMessage,
  setOrderAgodaMessage,
  orderAgodaMessage,
  listLoading,
  setListLoading
) => {
  setLoading(true);
  setFetching(true);
  const profileSuccess = await getprofile(room.id, setLoading);
  if (!profileSuccess) {
    return;
  }

  if (requestQueue.length > 0) {
    setRequestQueue((prevQueue) => [...prevQueue, { roomId, token }]);
    setLoading(false);
    setFetching(false);
    return;
  }

  try {
    setListLoading(true);
    // get message ids
    const getMessageIds = () => request.get(`/private/module/rpa/agoda/message_ids/${roomId}`);

    let idsResponse;
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 15000; // 15秒

    for (let retryCount = 0; retryCount < MAX_RETRIES; retryCount++) {
      try {
        idsResponse = await retryRequest(getMessageIds);
        
        // 檢查時間戳
        const lastUpdate = idsResponse?.last_update_timestamp;
        const timeDiff = (Date.now() / 1000) - lastUpdate;
        
        if (!lastUpdate || timeDiff > 5 * 60) {
          console.warn(`Message IDs 資料已過期 (${Math.floor(timeDiff)}秒)，嘗試重新獲取 (嘗試 ${retryCount + 1}/${MAX_RETRIES})`);
          
          try {
            // 發送 POST 請求重新獲取資料
            await request.post(
              `/private/module/rpa/agoda/message_ids/${roomId}`,
              {}
            );
          } catch (postError) {
            // 如果是 400 錯誤或任務已在運行，只記錄警告
            if (postError.response?.status === 400 || 
                postError.response?.data?.detail === "Task is already running") {
              console.warn("任務已在運行中或發生 400 錯誤，繼續等待...");
            } else {
              console.error("POST message_ids 失敗：", postError);
            }
          }

          // 無論 POST 是否成功，都等待 15 秒
          console.warn("等待 15 秒後重新獲取資料...");
          await delay(15000);
          
          // 重新獲取資料
          try {
            idsResponse = await retryRequest(getMessageIds);
          } catch (getError) {
            console.error("重新獲取 message_ids 失敗：", getError);
            if (retryCount === MAX_RETRIES - 1) {
              throw getError;
            }
            continue;
          }
        } else {
          console.warn("獲取到的資料在有效期內，繼續執行");
          break;
        }
      } catch (error) {
        if (retryCount === MAX_RETRIES - 1) {
          console.error("最終獲取 message_ids 失敗：", error);
          return;
        }
        console.warn(`獲取 message_ids 失敗，等待後重試 (嘗試 ${retryCount + 1}/${MAX_RETRIES})`);
        await delay(RETRY_DELAY);
      }
    }

    if (!idsResponse?.result?.length) {
      console.warn("沒有返回 message IDs。");
      return;
    }

    const lastUpdate = idsResponse?.last_update_timestamp;
    const timeDiff = (Date.now() / 1000) - lastUpdate;
    if (timeDiff > 5 * 60) {
      console.warn(`最終獲取的 Message IDs 資料仍然過期 (${Math.floor(timeDiff)}秒)，退出操作`);
      return;
    }

    const messageIds = idsResponse.result;
    let messages = [];

    for (const messageId of messageIds) {
      try {
        // get message
        await delay(2000);
        const getMessage = () =>
          request.get(`/private/module/rpa/agoda/message/${roomId}`, {
            params: {
              message_id: messageId,
            },
          });
        // add order info fetching
       await fetchAgodaOrder(roomId, messageId, setOrderAgodaMessage, orderAgodaMessage);

        let messageResponse;
        try {
          messageResponse = await retryRequest(getMessage);
          if (
            messageResponse.status === "pending" ||
            messageResponse.status === "failed"
          ) {
            console.warn(
              `Message (${messageId}) 狀態為 pending，嘗試 POST 開啟任務。`
            );

            // post message
            const postMessage = () =>
              request.post(
                `/private/module/rpa/agoda/message/${roomId}`,
                {},
                {
                  params: {
                    message_id: messageId,
                    clean_run: false,
                  },
                }
              );

            try {
              await postMessage();
            } catch (postError) {
              console.error(`POST message (${messageId}) 失敗：`, postError);
            }

            // get message again
            try {
              await delay(2000);
              messageResponse = await retryRequest(getMessage);
            } catch (finalError) {
              console.error(
                `最終 GET message (${messageId}) 失敗：`,
                finalError
              );
              continue; // continue to next messageId
            }
          }
        } catch (error) {
          console.warn(`GET message (${messageId}) 失敗，嘗試 POST 開啟任務。`);

          // post message
          const postMessage = () =>
            request.post(
              `/private/module/rpa/agoda/message/${roomId}`,
              {},
              {
                params: {
                  message_id: messageId,
                  clean_run: false,
                },
              }
            );

          try {
            await postMessage();
          } catch (postError) {
            console.error(`POST message (${messageId}) 失敗：`, postError);
          }

          // get message again
          try {
            await delay(2000);
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }

        const messageLastUpdate = messageResponse?.last_update_timestamp;
        console.warn(
          "距離上次更新時間",
          Date.now() / 1000 - messageLastUpdate,
          "秒"
        );
        if (
          messageLastUpdate &&
          Date.now() / 1000 - messageLastUpdate > 5 * 60
        ) {
          console.warn(
            `Message (${messageId}) 超過 5 分鐘未更新，嘗試 POST 開啟任務。`
          );

          // post message
          const postMessage = () =>
            request.post(
              `/private/module/rpa/agoda/message/${roomId}`,
              {},
              {
                params: {
                  message_id: messageId,
                },
              }
            );

          try {
            // post message
            await postMessage();
            // get message again
            messageResponse = await retryRequest(getMessage);
          } catch (error) {
            console.error(`處理 message (${messageId}) 失敗：`, error);
          }

          // get
          try {
            await delay(2000);
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }
        if (messageResponse) {
          const updatedMessageResponse = {
            ...messageResponse,
            message_id: messageId
          };
          setAgodaMessage(prevMessages => {
            const prevAgodaMessages = Array.isArray(prevMessages) ? prevMessages : [];

            const existingIndex = prevAgodaMessages.findIndex(
              (msg) => msg.message_id === updatedMessageResponse.message_id
            );
    
            if (existingIndex !== -1) {
              console.log(`message (${messageId}) 已存在，已更新。`);

              const updatedMessages = [...prevAgodaMessages];
          updatedMessages[existingIndex] = updatedMessageResponse; 

          // update localStorage
          localStorage.setItem("agodaMessages", JSON.stringify(updatedMessages));

          return updatedMessages;
        }
            console.log(`message (${messageId}) 不存在，增加一筆。`);

            // add message to state
            const updatedMessages = [...prevAgodaMessages, updatedMessageResponse];

            // update localStorage
            localStorage.setItem("agodaMessages", JSON.stringify(updatedMessages));

            return updatedMessages;
          });
        }
        messages = [];
      } catch (error) {
        console.error(`處理 message (${messageId}) 時發生錯誤：`, error);
        continue; // continue to next messageId
      }
    }

    // queue next request
    if (requestQueue.length > 0) {
      const nextRequest = requestQueue.shift();
      setRequestQueue([...requestQueue]);
      fetchAgodaClients({
        serverUrl,
        room,
        token: nextRequest.token,
        setClientsAgoda,
        setLoading,
        setFetching,
        requestQueue,
        setRequestQueue,
        setMessageId,
        roomId: nextRequest.roomId,
      });
    }
  } catch (error) {
    console.error("獲取 Agoda 客戶端時發生錯誤：", error);
    try {
      // post message ids
      const postMessageIds = () =>
        request.post(`/private/module/rpa/agoda/message_ids/${roomId}`);
      // post message ids
      await postMessageIds();
    } catch (postError) {
      console.error("POST 開啟任務時發生錯誤：", postError);
    } finally {
      setFetching(false);
      setLoading(false);
    }
  } finally {
    setLoading(false);
    setFetching(false);
    setListLoading(false);
  }
};

// POST request to fetch order info
const postOrder = (roomId, messageId) =>
  request.post(
    `/private/module/rpa/agoda/order_info/${roomId}`,
    {},
    {
      params: { message_id: messageId },
    }
  );

// GET request to fetch order info
const getOrder = (roomId, messageId) =>
  request.get(`/private/module/rpa/agoda/order_info/${roomId}`, {
    params: { message_id: messageId },
  });

const fetchAgodaOrder = async (roomId, messageId, setOrderAgodaMessage) => {
    try {
      let getResponse = await getOrder(roomId, messageId);
  
      // 處理 GET 請求失敗的情況
      if (getResponse.status === "failed") {
        console.log("GET 失敗，嘗試 POST 任務開啟");
        try {
          await delay(2000);
          await postOrder(roomId, messageId);
        } catch (error) {
          console.error("POST 任務開啟失敗，等待後重試 GET");
          await delay(5000);
          getResponse = await getOrder(roomId, messageId);
        }
      }
  
      // 處理 GET 請求處於 pending 狀態的情況
      if (getResponse.status === "pending") {
        console.log("GET 仍處於 pending，嘗試 POST 任務開啟");
        try {
          await delay(2000);
          await postOrder(roomId, messageId);
        } catch (error) {
          console.error("POST 任務開啟失敗，等待後重試 GET");
          await delay(5000);
          getResponse = await getOrder(roomId, messageId);
        }
      }
  
      if (getResponse) {
        // 確保 message_id 被正確增加
        const updatedResponse = { ...getResponse, message_id: messageId };
        setOrderAgodaMessage(prevOrders => {
          const prevOrderList = Array.isArray(prevOrders) ? prevOrders : [];
          const existingIndex = prevOrderList.findIndex(order => order.message_id === messageId);
  
          if (existingIndex !== -1) {
            // 更新已存在的訂單
            const updatedOrders = [...prevOrderList];
            updatedOrders[existingIndex] = updatedResponse;
            console.log(`Order (${messageId}) 已存在，已更新。`);
            return updatedOrders;
          } else {
            // 增加新的訂單
            const updatedOrders = [...prevOrderList, updatedResponse];
            console.log(`Order (${messageId}) 不存在，增加一筆。`);
            return updatedOrders;
          }
        });
      }
  
    } catch (error) {
      console.error("獲取 order 資訊時發生錯誤：", error);
      try {
        console.log("嘗試 POST 作為恢復措施");
        await postOrder(roomId, messageId);
        await delay(2000);
        let getResponse = await getOrder(roomId, messageId);
  
        if (getResponse.status === "pending") {
          console.log("POST 成功，但 GET 仍 pending，嘗試再次 GET");
          await delay(2000);
          getResponse = await getOrder(roomId, messageId);
        }
  
        if (getResponse) {
          const updatedResponse = { ...getResponse, message_id: messageId };
  
          setOrderAgodaMessage(prevOrders => {
            const prevOrderList = Array.isArray(prevOrders) ? prevOrders : [];
            const existingIndex = prevOrderList.findIndex(order => order.message_id === messageId);
  
            if (existingIndex !== -1) {
              // 更新已存在的訂單
              const updatedOrders = [...prevOrderList];
              updatedOrders[existingIndex] = updatedResponse;
              console.log(`Order (${messageId}) 已存在，已更新。`);
              return updatedOrders;
            } else {
              // 增加新的訂單
              const updatedOrders = [...prevOrderList, updatedResponse];
              console.log(`Order (${messageId}) 不存在，增加一筆。`);
              return updatedOrders;
            }
          });
        }
      } catch (postError) {
        console.error("POST 請求失敗：", postError);
      }
    }
  };
  
