import React, { useState } from 'react'
import axios from 'axios';
import { Form, Input, Button, notification } from "antd";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Forget = () => {
  const [email, setEmail] = useState("")
  const [username, setUsername] = useState("")
  const [loading, setLoading] = useState(false);

  const openNotification = (title, description, type = 'info') => {
    notification[type]({
      message: title,
      description: description,
      placement: "bottomRight",
    });
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!email.includes("@")) {
      openNotification("Email 格式錯誤", "請輸入正確的 Email 格式", "error");
      setLoading(false);
      return
    }

    try {
      await axios.post(SERVER_URL + "/public/auth/password", {
        email: email,
        username: username
      })
    } catch (error) {
      openNotification("找回密碼失敗", "請確認 Email 和帳號是否正確", "error");
      setLoading(false);
      return
    }

    openNotification("重設密碼信件已寄出", "請至您註冊的 Email 帳戶中重新設定密碼", "success");
    setLoading(false);
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="flex flex-col items-center justify-center w-80 gap-4">
        <a href="/login" className="flex flex-row juystify-start w-full hover:underline cursor-pointer">
          返回
        </a>
        <div className="text-2xl font-bold">
          忘記密碼
        </div>
        <Form
          layout="vertical"
          style={{
            width: "320px",
          }}
        >
          <Form.Item label="Email">
            <Input disabled={loading} onChange={e => setEmail(e.target.value)} />
          </Form.Item>
          <Form.Item label="帳號">
            <Input disabled={loading} onChange={e => setUsername(e.target.value)} />
          </Form.Item>

          <Form.Item className="flex flex-row justify-end w-full">
            <Button onClick={handleSubmit} disabled={!email || !username} loading={loading} className="mt-2">寄送重設密碼信件</Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default Forget