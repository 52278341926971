import React, { useState, useEffect, useRef } from "react";
import { Table, Input, Button, Select, Tag, Tabs, Divider } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { Spin } from "antd";
import AddServiceForm from "./components/AddServiceForm";
import { LoadingOutlined } from "@ant-design/icons";
import OrderManagement from "./components/OrderManagement";
import moment from "moment";
import ToolBar from "./components/ToolBar";
import { initialData } from "./data/ServiceData";

const Service = () => {
  // Define columns
  const columns = [
    {
      title: "推薦",
      width: "5%",
      fixed: "left",
      dataIndex: "recommendation",
      align: "center",
      sorter: (a, b) => {
        // Handle null values
        if (a.recommendation === null && b.recommendation === null) return 0;
        if (a.recommendation === null) return 1;
        if (b.recommendation === null) return -1;
        return a.recommendation - b.recommendation;
      },
      render: (value, record) => (
        <Input
          type="text"
          style={{
            width: '60px',
            '-moz-appearance': 'textfield'
          }}
          value={value || ''}
          onChange={(e) => {
            const inputValue = e.target.value;

            // if input is empty, update to null
            if (inputValue === '') {
              const updatedServices = services.map(service => {
                if (service.key === record.key) {
                  return { ...service, recommendation: null };
                }
                return service;
              });
              setServices(updatedServices);
              return;
            }

            let newValue = parseInt(inputValue);

            // check if it's a valid number and between 1 and 5
            if (isNaN(newValue) || newValue < 1 || newValue > 5) return;

            // check if the new number is already used
            const isNumberUsed = services.some(
              service => service.key !== record.key && service.recommendation === newValue
            );

            if (isNumberUsed) {
              // if the number is already used, don't update
              return;
            }

            const updatedServices = services.map(service => {
              if (service.key === record.key) {
                return { ...service, recommendation: newValue };
              }
              return service;
            });
            setServices(updatedServices);
          }}
          className="[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        />
      ),
    },
    {
      title: "上架商家",
      dataIndex: "provider",
      width: "10%",
      fixed: "left",
      sorter: (a, b) => a.provider.localeCompare(b.provider),
    },
    {
      title: "前台狀態",
      dataIndex: "status",
      width: "6%",
      fixed: "left",
      align: "center",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <span
          className={`badge rounded-full px-2 py-1 text-white ${status === "上架中" ? "bg-[#67BE5F]" : "bg-[#B6B3A7]"
            }`}
        >
          {status}
        </span>
      ),
    },
    {
      title: "服務序號",
      dataIndex: "serviceId",
      width: "6%",
      align: "center",
      sorter: (a, b) => a.serviceId.localeCompare(b.serviceId),
    },
    {
      title: "服務名稱",
      dataIndex: "name",
      width: "15%",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => (
        <div className="flex items-center">
          <img
            src={record.imageUrls?.length > 0 ? record.imageUrls[0] : record.imageUrl}
            alt="服務縮圖"
            className="w-6 h-6 object-cover rounded mr-2"
          />
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "價格",
      dataIndex: "price",
      width: "8%",
      align: "center",
      sorter: (a, b) => a.price - b.price,
      render: (price) => `NT$${price}`,
    },
    {
      title: "訂單數",
      dataIndex: "order",
      width: "7%",
      sorter: (a, b) => a.order - b.order,
      render: (order, record) => (
        <Button
          type="link"
          onClick={(e) => {
            e.stopPropagation();
            setShowOrderManagement(true);
            setSelectedService(record);
          }}
        >
          {order}
          <img
            src="/images/order.png"
            className="w-[70px]"
            alt="arrow"
          />
        </Button>
      ),
    },
    {
      title: "庫存數",
      dataIndex: "remaining",
      width: "7%",
      align: "center",
      sorter: (a, b) => {
        // Handle "unlimited" cases
        if (a.remaining === "0" && b.remaining === "0") return 0;
        if (a.remaining === "0") return 1;
        if (b.remaining === "0") return -1;
        return parseInt(a.remaining) - parseInt(b.remaining);
      },
      render: (remaining) => (remaining === "0" ? "無限" : remaining),
    },
    {
      title: "優惠",
      dataIndex: "memberDiscount",
      width: "6%",
      render: (memberDiscount, record) => {
        const discounts = [];
        if (memberDiscount && memberDiscount !== "無折扣") {
          discounts.push(`會員${memberDiscount}`);
        }
        if (record.earlyBirdDiscount && record.earlyBirdDiscount !== "無折扣") {
          discounts.push(`晚鳥${record.earlyBirdDiscount}`);
        }
        return discounts.length > 0 ? discounts.join("、") : "無折扣";
      },
    },
    {
      title: "標籤",
      dataIndex: "tags",
      width: "10%",
      render: (tags) => (
        <div className="flex flex-wrap gap-1">
          {(tags || []).map((tag) => (
            <Tag 
              key={tag} 
              className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]"
            >
              {tag}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "上架日期",
      dataIndex: "publishDate",
      width: "8%",
      align: "center",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
  ];

  const [selectedService, setSelectedService] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showOrderManagement, setShowOrderManagement] = useState(false);
  const [services, setServices] = useState(initialData);
  const [searchText, setSearchText] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("all");
  const [selectedStatus, setSelectedStatus] = useState("all");
  const totalServices = services.length;
  const availableServices = services.filter(
    (service) => service.status === "上架中"
  ).length;
  const lowStockWarnings = services.filter(
    (service) => parseInt(service.remaining) < 20 && service.remaining !== "0"
  ).length;
  const onPromotion = services.filter(
    (service) => parseFloat(service.memberDiscount) > 0
  ).length;
  const drafts = services.filter(
    (service) => service.status === "草稿夾"
  ).length;

  // Handle row click
  const onRowClick = (record) => {
    setSelectedService(record);
  };

  // Handle add service
  const handleAddService = (newService) => {
    const formValues = newService;
    let finalPrice = formValues.fixedPrice;
    let pricingTable = null;
    if (formValues.pricingMode === "tiered" && formValues.pricingTable) {
      const validPricing = formValues.pricingTable.filter(
        (item) => item.price && item.price.trim() !== ""
      );
      if (validPricing.length > 0) {
        finalPrice = validPricing[0].price;
        pricingTable = validPricing;
      }
    }

    const serviceWithPrice = {
      ...newService,
      key: (services.length + 1).toString(),
      price: finalPrice || 0,
      imageUrl: newService.imageUrl,
      pricingTable: pricingTable,
      pricingMode: formValues.pricingMode,
    };

    const updatedServices = [serviceWithPrice, ...services];
    setServices(updatedServices);
    setShowForm(false);
    setSelectedService(updatedServices[0]);
  };

  // Handle search
  const handleSearch = (value) => {
    setSearchText(value);
  };

  // Handle provider change
  const handleProviderChange = (value) => {
    setSelectedProvider(value);
  };

  // Handle status change
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  // Handle filtered services
  const filteredServices = services
    .filter((service) => {
      return (
        (searchText === "" ||
          service.name.toLowerCase().includes(searchText.toLowerCase())) &&
        (selectedProvider === "all" ||
          selectedProvider === null ||
          service.provider === selectedProvider) &&
        (selectedStatus === "all" ||
          selectedStatus === null ||
          service.status === selectedStatus)
      );
    })
    .sort((a, b) => {
      // If both have recommendation numbers, sort by number
      if (a.recommendation && b.recommendation) {
        return a.recommendation - b.recommendation;
      }
      // Items with recommendation numbers come first
      if (a.recommendation) return -1;
      if (b.recommendation) return 1;
      // Keep original order if neither has recommendation number
      return 0;
    });

  // Handle provider options
  const providerOptions = [
    { value: "all", label: "全部商家" },
    ...Array.from(new Set(services.map((service) => service.provider)))
      .filter(Boolean)
      .map((provider) => ({
        value: provider,
        label: provider,
      })),
  ];

  // Handle status options
  const statusOptions = [
    { value: "all", label: "全部狀態" },
    { value: "上架中", label: "上架中" },
    { value: "下架中", label: "下架中" },
  ];

  // Handle service detail
  const ServiceDetail = ({ service }) => {
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [currentPrice, setCurrentPrice] = useState(service.price);
    const [selectedDate, setSelectedDate] = useState(
      moment().format("YYYY-MM-DD")
    );
    const [selectedTime, setSelectedTime] = useState("15:00");
    const [selectedTimeButton, setSelectedTimeButton] = useState("15:00");
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [slideDirection, setSlideDirection] = useState('next');
    const timer = useRef(null);

    useEffect(() => {
      if (
        service.pricingMode === "tiered" &&
        Array.isArray(service.pricingTable)
      ) {
        const validPricing = service.pricingTable.filter(
          (item) => item.price && item.price.trim() !== ""
        );

        if (validPricing.length > 0) {
          const defaultSelected = validPricing[0];
          setSelectedDuration(defaultSelected.actualDuration);
          setCurrentPrice(defaultSelected.price);
        }
      } else {
        setCurrentPrice(service.price);
      }
    }, [service]);

    // Add auto-play carousel effect
    useEffect(() => {
      // Only auto-play when there are multiple images
      if (service.imageUrls?.length > 1) {
        timer.current = setInterval(() => {
          setSlideDirection('next');
          setCurrentImageIndex((prevIndex) =>
            prevIndex === service.imageUrls.length - 1 ? 0 : prevIndex + 1
          );
        }, 3000);

        // Cleanup timer
        return () => {
          if (timer.current) {
            clearInterval(timer.current);
          }
        };
      }
    }, [service.imageUrls]);

    // Handle dot click for manual navigation
    const handleDotClick = (index) => {
      setSlideDirection(index > currentImageIndex ? 'next' : 'prev');
      setCurrentImageIndex(index);
    };

    // Pause auto-play on mouse enter
    const handleMouseEnter = () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };

    // Resume auto-play on mouse leave
    const handleMouseLeave = () => {
      if (service.imageUrls?.length > 1) {
        timer.current = setInterval(() => {
          setSlideDirection('next');
          setCurrentImageIndex((prevIndex) =>
            prevIndex === service.imageUrls.length - 1 ? 0 : prevIndex + 1
          );
        }, 3000);
      }
    };

    const renderDurationSelection = () => {
      if (
        service.pricingMode !== "tiered" ||
        !Array.isArray(service.pricingTable) ||
        service.pricingTable.length === 0
      ) {
        return null;
      }

      return (
        <>
          <div className="bg-white p-4 rounded-lg sticky top-4">
            <h4 className="font-inter text-base font-black leading-[26px] tracking-[0.05em] text-left mb-3">選擇規格</h4>
            <h6 className="text-sm text-gray-600 mb-2">時長</h6>
            <div className="flex flex-wrap gap-2">
              {service.pricingTable.map((item) => (
                <Button
                  key={item.actualDuration}
                  className={`
                    min-w-[87px] h-[26px] px-4 mb-2 
                    text-black hover:!text-black
                    rounded-md rounded-none
                    flex items-center justify-center
                    ${selectedDuration === item.actualDuration ? 'bg-primary' : 'bg-white'}
                  `}
                  type={
                    selectedDuration === item.actualDuration
                      ? "primary"
                      : "default"
                  }
                  onClick={() => {
                    setSelectedDuration(item.actualDuration);
                    setCurrentPrice(item.price);
                  }}
                >
                  {item.actualDuration}
                </Button>
              ))}
            </div>
          </div>
          <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />
        </>
      );
    };

    const items = [
      {
        key: "1",
        label: "服務資訊",
        children: (
          <div className="flex-1 pb-20">
            <div className="flex flex-col bg-white shadow gap-1">
              {/* Top area */}
              <div className="p-4">
                <div className="relative mb-2">
                  {service.recommendation && service.recommendation >= 1 && service.recommendation <= 5 && (
                    <div className="absolute top-2 left-2 z-10">
                      <span className="text-[11px] bg-[#FF7171] text-white rounded-md h-[20px] w-[40px] inline-flex items-center justify-center">熱門</span>
                    </div>
                  )}

                  {/* Carousel image area */}
                  <div
                    className="relative overflow-hidden"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div
                      className={`relative w-full h-48 transition-transform duration-300 ease-in-out
                        ${slideDirection === 'next' ? 'translate-x-[-100%]' : 'translate-x-[100%]'}`}
                      style={{
                        transform: `translateX(${currentImageIndex * -100}%)`,
                      }}
                    >
                      {service.imageUrls?.length > 0 ? (
                        <div className="absolute flex w-full h-full">
                          {service.imageUrls.map((url, index) => (
                            <img
                              key={index}
                              src={url}
                              alt={`${service.name}-${index + 1}`}
                              className="w-full h-full object-cover flex-shrink-0"
                            />
                          ))}
                        </div>
                      ) : (
                        <img
                          src={service.imageUrl}
                          alt={service.name}
                          className="w-full h-full object-cover"
                        />
                      )}
                    </div>

                    {/* Carousel dot indicator */}
                    {service.imageUrls?.length > 1 && (
                      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex gap-2">
                        {service.imageUrls.map((_, index) => (
                          <div
                            key={index}
                            onClick={() => handleDotClick(index)}
                            className={`w-2 h-2 rounded-full cursor-pointer transition-all
                              ${currentImageIndex === index ? 'bg-[#D7AE71]' : 'bg-white'}`}
                          />
                        ))}
                      </div>
                    )}

                    {/* Page number indicator */}
                    {service.imageUrls?.length > 1 && (
                      <div className="absolute bottom-2 right-2 bg-black/50 text-white px-2 py-1 rounded text-xs">
                        {currentImageIndex + 1}/{service.imageUrls.length}
                      </div>
                    )}
                  </div>

                  <h3 className="text-lg font-bold mb-1">{service.name}</h3>
                  <p className="text-sm text-gray-600 mb-2">{service.provider}</p>
                  
                  {/* tags */}
                  {service.tags && service.tags.length > 0 && (
                    <div className="flex flex-wrap gap-1 mb-2">
                      {service.tags.map((tag) => (
                        <Tag key={tag} className="m-0 bg-[#F3E5D4] text-[#DFB782] border-[#DFB782]">
                          {tag}
                        </Tag>
                      ))}
                    </div>
                  )}

                  {service.description && (
                    <div className="">
                      <p className="text-sm text-gray-700">
                        {service.description}
                      </p>
                    </div>
                  )}
                  <div className="text-[24px] mb-2 text-primary flex items-baseline justify-end pb-2">
                    <span className="font-roboto text-2xl font-medium leading-[26px] tracking-[-0.9px] text-right">
                      $ {Number(currentPrice).toLocaleString()}
                    </span>
                    <span className="text-xl text-black ml-1 align-baseline">起</span>
                  </div>
                </div>
                <Divider className="border-b-[5px] border-[#F0F0F0] mx-4 my-2" />

                {/* selection area */}
                {renderDurationSelection()}

                {/* Reservation time selection area */}
                <div className=" p-2">
                  <h4 className="text-base font-black mb-3">選擇預約時段</h4>
                  <div className="grid grid-cols-2 gap-4 mb-4">
                    <div>
                      <div className="text-sm mb-2">日期</div>
                      <div className="w-full h-[32px] border rounded px-3 py-1 bg-gray-50">
                        {selectedDate}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm mb-2">時間</div>
                      <div className="w-full h-[32px]  border rounded px-3 py-1 bg-gray-50">
                        {selectedTime}
                      </div>
                    </div>
                  </div>

                  {/* 9-grid time selection */}
                  <div className="grid grid-cols-3 gap-2">
                    {[
                      "09:00",
                      "10:00",
                      "11:00",
                      "14:00",
                      "15:00",
                      "16:00",
                      "17:00",
                      "18:00",
                      "19:00",
                    ].map((time) => (
                      <div
                        key={time}
                        className={`text-center py-2 text-black hover:!text-black rounded-[8px] ${time === selectedTimeButton
                          ? "bg-primary"
                          : "bg-gray-200"
                          } min-w-[80px] mb-2 rounded`}
                      >
                        {time}
                      </div>
                    ))}
                  </div>
                </div>

                {/* Notice area */}
                {service.serviceNotice && (
                  <div className="py-4">
                    <h4 className="text-base font-bold mb-2">注意事項</h4>
                    <p className="text-sm text-gray-700">
                      {service.serviceNotice}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ),
      },
      // {
      //   key: '2',
      //   label: '預約設定',
      //   children: '預約設定內容',
      // },
      // {
      //   key: '3',
      //   label: '銷售統計',
      //   children: '銷售統計內容',
      // },
    ];

    return (
      <>
        <div className="flex flex-col h-full relative">
          <div className="flex-1">
            <Tabs
              defaultActiveKey="1"
              items={items}
              className="flex-1 [&_.ant-tabs-content-holder]:overflow-auto [&_.ant-tabs-nav]:px-4 [&_.ant-tabs-nav]:pt-4"
            />
          </div>
        </div>
      </>
    );
  };

  // Handle loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  // set default selected service
  useEffect(() => {
    if (!loading && services.length > 0 && !selectedService) {
      setSelectedService(services[0]);
    }
  }, [loading, services]);

  // handle show form
  const handleShowForm = () => {
    setShowForm(true);
    setSelectedService(null);
  };

  // Update recommendation value to start from 1
  useEffect(() => {
    if (services.length > 0) {
      const updatedServices = services.map((service, index) => ({
        ...service,
        recommendation: index < 5 ? index + 1 : null
      }));
      setServices(updatedServices);
    }
  }, []); // Only run on component mount

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    );
  }

  if (showOrderManagement) {
    return (
      <OrderManagement
        service={selectedService}
        onBack={() => setShowOrderManagement(false)}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-start w-full h-screen overflow-auto">
        <ToolBar pure={true} />
        <div className="flex flex-col w-full h-[calc(100vh-64px)]">
          <div className="flex-1 flex overflow-auto">
            {showForm ? (
              <div className="flex w-full space-x-4 overflow-auto">
                <div className="flex-1 overflow-auto">
                  <AddServiceForm
                    onSave={handleAddService}
                    services={services}
                  />
                </div>
              </div>
            ) : (
              <div className="flex w-full overflow-auto bg-gray-50">

                {/* left table area */}
                <div className="flex-1 flex flex-col shadow min-w-0 overflow-auto h-full p-4">

                  <div className="flex justify-between items-center">
                    <div className="text-lg font-bold">服務管理</div>
                    <Button
                      type="primary"
                      className="bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                      icon={<PlusOutlined />}
                      onClick={handleShowForm}
                    >
                      新增服務
                    </Button>
                  </div>

                  {/* Statistics information */}
                  <div className="grid grid-cols-5 gap-4 mb-4">
                    <div className="col-span-2 bg-[#F3F3F3] rounded-lg shadow">
                      <div className="h-[36px] pt-2 pl-2">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.8492 12.11C16.8492 15.0917 15.0917 16.8492 12.11 16.8492H5.625C2.63583 16.8492 0.875 15.0917 0.875 12.11V5.61C0.875 2.6325 1.97 0.875 4.9525 0.875H6.61917C7.2175 0.875833 7.78083 1.15667 8.13917 1.63583L8.9 2.6475C9.26 3.12583 9.82333 3.4075 10.4217 3.40833H12.78C15.7692 3.40833 16.8725 4.93 16.8725 7.9725L16.8492 12.11Z"
                            stroke="#3E3D3D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.23438 11.0524H12.5135"
                            stroke="#3E3D3D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">總服務數</div>
                          <div className="text-2xl font-bold">
                            {totalServices}
                          </div>
                        </div>
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">可用服務數</div>
                          <div className="text-2xl font-bold">
                            {availableServices}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-span-3 p-1 rounded-lg ">
                      <div className="bg-[#FFCC9129] rounded-lg w-[36px] h-[36px] inline-flex items-center justify-center">
                        <svg 
                          width="17" 
                          height="18" 
                          viewBox="0 0 17 18" 
                          fill="none" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M6.49268 11.6724C9.56685 11.6724 12.1943 12.1382 12.1943 13.999C12.1943 15.8599 9.58435 16.339 6.49268 16.339C3.41768 16.339 0.791016 15.8774 0.791016 14.0157C0.791016 12.154 3.40018 11.6724 6.49268 11.6724Z" 
                            stroke="rgb(19, 15, 38)"
                            strokeWidth="1.5" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                          />
                          <path 
                            fillRule="evenodd" 
                            clipRule="evenodd" 
                            d="M6.49289 9.01634C4.47456 9.01634 2.83789 7.38051 2.83789 5.36217C2.83789 3.34384 4.47456 1.70801 6.49289 1.70801C8.51039 1.70801 10.1471 3.34384 10.1471 5.36217C10.1546 7.37301 8.52956 9.00884 6.51872 9.01634H6.49289Z" 
                            stroke="rgb(19, 15, 38)"
                            strokeWidth="1.5" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                          />
                          <path 
                            d="M12.2358 8.0679C13.57 7.8804 14.5975 6.7354 14.6 5.34956C14.6 3.98373 13.6042 2.8504 12.2983 2.63623" 
                            stroke="rgb(19, 15, 38)"
                            strokeWidth="1.5" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                          />
                          <path 
                            d="M13.9961 11.2769C15.2886 11.4694 16.1911 11.9227 16.1911 12.856C16.1911 13.4985 15.7661 13.9152 15.0794 14.176" 
                            stroke="rgb(19, 15, 38)"
                            strokeWidth="1.5" 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                          />
                        </svg>
                      </div>
                      <div className="grid grid-cols-3 gap-2">
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-red-500 text-sm">低庫存警告</div>
                          <div className="text-2xl font-bold">
                            {lowStockWarnings}
                          </div>
                        </div>
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">優惠中</div>
                          <div className="text-2xl font-bold">
                            {onPromotion}
                          </div>
                        </div>
                        <div className="p-2 text-start rounded-lg">
                          <div className="text-sm">草數量</div>
                          <div className="text-2xl font-bold">{drafts}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex space-x-4 mb-4">
                    <Input
                      className="w-80"
                      placeholder="搜尋"
                      prefix={<SearchOutlined />}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <Select
                      className="w-40"
                      placeholder="上架商家"
                      options={providerOptions}
                      onChange={handleProviderChange}
                      allowClear
                      value={selectedProvider}
                    />
                    <Select
                      className="w-40"
                      placeholder="前狀態"
                      options={statusOptions}
                      onChange={handleStatusChange}
                      allowClear
                      value={selectedStatus}
                    />
                  </div>

                  <div className="flex-1 overflow-auto h-full">
                    <Table
                      style={{
                        height: "100%",
                      }}
                      columns={columns}
                      dataSource={filteredServices}
                      pagination={{
                        pageSize: 10,
                        className: "sticky bottom-0 pb-2"
                      }}
                      onRow={(record) => ({
                        onClick: () => onRowClick(record),
                        className:
                          record.key === selectedService?.key
                            ? "bg-gray-100"
                            : "",
                      })}
                      rowClassName={(record) =>
                        `cursor-pointer ${record.key === selectedService?.key
                          ? "bg-gray-100"
                          : ""
                        }`
                      }
                      scroll={{ x: 1950 }}
                      className="[&_.ant-table-thead>tr>th]:bg-[#E0F0E0] h-full"
                      size="small"
                    />
                  </div>
                </div>

                {/* right preview area */}
                <div className="w-[440px] flex-shrink-0 border-l border-t border-gray-200 shadow overflow-auto">
                  <div>
                    {selectedService && (
                      <ServiceDetail service={selectedService} className="pl-6" />
                    )}
                  </div>
                  <div className="fixed w-[440px] bottom-0 bg-white border shadow-3xl p-4">
                    <Button
                      type="primary"
                      className="w-full h-10 bg-[#ADDEAC] hover:bg-[#45a049] text-black hover:!text-black"
                    >
                      編輯服務
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
