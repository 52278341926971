import React, { useState, useEffect } from 'react';
import { request } from '@/utils';
import { useBound } from '@/contexts/BoundContext';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { Spin, Tooltip, Switch } from 'antd';

const AirbnbSetting = (client) => {
  const { roomId, messageId, orderAirbnbMessage } = useBound();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  useEffect(() => {
    console.log('orderData', orderData);
  }, [orderData]);
    

  useEffect(() => {
    setLoading(true);
    setOrderData(null);
    if (client.client.channel === "airbnb") {
      // filter out data with matching messageId and client.messageId
      const filteredOrders = orderAirbnbMessage.filter(
        (item) => item.message_id === client.client.messageId
      );

      // flatten each order after filtering
      const ordersObject = filteredOrders.reduce((acc, order) => {
        return { ...acc, ...order }; // flatten each order's properties
      }, {});

      // store the flattened object in orderData state
      setOrderData(ordersObject);
      setLoading(false);
    }
  }, [client, orderAirbnbMessage]);

  // POST request to fetch order info
  const postOrder = () => request.post(
    `/private/module/rpa/airbnb/order_info/${roomId}`,
    {},
    {
      params: { message_id: messageId },
    }
  );

  // GET request to fetch order info
  const getOrder = () => request.get(`/private/module/rpa/airbnb/order_info/${roomId}`, {
    params: { message_id: messageId },
  });

  // Fetch order info
  const fetchOrderInfo = async () => {
    setLoading(true);
    try {
      console.log("Attempting POST as recovery");
      await postOrder();
      await delay(2000);  // Adding delay before retrying
    } catch (postError) {
      console.error("POST request failed as well:", postError);
    }
    await delay(2000);  // Adding delay before retrying

    try {
      let getResponse = await getOrder();
      // Handle failed response
      if (getResponse.status === "failed") {
        console.log("Initial GET failed, attempting to POST");
        try {
          await delay(2000);
          await postOrder();
        } catch (error) {
          await delay(5000);
          getResponse = await getOrder();
        }
      }
      if (getResponse.status === "pending") {
        console.log("Initial GET pending, attempting to POST");
        try {
          await delay(2000);
          await postOrder();
        } catch (error) {
          await delay(5000);
          getResponse = await getOrder();
        }
      }
      setOrderData(getResponse);  // Update order data
    } catch (error) {
      console.error("Error fetching order info:", error);  // Log errors for visibility
      try {
        console.log("Attempting POST as recovery");
        await postOrder();
        await delay(2000);  // Adding delay before retrying
        let getResponse = await getOrder();
        if (getResponse.status === "pending") {
          console.log("Initial GET pending, attempting to POST");
          try {
            await delay(2000);
            await postOrder();
          } catch (error) {
            await delay(2000);
            getResponse = await getOrder();
          }
          await delay(2000);  // Adding delay before retrying
          getResponse = await getOrder();
        }
        setOrderData(getResponse);  // Set response if POST and retry were successful
      } catch (postError) {
        console.error("POST request failed as well:", postError);
      }
    } finally {
      setLoading(false);  // Always stop loading
    }
  };

  return (
    <>
      <div className="p-3 mr-3 overflow-y-auto h-full">
        {true && (
          <>
            <div className="flex justify-between font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
              AI 個別狀態設定
            </div>
            <div className="flex flex-col border border-borderLight rounded bg-bgMedium w-full">
              <div className="flex flex-col items-start w-full h-22 p-4 gap-2">
                <div className="font-bold">AI 狀態</div>
                <div className="flex flex-row justify-between w-full items-center">
                  <div>針對此客戶啟用 AI</div>
                  <Tooltip title="將自動傳送狀態變更訊息給客戶">
                    <Switch
                    // value={useWebhook}
                    // onChange={(e) =>
                    //   handleUpdateProperty("useWebhook", e)
                    // }
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="flex justify-between items-center font-inter text-[17px] font-semibold leading-[22.1px] text-left text-[#3E3D3D] w-full my-3">
          <span>預訂詳情</span>
          <ReloadOutlined
          className="hover:text-primary mr-2 flex items-center"
          style={{ width: "12px", height: "12px" }}
          onClick={() => fetchOrderInfo()}
        />
        </div>

        <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
          {
            loading ?
              <div className="flex justify-center items-center h-[600px]">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
              :
              <>
              <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">預訂人訊息</div>
                  </div>
                  {orderData?.result?.customer_info?.map((info, index) => (
                  <div key={index} className={`flex flex-col items-start w-full h-22 p-4 gap-2 ${index !== orderData.result.customer_info.length - 1 ? 'border-b border-b-borderLight' : ''}`}>
                    <div className="text-[14px] font-[400]">{info || '查無相關資料'}</div>
                  </div>
                ))}
              </>
          }
        </div>
        
        <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
          {
            loading ?
              <div className="flex justify-center items-center h-[600px]">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
              :
              <>
                <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">訂單摘要</div>
                  </div>
                  {orderData?.result?.order_summary?.map((summary, index) => (
                    <div 
                      key={index} 
                      className={`flex flex-col items-start w-full h-22 p-4 gap-2 ${index !== orderData.result.order_summary.length - 1 ? 'border-b border-b-borderLight' : ''}`}
                    >
                      <div className="text-[14px] font-[400]">{summary || '查無相關資料'}</div>
                    </div>
                  ))}
              </>
          }
        </div>

        <div className="flex flex-col border border-borderLight mb-3 rounded bg-bgMedium w-full rounded-[11px]">
          {
            loading ?
              <div className="flex justify-center items-center h-[600px]">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
              </div>
              :
              <>
              <div className="flex flex-col items-start w-full h-10 p-4 gap-2">
                <div className="font-bold">預訂資訊</div>
                  </div>
                {orderData?.result?.order_details?.map((detail, index) => (
                  <div key={index} className={`flex flex-col items-start w-full h-22 p-4 gap-2 ${index !== orderData.result.order_details.length - 1 ? 'border-b border-b-borderLight' : ''}`}>
                    <div className="font-[500]">{detail[0] || '查無相關資料'}
                      {/* <span className="text-xs text-bgMedium">{" "}{orderData.last_update?.split('.')[0]}</span> */}
                    </div>
                    <div className="text-[14px] font-[400]">{detail[1] || '查無相關資料'}</div>
                  </div>
                ))}
              </>
          }
        </div>
      </div>
    </>
  );
}

export default AirbnbSetting;
