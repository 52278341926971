import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'

import { useAuth } from '@/contexts/AuthProvider'

import ChatList from './components/list/ChatList'
import ChatInterface from './components/ChatInterface'
import CreateChat from './components/list/CreateChat'

import { LoadingOutlined } from '@ant-design/icons';
import { Spin, message } from 'antd';
import ToolBar from './components/ToolBar';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Chat = () => {
  let { roomId } = useParams();
  const { userId, token } = useAuth();
  const navigate = useNavigate()

  const [chatList, setChatList] = useState([])
  const [activeRoomId, setActiveRoomId] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchRooms()
  }, [userId])

  const fetchRooms = async () => {
    setLoading(true)
    try {
      const response = await axios.get(SERVER_URL + '/private/user/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data.joined_rooms.length === 0) {
        setChatList([])
        setLoading(false)
        message.info("尚未加入任何聊天室")
        return
      }

      const idList = response.data.joined_rooms

      let department_chatrooms = await axios.get(SERVER_URL + '/private/chatrooms/list/group/my', {
        headers: { Accept: 'application/json', Authorization: `Bearer ${token}` }
      }).then((response) => {
        return response.data.flatMap(group => group.chatrooms);
      });

      // Filter out the department chatrooms if exist in idList
      let tempChatList = department_chatrooms.filter(chatroom => idList.includes(chatroom.id));

      // check if roomid is in tempchatlist
      if (roomId && tempChatList.filter(x => x.id === roomId).length > 0) {
        setActiveRoomId(roomId)
        navigate(`/chat/${roomId}`)
      } else {
        setActiveRoomId(null)
        navigate(`/chat`)
      }

      setChatList(tempChatList)
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    }
    setLoading(false)
  }

  const changeActiveRoomId = (id) => {
    setActiveRoomId(id)
    navigate(`/chat/${id}`)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-screen">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  return (
    <div className="flex flex-row w-screen h-screen">
      <CreateChat token={token} isModalOpen={isModalOpen} closeModal={closeModal} />
      <ChatList
        userId={userId}
        activeRoomId={activeRoomId}
        chatList={chatList}
        changeActiveRoom={changeActiveRoomId}
        openModal={openModal}
      />
      {
        activeRoomId && chatList.length > 0 ?
          <ChatInterface id={activeRoomId} room={chatList.filter(x => x.id === activeRoomId)[0]} refresh={fetchRooms} />
          :
          <div className="flex flex-col items-center justify-start w-full h-screen">
            <ToolBar pure={true} />
            <div className="text-center my-auto text-2xl font-bold text-textLight">
              請選擇聊天室
            </div>
          </div>
      }
    </div>
  )
}

export default Chat
