import axios from 'axios';
import { message } from 'antd';

export const fetchLineClients = async (SERVER_URL, room, token, setClients, setListLoading, channelType, chatType) => {
  setListLoading(true);
  try {
    const response = await axios.get(`${SERVER_URL}/private/property/chatroom/line_channel/${room.id}/clients`, {
      params: {
        channel_type: `${channelType}`,
        chat_type: `${chatType}`
      },
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    // check if there's a null property in one element of the response.data.valid
    if (response.data.valid.length > 0 && response.data.valid[0].displayName === null) {
      message.error('Line@ 帳號可能已經串接至其他聊天室，請至 Line@ 後台檢查。');
      return;
    }
    setClients(response.data.valid);
  } catch (error) {
    console.error(error);
  } finally {
    setListLoading(false);
  }
};