import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Input, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { useAuth } from '@/contexts/AuthProvider';

const PasswordWindow = ({ roomId, onClose }) => {
  const [password, setPassword] = useState('');
  const { token } = useAuth();
  const navigate = useNavigate();

  const SERVER_URL = process.env.REACT_APP_SERVER_URL;

  const handlechatRoomPassword = (e) => {
    setPassword(e.target.value);
  };
  const handleSubmit = async () => {
    try {
      await axios.post(`${SERVER_URL}/private/chatrooms/join/${roomId}`,
        { text: password }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      // const data = response.data;
      // console.log('data:::::', data);
      // if (response.status === 400 && data.detail === 'Invalid passphrase') {
      //   message.error('password is wrong');
      //   throw new Error('password is wrong');
      // }
      // if (response.status === 200) {
      //   navigate(`/chat/${roomId}`);
      // }
      navigate(`/chat/${roomId}`);
      message.success('成功加入新私人聊天室');
    } catch (error) {
      if (error.response.data.detail === 'Invalid passphrase') {
        message.error('密碼錯誤');
        return
      }
      message.error(JSON.stringify(error.response?.data?.detail));
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20 z-50">
      <div className='w-[340px] h-[204px] flex flex-col bg-[#F2F2F2] justify-between rounded-[7px] overflow-hidden'>
        <div className='w-full flex flex-col items-start p-2'>
          <button className='flex justify-end w-full'
            onClick={onClose}><CloseOutlined /></button>
          <div className='w-full p-4'>
            <p className='flex justify-start w-full mb-2 font-semibold text-[17px]'>
              請輸入房間密碼
            </p>
            <Input className='w-full rounded-[5px] '
              value={password}
              onChange={handlechatRoomPassword} />
          </div>
        </div>
        <div className='w-full bg-[#ADDEAC] flex justify-center ' >
          <button className='w-full h-10' onClick={handleSubmit}>確定</button>
        </div>
      </div>
    </div>
  )
}

export default PasswordWindow;