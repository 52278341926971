export const initialData = [
  {
    key: '1',
    ticketId: 'TK001',
    name: 'HAPPY SPA',
    type: '上架中',
    value: '審核通過',
    ticketName: '春季美容護理券',
    price: 'NT$500',
    inventory: 100,
    usedCount: 25,
    exchangeInfo: '可折抵美容服務',
    conditions: '需提前預約',
    validPeriod: '2024-05-31',
    tags: ['限時優惠', '新客優惠'],
    publishDate: '2024-01-01',
    imageUrl: '/images/merchant1.png',
    recommendation: 1,
  },
  {
    key: '2',
    ticketId: 'TK002',
    name: '美甲工作室',
    type: '上架中',
    value: '審核通過',
    ticketName: '美甲服務券',
    price: 'NT$299',
    inventory: 50,
    usedCount: 15,
    exchangeInfo: '可折抵美甲服務',
    conditions: '平日使用',
    validPeriod: '2024-12-31',
    tags: ['新客優惠'],
    publishDate: '2024-01-15',
    imageUrl: '/images/merchant2.png',
    recommendation: 2,
  },
  {
    key: '3',
    ticketId: 'TK003',
    name: '髮廊 Hair Salon',
    type: '下架中',
    value: '待審核',
    ticketName: '剪髮造型券',
    price: 'NT$399',
    inventory: 0,
    usedCount: 30,
    exchangeInfo: '可折抵剪髮服務',
    conditions: '不限使用時段',
    validPeriod: '2024-06-30',
    tags: ['限量優惠'],
    publishDate: '2024-02-01',
    imageUrl: '/images/merchant3.png',
    recommendation: 3,
  },
  {
    key: '4',
    ticketId: 'TK004',
    name: '美睫工作室',
    type: '上架中',
    value: '審核通過',
    ticketName: '美睫服務券',
    price: 'NT$799',
    inventory: 75,
    usedCount: 10,
    exchangeInfo: '可折抵美睫服務',
    conditions: '需提前3天預約',
    validPeriod: '2024-08-31',
    tags: ['會員專屬'],
    publishDate: '2024-02-15',
    imageUrl: '/images/merchant4.png',
    recommendation: 4,
  },
  {
    key: '5',
    ticketId: 'TK005',
    name: '美容中心',
    type: '上架中',
    value: '審核通過',
    ticketName: '臉部保養券',
    price: 'NT$999',
    inventory: 200,
    usedCount: 45,
    exchangeInfo: '可折抵保養服務',
    conditions: '限女性使用',
    validPeriod: '2024-07-31',
    tags: ['熱門商品'],
    publishDate: '2024-03-01',
    imageUrl: '/images/merchant5.png',
    recommendation: 5,
  },
  {
    key: '6',
    ticketId: 'TK006',
    name: '東方養生館',
    type: '上架中',
    value: '審核通過',
    ticketName: '全身按摩券',
    price: 'NT$1200',
    inventory: 150,
    usedCount: 35,
    exchangeInfo: '可折抵按摩服務',
    conditions: '需提前預約',
    validPeriod: '2024-09-30',
    tags: ['人氣推薦', '限時優惠'],
    publishDate: '2024-03-05',
    imageUrl: '/images/merchant6.png',
    recommendation: 6,
  },
  {
    key: '7',
    ticketId: 'TK007',
    name: '美顏工作室',
    type: '下架中',
    value: '待審核',
    ticketName: '美白護理券',
    price: 'NT$1500',
    inventory: 80,
    usedCount: 20,
    exchangeInfo: '可折抵美白療程',
    conditions: '首次體驗限定',
    validPeriod: '2024-10-31',
    tags: ['新客專屬'],
    publishDate: '2024-03-10',
    imageUrl: '/images/merchant7.png',
    recommendation: 7,
  },
  {
    key: '8',
    ticketId: 'TK008',
    name: '時尚美妝屋',
    type: '上架中',
    value: '審核通過',
    ticketName: '彩妝服務券',
    price: 'NT$800',
    inventory: 120,
    usedCount: 40,
    exchangeInfo: '可折抵彩妝服務',
    conditions: '週末使用加價NT$100',
    validPeriod: '2024-11-30',
    tags: ['節日優惠'],
    publishDate: '2024-03-15',
    imageUrl: '/images/merchant8.png',
    recommendation: 8,
  },
  {
    key: '9',
    ticketId: 'TK009',
    name: '纖體美容院',
    type: '上架中',
    value: '審核通過',
    ticketName: '纖體護理券',
    price: 'NT$2000',
    inventory: 60,
    usedCount: 15,
    exchangeInfo: '可折抵纖體療程',
    conditions: '需連續使用3次',
    validPeriod: '2024-12-15',
    tags: ['限量特惠', '熱門商品'],
    publishDate: '2024-03-20',
    imageUrl: '/images/merchant9.png',
    recommendation: 9,
  },
  {
    key: '10',
    ticketId: 'TK010',
    name: '專業美睫店',
    type: '上架中',
    value: '待審核',
    ticketName: '睫毛嫁接券',
    price: 'NT$1800',
    inventory: 40,
    usedCount: 28,
    exchangeInfo: '可折抵睫毛嫁接',
    conditions: '需提前一週預約',
    validPeriod: '2024-09-15',
    tags: ['VIP專屬'],
    publishDate: '2024-03-25',
    imageUrl: '/images/merchant10.png',
    recommendation: 10,
  },
  {
    key: '11',
    ticketId: 'TK011',
    name: '沙龍美髮',
    type: '下架中',
    value: '審核通過',
    ticketName: '染髮優惠券',
    price: 'NT$1600',
    inventory: 90,
    usedCount: 32,
    exchangeInfo: '可折抵染髮服務',
    conditions: '不含漂髮服務',
    validPeriod: '2024-08-31',
    tags: ['季節限定'],
    publishDate: '2024-03-28',
    imageUrl: '/images/merchant11.png',
    recommendation: 11,
  },
  {
    key: '12',
    ticketId: 'TK012',
    name: '美甲天地',
    type: '上架中',
    value: '審核通過',
    ticketName: '手足護理券',
    price: 'NT$699',
    inventory: 150,
    usedCount: 45,
    exchangeInfo: '可折抵手足保養',
    conditions: '平日使用優惠',
    validPeriod: '2024-07-31',
    tags: ['暑期特惠'],
    publishDate: '2024-04-01',
    imageUrl: '/images/merchant12.png',
    recommendation: 12,
  },
  {
    key: '13',
    ticketId: 'TK013',
    name: '芳療生活館',
    type: '上架中',
    value: '待審核',
    ticketName: '精油按摩券',
    price: 'NT$2500',
    inventory: 30,
    usedCount: 18,
    exchangeInfo: '可折抵精油按摩',
    conditions: '需提前兩天預約',
    validPeriod: '2024-10-15',
    tags: ['高級享受', '限量優惠'],
    publishDate: '2024-04-05',
    imageUrl: '/images/merchant13.png',
    recommendation: 13,
  },
  {
    key: '14',
    ticketId: 'TK014',
    name: '美體沙龍',
    type: '上架中',
    value: '審核通過',
    ticketName: '身體護理券',
    price: 'NT$1300',
    inventory: 100,
    usedCount: 55,
    exchangeInfo: '可折抵身體護理',
    conditions: '限女性使用',
    validPeriod: '2024-11-30',
    tags: ['人氣商品'],
    publishDate: '2024-04-10',
    imageUrl: '/images/merchant14.png',
    recommendation: 14,
  },
  {
    key: '15',
    ticketId: 'TK015',
    name: '美顏世界',
    type: '下架中',
    value: '審核通過',
    ticketName: '醫美護理券',
    price: 'NT$3000',
    inventory: 50,
    usedCount: 22,
    exchangeInfo: '可折抵醫美項目',
    conditions: '需先諮詢評估',
    validPeriod: '2024-12-31',
    tags: ['精品服務'],
    publishDate: '2024-04-15',
    imageUrl: '/images/merchant15.png',
    recommendation: 15,
  },
  {
    key: '16',
    ticketId: 'TK016',
    name: '美肌中心',
    type: '上架中',
    value: '待審核',
    ticketName: '肌膚保養券',
    price: 'NT$1100',
    inventory: 180,
    usedCount: 65,
    exchangeInfo: '可折抵保養項目',
    conditions: '不限使用時段',
    validPeriod: '2024-09-30',
    tags: ['基礎護理'],
    publishDate: '2024-04-20',
    imageUrl: '/images/merchant16.png',
    recommendation: 16,
  },
  {
    key: '17',
    ticketId: 'TK017',
    name: '美容美體館',
    type: '上架中',
    value: '審核通過',
    ticketName: '美體雕塑券',
    price: 'NT$2200',
    inventory: 70,
    usedCount: 30,
    exchangeInfo: '可折抵美體項目',
    conditions: '需做體態評估',
    validPeriod: '2024-08-15',
    tags: ['塑身優惠'],
    publishDate: '2024-04-25',
    imageUrl: '/images/merchant17.png',
    recommendation: 17,
  },
  {
    key: '18',
    ticketId: 'TK018',
    name: '美髮沙龍',
    type: '上架中',
    value: '審核通過',
    ticketName: '護髮療程券',
    price: 'NT$1400',
    inventory: 120,
    usedCount: 48,
    exchangeInfo: '可折抵護髮療程',
    conditions: '限長髮使用',
    validPeriod: '2024-07-31',
    tags: ['髮質保養'],
    publishDate: '2024-05-01',
    imageUrl: '/images/merchant18.png',
    recommendation: 18,
  },
  {
    key: '19',
    ticketId: 'TK019',
    name: '美睫天后',
    type: '下架中',
    value: '待審核',
    ticketName: '睫毛保養券',
    price: 'NT$900',
    inventory: 85,
    usedCount: 38,
    exchangeInfo: '可折抵睫毛保養',
    conditions: '需先卸除舊睫毛',
    validPeriod: '2024-10-31',
    tags: ['美睫護理'],
    publishDate: '2024-05-05',
    imageUrl: '/images/merchant19.png',
    recommendation: 19,
  },
  {
    key: '20',
    ticketId: 'TK020',
    name: '養生美容院',
    type: '上架中',
    value: '審核通過',
    ticketName: '養生護理券',
    price: 'NT$1700',
    inventory: 95,
    usedCount: 42,
    exchangeInfo: '可折抵養生項目',
    conditions: '需提前預約',
    validPeriod: '2024-11-15',
    tags: ['養生保健'],
    publishDate: '2024-05-10',
    imageUrl: '/images/merchant20.png',
    recommendation: 20,
  }
]; 