import React, { useState, useEffect } from "react";
import { Input, Radio, Table, Switch, Button, Form, Checkbox } from "antd";
import "tailwindcss/tailwind.css";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

const ServicePricing = ({ form }) => {
  const [isFixedPrice, setIsFixedPrice] = useState(true);

  const handlePricingModeChange = (e) => {
    const isFixed = e.target.value === "fixed";
    setIsFixedPrice(isFixed);
    
    // clear related fields
    if (isFixed) {
      form.setFieldsValue({
        pricingTable: undefined
      });
    } else {
      form.setFieldsValue({
        fixedPrice: undefined
      });
    }
  };

  // define initial table data
  const initialData = [
    { 
      key: "1", 
      actualDuration: "60分鐘", 
      backendDuration: "90分鐘", 
      price: "1800", 
      selected: true,
      discount: false,
      discountPrice: "1800", 
      earlyBird: false,
      earlyBirdPrice: "1800" 
    },
    { key: "2", actualDuration: "90分鐘", backendDuration: "120分鐘", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
    { key: "3", actualDuration: "120分鐘", backendDuration: "150分鐘", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
    { key: "4", actualDuration: "150分鐘", backendDuration: "180分鐘", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
    { key: "5", actualDuration: "180分鐘", backendDuration: "210分鐘", price: "", discount: false, discountPrice: "", earlyBird: false, earlyBirdPrice: "" },
  ];

  useEffect(() => {
    // set initial table data
    if (!isFixedPrice) {
      form.setFieldsValue({
        pricingTable: initialData
      });
    }
  }, [isFixedPrice]);

  // modify function to handle price change
  const handlePriceChange = (record, value) => {
    const currentTable = form.getFieldValue('pricingTable') || initialData;
    const updatedTable = currentTable.map(item => {
      if (item.key === record.key) {
        return { 
          ...item, 
          price: value,
          // update discount price and early bird price
          discountPrice: value,
          earlyBirdPrice: value
        };
      }
      return item;
    });
    form.setFieldsValue({ pricingTable: updatedTable });
  };

  // modify table column definition
  const columns = [
    {
      title: "課程實際時長",
      dataIndex: "actualDuration",
      key: "actualDuration",
    },
    {
      title: "後台設定時長（含緩衝時間）",
      dataIndex: "backendDuration",
      key: "backendDuration",
    },
    {
      title: "價格",
      dataIndex: "price",
      key: "price",
      render: (text, record) => (
        <Form.Item
          name={['pricingTable', record.key - 1, 'price']}
          noStyle
        >
          <Input 
            prefix="$" 
            className="w-24"
            onChange={(e) => {
              const validValue = validateNumberInput(e);
              handlePriceChange(record, validValue);
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "折扣",
      dataIndex: "discount",
      key: "discount",
      render: (_, record) => (
        <Checkbox defaultChecked={false} />
      ),
    },
    {
      title: "折扣價",
      dataIndex: "discountPrice",
      key: "discountPrice",
      render: (_, record) => (
        <Input 
          defaultValue={record.discountPrice} 
          prefix="$" 
          className="w-24"
          onChange={(e) => {
            const validValue = validateNumberInput(e);
            e.target.value = validValue;
          }}
        />
      ),
    },
    {
      title: "晚鳥優惠",
      dataIndex: "earlyBird",
      key: "earlyBird",
      render: (_, record) => (
        <Switch defaultChecked={false} />
      ),
    },
    {
      title: "晚鳥優惠價",
      dataIndex: "earlyBirdPrice",
      key: "earlyBirdPrice",
      render: (_, record) => (
        <Input 
          defaultValue={record.earlyBirdPrice} 
          prefix="$" 
          className="w-24"
          onChange={(e) => {
            const validValue = validateNumberInput(e);
            e.target.value = validValue;
          }}
        />
      ),
    },
    {
      title: "優惠期限",
      dataIndex: "promoDuration",
      key: "promoDuration",
      render: () => <span>無限制</span>,
    },
  ];

  // add number input validation function
  const validateNumberInput = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      return value;
    }
    return e.target.value.replace(/\D/g, '');
  };

  return (
    <div className="bg-white">
      {/* Pricing mode */}
      <Form.Item name="pricingMode" initialValue="fixed">
        <Radio.Group
          onChange={handlePricingModeChange}
          value={isFixedPrice ? "fixed" : "tiered"}
          className="flex gap-8 mb-6"
        >
          <Radio value="fixed" className="flex items-center">
            固定金額
          </Radio>
          <Radio value="tiered" className="flex items-center">
            規格金額
          </Radio>
        </Radio.Group>
      </Form.Item>

      {isFixedPrice ? (
        <Form.Item 
          label="輸入固定金額" 
          name="fixedPrice"
          rules={[{ 
            required: true, 
            message: '請輸入固定金額',
            type: 'number',
            transform: (value) => Number(value)
          }]}
        >
          <Input 
            prefix="$" 
            className="w-1/5 bg-gray-100" 
            placeholder="請輸入金額"
            onChange={(e) => {
              const validValue = validateNumberInput(e);
              form.setFieldsValue({ fixedPrice: validValue });
            }}
          />
        </Form.Item>
      ) : (
        <>
          <div className="mb-6">
            <p className="text-gray-500 text-sm mb-2">至下方完成規格金額設定</p>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item name="specName" className="mb-0">
                <Input
                  placeholder="例如：時長、車型"
                  className="w-full"
                  disabled
                />
              </Form.Item>
              <div className="flex items-center gap-4">
                <Form.Item name="specOptions" className="mb-0 flex-1">
                  <Input
                    placeholder="例如：1小時、2小時"
                    className="w-full"
                    disabled
                  />
                </Form.Item>
                <button className="text-gray-400 hover:text-gray-600">
                  <CloseOutlined />
                </button>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <div className="text-sm mb-2">規格二</div>
            <button className="border border-dashed border-green-500 text-green-500 px-4 py-2 rounded-md hover:bg-green-50 flex items-center gap-2">
              <PlusOutlined />
              新增規格二
            </button>
          </div>

          <Form.Item name="pricingTable" className="mt-6">
            <Table
              columns={columns}
              dataSource={initialData}
              pagination={false}
              className="bg-white"
            />
          </Form.Item>
        </>
      )}
    </div>
  );
};

export default ServicePricing;
