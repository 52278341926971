import React from "react";
import { Select, Button } from "antd";

const { Option } = Select;

const StepOneComponent = ({
  integrationsInfos,
  thirdPartyIntegrations,
  setThirdPartyIntegrations,
  handleCancel,
  handleNextStep,
}) => {
  return (
    <div className="w-full flex flex-col items-start gap-4">
      <div className="w-full h-[109px] bg-[#F0F0F0] border border-[#E0E0E0] rounded-[4px] flex flex-col items-start gap-4 p-4">
        <div className="text-black text-[16px]">串接平台</div>
        <div className="mt-[2px] text-black-900 w-full">
          <Select
            className="w-full"
            placeholder={
              <span style={{ color: "#000000" }}>串接平台選擇</span>
            }
            onChange={(value) => setThirdPartyIntegrations(value)}
            value={thirdPartyIntegrations}
          >
            {integrationsInfos.map((integration) => (
              <Option key={integration.name} value={integration.name}>
                <div className="flex items-center">
                  <img
                    src={integration.icon}
                    alt={`${integration.name}-icon`}
                    className="w-[24px] h-[24px] mr-2"
                  />
                  {integration.name}
                </div>
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="w-full flex justify-end gap-4">
        <Button
          onClick={handleCancel}
          className="text-black-900 text-[16px] leading-[1.3]"
        >
          取消
        </Button>
        <Button
          onClick={handleNextStep}
          className="text-black-900 text-[16px] leading-[1.3]"
          style={{ backgroundColor: "#ADDEAC" }}
        >
          下一步
        </Button>
      </div>
    </div>
  );
};

export default StepOneComponent;
