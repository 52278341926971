import React, { useState, useEffect } from "react";
import { Select, Input, DatePicker, Form, Tag, Upload, Modal, Carousel } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import ImgCrop from 'antd-img-crop';
import { useShop } from "@/contexts/ShopContext";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const Information = ({ form }) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [fileList, setFileList] = useState([]);
  const { globalShops } = useShop();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newTagInput, setNewTagInput] = useState('');

  // Get base64 of image
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // Handle image change
  const onChange = async ({ fileList: newFileList }) => {
    const processedFileList = await Promise.all(
      newFileList.map(async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        return file;
      })
    );
    setFileList(processedFileList);
    if (processedFileList.length > 0) {
      const imageUrl = processedFileList[0].preview || processedFileList[0].url;
      form.setFieldsValue({ serviceImage: imageUrl });
    } else {
      form.setFieldsValue({ serviceImage: '' });
    }
  };

  // Handle image preview
  const onPreview = async (file) => {
    let src = file.url || file.preview;
    if (!src) {
      src = await getBase64(file.originFileObj);
      file.preview = src;
    }
    
    // 創建預覽 Modal 而不是新窗口
    Modal.info({
      title: '圖片預覽',
      width: 800,
      content: (
        <Carousel autoplay>
          {fileList.map((item, index) => (
            <div key={index}>
              <img 
                src={item.url || item.preview} 
                alt={`preview-${index}`}
                style={{ width: '100%', maxHeight: '500px', objectFit: 'contain' }}
              />
            </div>
          ))}
        </Carousel>
      ),
      footer: null,
    });
  };

  // Handle tag close
  const handleTagClose = (removedTag) => {
    const newTags = selectedTags.filter(tag => tag !== removedTag);
    setSelectedTags(newTags);
    form.setFieldsValue({ tags: newTags });
  };

  // Handle tag add
  const addTag = (newTag) => {
    if (newTag && !selectedTags.includes(newTag)) {
      const newTags = [...selectedTags, newTag];
      setSelectedTags(newTags);
      setTags(newTags);
      form.setFieldsValue({ tags: newTags });
    }
  };

  // add new tag
  const handleModalOk = () => {
    if (newTagInput) {
      addTag(newTagInput);
      setNewTagInput('');
    }
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setNewTagInput('');
    setIsModalVisible(false);
  };

  return (
    <>
      <Form.Item
        name="provider"
        label="選擇商家"
        rules={[{ required: true, message: '請選擇服務對象' }]}
        className="w-1/5"
      >
        <Select 
          placeholder="請選擇服務對象"
          onChange={(value) => {
            const selectedShop = globalShops.find(shop => shop.shopId === value);
            if (selectedShop) {
              form.setFieldsValue({
                selectedShopName: selectedShop.name,
                selectedShopId: selectedShop.shopId
              });
            }
          }}
        >
          {globalShops?.map((shop) => (
            <Select.Option 
              key={shop.shopId}
              value={shop.name}
            >
              {shop.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      {/* use different field names to store selected shop information */}
      <Form.Item name="selectedShopName" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="selectedShopId" hidden>
        <Input />
      </Form.Item>

      <div className="flex space-x-6">
        <Form.Item 
          label="服務名稱" 
          name="serviceTitle" 
          className="w-1/3"
          rules={[{ required: true, message: '請輸入服務名稱' }]}
        >
          <Input placeholder="請輸入服務名稱" />
        </Form.Item>
        <Form.Item 
          label="服務序號" 
          name="serviceNumber" 
          className="w-1/3"
          rules={[{ required: true, message: '服務序號為必填' }]}
        >
          <Input disabled placeholder="系統自動填入" />
        </Form.Item>
      </div>
      <div className="font-medium mb-2">
        服務主要圖片
        <span className="text-red-500 ml-1">*</span>
      </div>
      <Form.Item
        name="uploadImage"
        rules={[{ required: true, message: '請上傳服務圖片' }]}
        className="mb-4"
      >
        <div className="flex space-x-4">
          <ImgCrop rotationSlider>
            <Upload
              action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
              listType="picture-card"
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
              maxCount={5}
              beforeUpload={() => false}
            >
              {fileList.length < 5 && (
                <div>
                  <PlusOutlined />
                  <div className="mt-2">上傳圖片</div>
                </div>
              )}
            </Upload>
          </ImgCrop>
        </div>
      </Form.Item>
      <Form.Item 
        label="服務描述" 
        name="serviceDescription"
        rules={[{ required: true, message: '請輸入服務描述' }]}
      >
        <TextArea rows={4} placeholder="請輸入服務介紹" />
      </Form.Item>

      <Form.Item 
        label="注意事項說明" 
        name="serviceNotice"
        rules={[{ required: true, message: '請輸入注意事項說明' }]}
      >
        <TextArea rows={4} placeholder="請輸入注意事項、使用規則" />
      </Form.Item>

      {/* Reservation and Tags */}
      <div className="flex space-x-4">
        <Form.Item 
          label="預約" 
          name="reservation"
          rules={[{ required: true, message: '請選擇預約方式' }]}
        >
          <Select style={{ width: 200 }}>
            <Select.Option value="不需預約">不需預約</Select.Option>
            <Select.Option value="需預約日期">需預約日期</Select.Option>
            <Select.Option value="需預約日期及時段">需預約日期及時段</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="可預約區間" name="bookingPeriod" >
          <RangePicker disabled/>
        </Form.Item>
      </div>

      <Form.Item label="使用期限" name="usagePeriod">
        <RangePicker disabled/>
      </Form.Item>

      {/* tags */}
      <Form.Item 
          label={`標籤 (${selectedTags.length}/5)`}
          name="tags"
          rules={[{ required: true, message: '請至少增加一個標籤' }]}
        >
          <div className="flex flex-wrap gap-2 border border-gray-300 rounded-lg p-2 min-w-[400px] w-fit">
            {selectedTags.map((tag) => (
              <Tag
                key={tag}
                closable
                onClose={() => handleTagClose(tag)}
                className="bg-green-50 text-green-600 border-green-200"
              >
                {tag}
              </Tag>
            ))}
            {selectedTags.length < 5 && (
              <Tag
                className="border-dashed border-gray-300 cursor-pointer hover:border-green-500"
                onClick={() => setIsModalVisible(true)}
              >
                <PlusOutlined /> 新增標籤
              </Tag>
            )}
          </div>
        </Form.Item>

      <Form.Item label="服務備註（小標呈現）" name="copyright">
        <Input placeholder="請輸入備註（25字內）" maxLength={25} disabled/>
      </Form.Item>

      {/* Image URL */}
      <Form.Item 
        name="serviceImage" 
        label="圖片 URL" 
        hidden
        rules={[{ 
          required: true, 
          message: '請上傳服務圖片',
          validator: (_, value) => {
            if (!value && fileList.length === 0) {
              return Promise.reject('請上傳服務圖片');
            }
            return Promise.resolve();
          }
        }]}
      >
        <Input disabled />
      </Form.Item>

      {/* add Modal component */}
      <Modal
        title="新增標籤"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="確定"
        cancelText="取消"
      >
        <Input
          placeholder="請輸入標籤名稱"
          value={newTagInput}
          onChange={(e) => setNewTagInput(e.target.value)}
          onPressEnter={handleModalOk}
        />
      </Modal>
    </>
  );
};

export default Information;
