import { request } from "@/utils";
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const retryRequest = async (requestFn, retries = 5, delayMs = 2000) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      return await requestFn();
    } catch (error) {
      console.error(`Attempt ${attempt} failed:`, error);
      if (attempt < retries) {
        await delay(delayMs);
      } else {
        throw error;
      }
    }
  }
};

export const refreshAirbnb = (fetching, fetchAirbnbClients) => {
  if (!fetching) {
    fetchAirbnbClients(true);
  }
};

// check airbnb profile
const getprofile = async (roomId, setLoading) => {
  try {
    await request.get(`/private/module/rpa/profile/${roomId}`);
    console.warn('Profile fetched successfully');
    return true;
  } catch (error) {
    console.warn('Error fetching profile');
    setLoading(false);
    return false;
  }
};

// fetch airbnb history
export const fetchAirbnbHistory = async (
  setOrderAirbnbMessage,
  setAirbnbMessage,
  roomId,
  listLoading,
  setListLoading
) => {
  const getHistory = (limit = 60) => request.get(
    `/private/module/rpa/airbnb/history/${roomId}`, {
    params: {
      limit: limit
    }
  });
  try {
    setListLoading(true);
    const historyResponse = await getHistory();
    const allMessages = historyResponse.flatMap(item => {
      const getMessages = item.get_messages;
      const messageId = item.message_id;
      const lastSubmitTimestamp = item.last_submit_timestamp;
      if (getMessages && typeof getMessages === 'object') {
        return [{
          ...getMessages,
          message_id: messageId,
          last_submit_timestamp: lastSubmitTimestamp
        }];
      }
      return [];
    });
    const allOrders = historyResponse.flatMap(item => {
      const getOrders = item.get_order_info;
      const messageId = item.message_id;
      const lastSubmitTimestamp = item.last_submit_timestamp;
      if (getOrders && typeof getOrders === 'object') {
        return [{
          ...getOrders,
          message_id: messageId,
          last_submit_timestamp: lastSubmitTimestamp
        }];
      }
      return [];
    });
    try {
      // set message to state
      setAirbnbMessage(prevMessages => {
        const prevAirbnbMessages = Array.isArray(prevMessages) ? prevMessages : [];
        const newMessages = allMessages.filter(
          newMsg => !prevAirbnbMessages.some(existingMsg => existingMsg.message_id === newMsg.message_id)
        );

        if (newMessages.length === 0) return prevAirbnbMessages;

        const updatedMessages = [...prevAirbnbMessages, ...newMessages];

        return updatedMessages;
      });
    } catch (error) {
      console.log('8787', error);
    }
    // set order to state
    try {
      setOrderAirbnbMessage(prevOrders => {
        const prevOrderMessages = Array.isArray(prevOrders) ? prevOrders : [];


        const updatedOrders = [...prevOrderMessages, ...allOrders];
        return updatedOrders;
      });
    } catch (error) {
      console.log('設定訂單時發生錯誤:', error);
    }
  } catch (error) {

  }finally {
    setListLoading(false);
  }
};

// fetch airbnb clients
export const fetchAirbnbClients = async (
  room,
  token,
  setClientsAirbnb,
  setLoading,
  setFetching,
  requestQueue,
  setRequestQueue,
  setMessageId,
  roomId,
  setAirbnbMessage,
  airbnbMessage,
  setOrderAirbnbMessage,
  messageId,
  listLoading,
  setListLoading
) => {
  setLoading(true);
  setFetching(true);
  const profileSuccess = await getprofile(room.id, setLoading);
  if (!profileSuccess) {
    return;
  }

  if (requestQueue.length > 0) {
    setRequestQueue(prevQueue => [...prevQueue, { roomId, token }]);
    setLoading(false);
    setFetching(false);
    return;
  }

  try {
    setListLoading(true)
    // get message_ids
    const getMessageIds = () => request.get(
      `/private/module/rpa/airbnb/message_ids/${roomId}`);

    let idsResponse;
    const MAX_RETRIES = 3;
    const RETRY_DELAY = 15000; // 15秒

    for (let retryCount = 0; retryCount < MAX_RETRIES; retryCount++) {
      try {
        idsResponse = await retryRequest(getMessageIds);
        
        // 檢查時間戳
        const lastUpdate = idsResponse?.last_update_timestamp;
        const timeDiff = (Date.now() / 1000) - lastUpdate;
        
        if (!lastUpdate || timeDiff > 5 * 60) {
          console.warn(`Message IDs 資料已過期 (${Math.floor(timeDiff)}秒)，嘗試重新獲取 (嘗試 ${retryCount + 1}/${MAX_RETRIES})`);
          
          try {
            // 发送 POST 请求
            await request.post(
              `/private/module/rpa/airbnb/message_ids/${roomId}`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
          } catch (postError) {
            // 处理 400 错误和 Task is already running 的情况
            if (postError.response?.status === 400 || 
                postError.response?.data?.detail === "Task is already running") {
              console.warn("任務已在運行中或發生 400 錯誤，等待 15 秒後繼續...");
            } else {
              console.error("POST message_ids 失敗：", postError);
            }
          }
          
          // 无论 POST 是否成功，都等待 15 秒
          await delay(15000);
          
          // 重新获取数据
          continue;
        } else {
          break; // 資料未過期，跳出循環
        }
      } catch (error) {
        if (retryCount === MAX_RETRIES - 1) {
          console.error("最終獲取 message_ids 失敗：", error);
          return;
        }
        console.warn(`獲取 message_ids 失敗，嘗試重新獲取 (嘗試 ${retryCount + 1}/${MAX_RETRIES})`);
        await delay(RETRY_DELAY);
      }
    }

    if (!idsResponse?.result?.length) {
      console.warn("沒有返回 message IDs。");
      return;
    }

    const lastUpdate = idsResponse?.last_update_timestamp;
    const timeDiff = (Date.now() / 1000) - lastUpdate;
    if (timeDiff > 5 * 60) {
      console.warn(`最終獲取的 Message IDs 資料仍然過期 (${Math.floor(timeDiff)}秒)，退出操作`);
      return;
    }

    const messageIds = idsResponse.result;
    let messages = [];

    for (const messageId of messageIds) {
      try {
        // get message
        await delay(2000);
        const getMessage = () => request.get(
          `/private/module/rpa/airbnb/message/${roomId}`,
          {
            params: {
              message_id: messageId,
            },
          }
        );
        // add order info fetching
        fetchAirbnbOrder(roomId, messageId, setOrderAirbnbMessage);
        let messageResponse;
        try {
          messageResponse = await retryRequest(getMessage);
          if (messageResponse?.status === "pending" || messageResponse?.status === "failed") {
            console.warn(`Message (${messageId}) 狀態為 pending，嘗試 POST 開啟任務。`);
            const postMessage = () => request.post(
              `/private/module/rpa/agoda/message/${roomId}`,
              {},
              {
                params: {
                  message_id: messageId,
                  clean_run: false,
                },
              }
            );

            try {
              await postMessage();
            } catch (postError) {
              console.error(`POST message (${messageId}) 失敗：`, postError);
            }

            // get message again
            try {
              await delay(2000);
              messageResponse = await retryRequest(getMessage);
            } catch (finalError) {
              console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
              continue; // continue to next messageId
            }
          }
        } catch (error) {
          console.warn(`GET message (${messageId}) 失敗，嘗試 POST 開啟任務。`);
          // post message
          const postMessage = () => request.post(
            `/private/module/rpa/airbnb/message/${roomId}`,
            {},
            {
              params: {
                message_id: messageId,
                clean_run: false,
              },
            }
          );

          try {
            await postMessage();
          } catch (postError) {
            console.error(`POST message (${messageId}) 失敗：`, postError);
          }

          // get message again
          try {
            await delay(2000);
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }

        const messageLastUpdate = messageResponse?.last_update_timestamp;
        console.warn('距離上次更新時間', ((Date.now() / 1000) - messageLastUpdate), '秒');

        if (messageLastUpdate && ((Date.now() / 1000) - messageLastUpdate) > 5 * 60) {
          console.warn(`Message (${messageId}) 超過 5 分鐘未更新，嘗試 POST 開啟任務。`);

          // post message
          const postMessage = () => request.post(
            `/private/module/rpa/airbnb/message/${roomId}`,
            {},
            {
              params: {
                message_id: messageId,
              },
            }
          );

          try {
            await postMessage();
            // get message again
            messageResponse = await retryRequest(getMessage);
          } catch (error) {
            console.error(`處理 message (${messageId}) 失敗：`, error);
            // continue to next messageId
          }

          // get message again
          try {
            await delay(2000);
            messageResponse = await retryRequest(getMessage);
          } catch (finalError) {
            console.error(`最終 GET message (${messageId}) 失敗：`, finalError);
            continue; // continue to next messageId
          }
        }

        // add message to airbnbMessage state
        if (messageResponse) {
          const updatedMessageResponse = {
            ...messageResponse,
            message_id: messageId
          };

          setAirbnbMessage(prevMessages => {
            const prevAirbnbMessages = Array.isArray(prevMessages) ? prevMessages : [];

            const existingIndex = prevAirbnbMessages.findIndex(
              (msg) => msg.message_id === updatedMessageResponse.message_id
            );

            if (existingIndex !== -1) {
              console.log(`message (${messageId}) 已存在，已更新。`);
              const updatedMessages = [...prevAirbnbMessages];
              updatedMessages[existingIndex] = updatedMessageResponse;
    
              // update localStorage
              localStorage.setItem("airbnbMessages", JSON.stringify(updatedMessages));
    
              return updatedMessages;
            }
            console.log(`message (${messageId}) 不存在，增加一筆。`);

            // add message to state
            const updatedMessages = [...prevAirbnbMessages, updatedMessageResponse];

            // update localStorage
            localStorage.setItem("airbnbMessages", JSON.stringify(updatedMessages));

            return updatedMessages;
          });
        }
      } catch (error) {
        console.error(`處理 message (${messageId}) 時發生錯誤：`, error);
        continue; // continue to next messageId
      }
    }

    // Queue next request
    if (requestQueue.length > 0) {
      const nextRequest = requestQueue.shift();
      setRequestQueue([...requestQueue]);
      fetchAirbnbClients({
        room,
        token: nextRequest.token,
        setClientsAirbnb,
        setLoading,
        setFetching,
        requestQueue,
        setRequestQueue,
        setMessageId,
        roomId: nextRequest.roomId,
      });
    }
  } catch (error) {
    console.error("獲取 Airbnb 客戶端時發生錯誤：", error);
    try {
      // post message_ids
      const postMessageIds = () => request.post(
        `/private/module/rpa/airbnb/message_ids/${roomId}`,
        {},
      );

      await postMessageIds();
    } catch (postError) {
      console.error("POST 開啟任務時發生錯誤：", postError);
    } finally {
      setFetching(false);
      setLoading(false);
    }
  } finally {
    setLoading(false);
    setFetching(false);
    setListLoading(false);
  }
};

// POST request to fetch order info
const postOrder = (roomId, messageId) => request.post(
  `/private/module/rpa/airbnb/order_info/${roomId}`,
  {},
  {
    params: { message_id: messageId },
  }
);

// GET request to fetch order info
const getOrder = (roomId, messageId) => request.get(`/private/module/rpa/airbnb/order_info/${roomId}`, {
  params: { message_id: messageId },
});

export const fetchAirbnbOrder = async (roomId, messageId, setOrderAirbnbMessage) => {
  try {
    let getResponse = await getOrder(roomId, messageId);
    if (getResponse.status === "failed") {
      console.log("GET 失敗，嘗試 POST 任務開啟");
      try {
        await delay(2000);
        await postOrder(roomId, messageId);
      } catch (error) {
        await delay(5000);
        getResponse = await getOrder(roomId, messageId);
      }
    }
    if (getResponse.status === "pending") {
      console.log("GET 仍處於 pending，嘗試 POST 任務開啟");
      try {
        await delay(2000);
        await postOrder(roomId, messageId);
      } catch (error) {
        await delay(5000);
        getResponse = await getOrder(roomId, messageId);
      }
    }

    if (getResponse) {
      // 確保 message_id 被正確增加
      const updatedResponse = { ...getResponse, message_id: messageId };
      setOrderAirbnbMessage(prevOrders => {
        const prevOrderList = Array.isArray(prevOrders) ? prevOrders : [];
        const existingIndex = prevOrderList.findIndex(order => order.message_id === messageId);

        if (existingIndex !== -1) {
          // 更新已存在的訂單
          const updatedOrders = [...prevOrderList];
          updatedOrders[existingIndex] = updatedResponse;
          console.log(`Order (${messageId}) 已存在，已更新。`);
          return updatedOrders;
        } else {
          // 增加新的訂單
          const updatedOrders = [...prevOrderList, updatedResponse];
          console.log(`Order (${messageId}) 不存在，增加一筆。`);
          return updatedOrders;
        }
      });
    }


  } catch (error) {
    console.error("獲取 order 資訊時發生錯誤：", error);
    try {
      await postOrder(roomId, messageId);
      await delay(2000);
      let getResponse = await getOrder(roomId, messageId);
      if (getResponse.status === "pending") {
        console.log("POST 成功，但 GET 仍 pending，嘗試再次 GET");
        await delay(2000);
        getResponse = await getOrder(roomId, messageId);
      }
      if (getResponse) {
        const updatedMessageResponse = {
          ...getResponse,
          message_id: messageId
        };

        setOrderAirbnbMessage(prevMessages => {
          const prevAirbnbMessages = Array.isArray(prevMessages) ? prevMessages : [];
          const existingIndex = prevAirbnbMessages.findIndex(msg => msg.message_id === updatedMessageResponse.message_id);

          if (existingIndex !== -1) {
            const updatedOrders = [...prevAirbnbMessages];
          updatedOrders[existingIndex] = updatedMessageResponse;
          console.log(`Order (${messageId}) 已存在，已更新。`);
            return prevAirbnbMessages;
          }
          console.log(`order (${messageId}) 不存在，增加一筆。`);

          // add message to state
          const updatedMessages = [...prevAirbnbMessages, updatedMessageResponse];

          return updatedMessages;
        });
      }
    } catch (postError) {
      console.error("POST 請求失敗：", postError);
    }
  }
};