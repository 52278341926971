import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { message, Button, Tooltip, Input } from 'antd'



import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const { TextArea } = Input;

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const RuleSetting = ({ room, token }) => {
  const [rule, setRule] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchRooms()
  }, [room])

  const fetchRooms = async () => {
    setLoading(true)
    try {
      const response = await axios.get(SERVER_URL + '/private/chatrooms/' + room.id, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setRule(response.data.instructions)
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
    }
  }

  const onChangeRule = (e) => {
    setRule(e.target.value)
  }

  const handleChangeRule = async () => {
    setLoading(true)

    try {
      await axios.patch(SERVER_URL + '/private/chatrooms/setting/instructions/' + room.id, {
        text: rule
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
    } catch (error) {
      message.error(JSON.stringify(error.response?.data?.detail));
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  return (
    <div className="flex flex-col justify-start items-start w-full px-4 py-6 gap-4">
      <div className="text-lg text-textDark font-bold w-full">
        聊天室規則
      </div>
      <div>
        寫下你量身定制的聊天室規則
      </div>
      <Tooltip title="AI助手在介紹自己和迎接使用者時應該遵循哪些指引？AI助手該如何確定介紹的語氣和風格？例如正式、隨意、友好或專業AI助手助手的首選回應風格是什麼？應該是知識性、幽默、簡潔還是冗長的？AI助手是否應該使用表情符號或根據使用者的偏好調整語言？AI助手可以包括如何引入和更改話題？AI是否應該堅持特定主題，還是可以討論各種主題？">
        <TextArea showCount maxLength={3000} value={rule} onChange={onChangeRule} placeholder="請輸入您的聊天室規則" className="min-h-60 mb-8" disabled={loading} />
      </Tooltip>
      <div className="flex flex-row justify-end w-full">
        <Button type="primary" onClick={handleChangeRule} loading={loading}>
          儲存
        </Button>
      </div>
    </div>
  )
}

export default RuleSetting
