import { ReactComponent as SmileIcon } from '../assets/smile_icon.svg';
import { ReactComponent as KeyIcon } from '../assets/key_icon.svg';
import { ReactComponent as OnlineMember } from '../assets/onlineMember_icon.svg';

const RoomInfo = ({ name, is_public, category, onlineMembers }) => {
    return (
        <div className='flex flex-col justify-between w-[100%] h-[160px] border-[#E0E0E0] border-[1px] rounded-[8px] overflow-hidden hover:shadow hover:shadow-primary'>
            <div className='flex'>
                <div className={` ${is_public ? 'bg-[#262626] text-[#F7F7F7]' : 'bg-[#ADDEAC] text-[#3E3D3D]'} flex items-center justify-around w-[68px] p-[5px] rounded-br`}>
                    {is_public ? <SmileIcon /> : <KeyIcon />}
                    <p className='text-[13px]'>{is_public ? '公開' : '私人'}
                    </p>
                </div>
            </div>
            <div className='p-[10px]'>
                <p className='text-[16px]'>{name}</p>
                <p className='text-[13px] text-textDark'>{category}</p>
            </div>
            <div className='flex w-full justify-end items-center border-[1px] border-gray bg-[#E0E0E0] p-[10px]'>
                {/* <OnlineMember /> */}
                <p className='text-[12px] flex items-center ml-2'>
                    共{onlineMembers}位成員</p>
            </div>


        </div>
    )
}

export default RoomInfo
